import React, { useEffect, useState } from 'react';

const ScrollbarStyles = () => {
    const [scrollbarColor, setScrollbarColor] = useState('#344766');

    useEffect(() => {
        const currentUrl = window.location.href;

        if (currentUrl.includes('evamp')) {
            setScrollbarColor('#29306e');
        } else {
            setScrollbarColor('#344766');
        }
    }, []);

    return (
        <style>
            {`
        ::-webkit-scrollbar {
          width: 7px;
        }
        
        ::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: transparent;
        }
        
        ::-webkit-scrollbar-thumb {
          background: ${scrollbarColor};
          border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb:hover {
          background: ${scrollbarColor};
        }
        
        * {
          scrollbar-width: thin;
          scrollbar-color: ${scrollbarColor} transparent;
        }
        
        *::-moz-selection {
          background-color: ${scrollbarColor};
        }
        
        *::-ms-scrollbar-thumb {
          background-color: ${scrollbarColor};
        }
        
        *::-ms-scrollbar-track {
          background-color: transparent;
        }
      `}
        </style>
    );
};

export default ScrollbarStyles;
