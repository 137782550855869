import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../../Axios/Axios';
import { useNotification } from '../../../../Notification/NotificationProvider';


function NewModeModal({ editingModeId, initialMode, addedNewComplaints  }) {

    const [mode, setMode] = useState('');
    const token = useSelector(state => state.login.token);
    const [error, seterror] = useState(false)
    const closeRef = useRef();
    const { showNotification } = useNotification();

    useEffect(() => {
        if (editingModeId && initialMode) {
            setMode(initialMode);
        }
    }, [editingModeId, initialMode]);

    const handleNewModeForm = (e) => {
        e.preventDefault();
        if (mode === '') {
            seterror(true)
            return
        }

        const newComplaints = {
            name: mode,
        }

        const request = editingModeId
          ? axiosInstance.put(`/support-ticket-mode-of-complaints/${editingModeId}`, newComplaints, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          })
          : axiosInstance.post('/support-ticket-mode-of-complaints', newComplaints, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

        request
          .then((response) => {
            if (response.data.success) {
              const message = response.data.message;
              showNotification(message, 'success');
              addedNewComplaints();
              closeRef.current.click();
              setMode('');
            }
          })
          .catch((error) => {
            showNotification(error.message, 'error');
            console.error(error);
          });
    };

    const handleModeChange = (e) => {
        const inputValue = e.target.value;
        setMode(inputValue);

        if (inputValue.trim() === '') {
            seterror(true);
        } else {
            seterror(false);
        }
    }


    return (
        <div className="modal fade" tabIndex="-1" id='newModeModal'>
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleNewModeForm}>
                        <div className="modal-header">
                            <h5 className="modal-title">{editingModeId ? 'Edit Mode' : 'Add New Mode'}</h5>
                            <button type="button" className="btn-close" ref={closeRef} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="mode" className="form-label"><span className='required-star'>*</span>Mode Name</label>
                            <input type="text" value={mode} onChange={handleModeChange} className="form-control form-control-sm" id="mode" name="mode" />
                            {error && <p className='m-0 text-danger'>Mode name is required.</p>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-sm" data-bs-dismiss="modal" aria-label="Close">Close</button>
                            <button type="submit" className="btn btn-primary btn-sm text-white">{editingModeId ? 'Update' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewModeModal