import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';

function BarChart({ serviceReport }) {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);

    const generateColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 1)`;
    };

    const colors = serviceReport.map(() => generateColor());

    useEffect(() => {
        if (serviceReport.length > 0) {
            // Find the service with the highest percentage
            const highestPercentageService = serviceReport.reduce((prev, current) =>
                prev.percentage > current.percentage ? prev : current
            );

            const options = {
                chart: {
                    id: 'yt',
                    type: 'bar',
                    height: 450,
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                        horizontal: false,
                        columnWidth: '40%',
                        endingShape: 'rounded',
                        dataLabels: {
                            position: 'top'
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(2) + "%";
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories: serviceReport.map(item => item.name),
                    title: {
                        text: 'Services'
                    },
                    labels: {
                        style: {
                            fontSize: '12px',
                            colors: ['#000']
                        },
                        rotate: -45,
                        maxHeight: 120,
                        trim: true
                    },
                    tickPlacement: 'on'
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(2) + '%';
                        }
                    },
                    title: {
                        text: 'Percentage'
                    }
                },
                annotations: {
                    yaxis: [
                        {
                            y: highestPercentageService.percentage, // Place annotation at the highest percentage
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                style: {
                                    color: '#fff',
                                    background: '#00E396'
                                },
                                text: `Highest: ${highestPercentageService.percentage}%` // Annotation text
                            }
                        }
                    ],
                    xaxis: [
                        {
                            x: highestPercentageService.name, // Mark the service with the highest percentage
                            borderColor: '#FEB019',
                            label: {
                                style: {
                                    color: '#fff',
                                    background: '#FEB019'
                                },
                                text: 'Top Service' // Annotation text for x-axis
                            }
                        }
                    ]
                },
                title: {
                    text: 'Problem wise Service',
                    align: 'center',
                    margin: 20,
                    style: {
                        fontSize: '20px',
                        color: '#666'
                    }
                },
                colors: colors,
                legend: {
                    show: false
                },
                grid: {
                    padding: {
                        bottom: 20
                    }
                },
                tooltip: {
                    enabled: true,
                    theme: 'dark',
                    y: {
                        formatter: function (value, { dataPointIndex }) {
                            const ticketCount = serviceReport[dataPointIndex].ticket_count;
                            return `${value.toFixed(2)}% (Ticket Count: ${ticketCount})`;
                        }
                    }
                }
            };

            const series = [{
                name: 'Percentage',
                data: serviceReport?.map(item => parseFloat(item.percentage))
            }];

            setChartOptions(options);
            setChartSeries(series);
        }
    }, [serviceReport]);

    return (
        <div className='border p-2 rounded border-secondary' style={{ backgroundColor: "#eff5f9" }}>
            <ApexCharts
                options={chartOptions}
                series={chartSeries}
                type="bar"
                width="100%"
                height={500}
            />
        </div>
    );
}

export default BarChart;
