import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { updateStaffMemberValidation } from '../../../../../Yup/Yup';
import axiosInstance from '../../../../../Axios/Axios';
import { useNotification } from '../../../../Notification/NotificationProvider';
import CustomSelect from '../../../Parts/CustomSelect';
import Loading from '../../../Parts/Loading';
import { Avatar } from '@mui/material'


function StaffDetails() {
    const { showNotification } = useNotification();
    const navigate = useNavigate()
    const token = useSelector((state) => state.login.token);
    const user = useSelector((state) => state.login.user);
    const { id } = useParams();
    const [member, setMember] = useState();
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(true)
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);

    const role = user.roles[0].name;
    const permissions = user.roles[0].permissions.map((permission) => permission.name);
    const allowedRoles = ['admin', 'staff'];


    const initialValues = {

        name: member?.name || '',
        last_name: member?.last_name || '',
        email: member?.email || '',
        parent_company_id: member?.parent_company_id || '1',
        phone: member?.phone || '',
        department: member?.departments.map((department) => ({ label: department.name, value: department.id })),
        role: { label: selectedRole?.charAt(0).toUpperCase() + selectedRole?.slice(1), value: selectedRole }

    }


    const fetchInitialValues = async (signal, id) => {
        try {
            const [
                departmentsResponse,
                staffResponse,
                rolesResponse
            ] = await Promise.all([
                axiosInstance.get("/departments", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get(`/staff/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/roles", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
            ]);
            setLoading(false);
            setDepartments(departmentsResponse.data.departments.data);
            setMember(staffResponse.data.data);
            setSelectedRole(staffResponse.data.data.role[0])
            // console.log(staffResponse.data.data.role[0]);
            setRoles(rolesResponse.data.data);
        } catch (error) {
            console.error(error);
        }

    }


    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchInitialValues(signal, id);

        return () => {
            controller.abort();
        };

    }, [id, token]);



    // console.log(member)
    const handleSubmit = (values) => {
        const department_id = values?.department && values.department.map((item) => item.value);

        const updateStaffData = {
            name: values.name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
            is_active: values.is_active,
            departments: department_id,
            profile_picture: file,
            role: values.role.value
        }

        axiosInstance
            .post(`/staff/${id}`, updateStaffData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                if (response.data.success) {
                    const controller = new AbortController();
                    const { signal } = controller;
                    fetchInitialValues(signal, id);
                    const message = response.data.message;
                    showNotification(message, 'success');
                }
            })
            .catch((error) => {
                showNotification(error.message, 'error');
                console.error('Submission error:', error);
            });
    };

    function capitalizeFirstLetter(str) {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    }

    return (
        <>
            <div className="container">
                {loading && (
                    <div className="loading-overlay">
                        <Loading />
                    </div>
                )}
                <div className="row">
                    <div className="col section">
                        <div className="row">
                            <div className="col-1 d-flex justify-content-start">
                                <Avatar className='border border-secondary' alt={"Avtar"} src={member?.profile_picture} style={{ height: 80, width: 80, fontSize: '4.25rem' }} />
                            </div>
                            <div className="col-4 d-flex  align-items-center ">
                                <h3 className='m-0'>{member && capitalizeFirstLetter(`${member.name} ${member.last_name}`)}</h3>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col">
                                {member && (
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize={true}
                                        validationSchema={updateStaffMemberValidation}
                                        onSubmit={handleSubmit}
                                    >
                                        <Form>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3">
                                                        <label htmlFor="name" className="form-label">
                                                            <span className="required-star">*</span>First Name
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="form-control "
                                                            id="name"
                                                            name="name"
                                                        />
                                                        <ErrorMessage
                                                            name="name"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="mb-3">
                                                        <label htmlFor="last_name" className="form-label">
                                                            <span className="required-star">*</span>Last Name
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="form-control "
                                                            id="last_name"
                                                            name="last_name"
                                                        />
                                                        <ErrorMessage
                                                            name="last_name"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3">
                                                        <label htmlFor="email" className="form-label">
                                                            <span className="required-star">*</span>Email
                                                        </label>
                                                        <Field
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                        />
                                                        <ErrorMessage
                                                            name="email"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="mb-3">
                                                        <label htmlFor="phone" className="form-label">
                                                            Phone
                                                        </label>
                                                        <Field
                                                            type="tel"
                                                            className="form-control "
                                                            id="phone"
                                                            name="phone"
                                                        />
                                                        <ErrorMessage
                                                            name="phone"
                                                            component="div"
                                                            className="text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-2">
                                                        <label htmlFor="assignTicket" className="form-label">Assign Department</label>
                                                        <Field
                                                            component={CustomSelect}
                                                            id="department"
                                                            name="department"
                                                            isMulti={true}
                                                            options={departments?.map((department) => ({ label: department.name, value: department.id }))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className='mb-2'>
                                                        <label htmlFor="Profile-Image" className="form-label">Profile Image</label>
                                                        <Field
                                                            type="file"
                                                            id="fileInput"
                                                            name="file"
                                                            accept="image/*"
                                                            className='form-control '
                                                            onChange={(event) => {
                                                                const selectedFile = event.currentTarget.files[0];
                                                                if (selectedFile && selectedFile.type.includes('image')) {
                                                                    setFile(selectedFile);

                                                                } else {
                                                                    setError(true)
                                                                    event.target.value = null;
                                                                }
                                                            }}
                                                        />
                                                        {error && <span className="text-danger">Please select a valid image file.</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-5">
                                                        <label htmlFor="assignTicket" className="form-label">Role</label>
                                                        <Field
                                                            component={CustomSelect}
                                                            id="role"
                                                            name="role"
                                                            options={roles?.map((role) => ({
                                                                label: role.charAt(0).toUpperCase() + role.slice(1),
                                                                value: role,
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                permissions.includes('Edit Staff') && allowedRoles.includes(role) && (
                                                    <div className="modal-footer">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary btn-sm text-white"
                                                        >
                                                            Save changes
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </Form>
                                    </Formik>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default StaffDetails;
