import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';

function DonutChart({ status_report }) {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);

    const generateColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 1)`;
    };

    // Generate colors for the donut chart
    const colors = status_report?.data?.map(() => generateColor());

    useEffect(() => {
        if (status_report?.data?.length > 0) {
            const options = {
                chart: {
                    id: 'donutChart',
                    type: 'donut',
                    height: 450,
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    events: {
                        dataPointSelection: (event, chartContext, { dataPointIndex }) => {
                            // console.log(`Clicked on ${status_report?.[dataPointIndex].time_group}: ${status_report?.[dataPointIndex].ticket_count}`);
                        }
                    }
                },
                labels: status_report?.data?.map(item => item.time_group),
                colors: colors,
                tooltip: {
                    y: {
                        formatter: (value, { dataPointIndex }) => {
                            const ticketCount = status_report?.data?.[dataPointIndex]?.ticket_count || 0;
                            return `Ticket Count = ${ticketCount}`;
                        }
                    },
                    theme: 'dark',
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    markers: {
                        width: 12,
                        height: 12,
                        radius: 12,
                    },
                    itemMargin: {
                        horizontal: 10,
                        vertical: 5
                    },
                    onItemClick: {
                        toggleDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val) => {
                        return val.toFixed(2) + "%";
                    },
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Arial, sans-serif',
                        fontWeight: 400,
                        dropShadow: {
                            enabled: true,
                            top: 1,
                            left: 1,
                            blur: 1,
                            opacity: 0.50
                        }
                    },
                    offset: 10
                },
                title: {
                    text: status_report?.title_status,
                    align: 'center',
                    margin: 20,
                    offsetY: 0,
                    style: {
                        fontSize: '16px',
                        color: '#666',
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%',
                        }
                    }
                }
            };

            const series = status_report?.data?.map(item => item.ticket_count);
            setChartOptions(options);
            setChartSeries(series);
        }
    }, [status_report]);

    return (
        <div className='border p-2 rounded border-secondary' style={{ backgroundColor: "#eff5f9" }}>
            <ApexCharts
                options={chartOptions}
                series={chartSeries}
                type="donut"
                width={450}
                height={400}
                key={JSON.stringify(chartSeries)}
            />
        </div>
    );
}

export default DonutChart;
