import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

import Login from "./Component/Login/Login";
import './App.scss';
import AdminHeader from "./Component/Admin/Header/AdminHeader";
import Sidebar from "./Component/Admin/Header/Sidebar";
import Staff from "./Component/Admin/Pages/SetUp/Staff/Staff";
import StaffDetails from "./Component/Admin/Pages/SetUp/Staff/StaffDetails";
import Departments from "./Component/Admin/Pages/SetUp/Support/Departments";
import PreDefinedReplies from "./Component/Admin/Pages/SetUp/Support/PreDefinedReplies";
import TicketPriority from "./Component/Admin/Pages/SetUp/Support/TicketPriority";
import TicketStatuses from "./Component/Admin/Pages/SetUp/Support/TicketStatuses";
import Services from "./Component/Admin/Pages/SetUp/Support/Services";
import Settings from "./Component/Admin/Pages/SetUp/Advanced Emails/Settings";
import TicketSupport from "./Component/Admin/Pages/TicketSupport/TicketSupport";
import TicketSupportDetails from "./Component/Admin/Pages/TicketSupport/TicketSupportDetails";
import TicketReply from "./Component/Admin/Pages/TicketSupport/TicketReply";
import Profile from "./Component/Admin/Pages/Profile";
import axiosInstance from "./Axios/Axios";
import Permissions from "./Component/Admin/Pages/SetUp/Permissions/Permissions";
import ProtectedRoute from "./Component/RouteProtected/ProtectedRoute";
import AdminHeaderProvider from "./Component/Context/AdminHeaderContext/AdminHeaderContext";
import Customers from "./Component/Admin/Pages/SetUp/Support/Customers";
import TurnAroundTime from "./Component/Admin/Pages/SetUp/Support/TurnAroundTime";
import MailLog from "./Component/Admin/Pages/SetUp/Advanced Emails/MailLog";
import AgingReport from "./Component/Admin/Pages/SetUp/Support/AgingReport";
import ScrollbarStyles from "./Component/Admin/Pages/ScrollBarStyle/ScrollbarStyles ";
import MakeOfCharger from "./Component/Admin/Pages/SetUp/Support/MakeOfCharger"
import ModeOfComplaint from "./Component/Admin/Pages/SetUp/Support/ModeOfComplaint";


const PageTitle = ({ title }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return null;
};


function App() {
  const location = useLocation();
  const user = useSelector((state) => state.login.user);
  const [isInterceptorReady, setIsInterceptorReady] = useState(false);
  // const navigate = useNavigate();
  // const isUserLoggedIn = useSelector(state => state.login.is_login);

  //const landingPage = location.pathname === '/' || location.pathname === '/pricing';

  // const permissions = user.roles[0].permissions.map((permission) => permission.name);
  // const role = user.roles[0].name;
  // console.log(user);
  // console.log(permissions);


  // change logo 
  useEffect(() => {
    const setFavicon = (url) => {
      const link = document.getElementById('dynamic-favicon');
      if (link) {
        link.href = url;
      }
    };

    const currentUrl = window.location.href;

    if (currentUrl.includes('evamp')) {
      setFavicon('/Evamp-fav.png');
    } else {
      setFavicon('/fav-book.png');
    }
  }, [location.pathname]);

  useEffect(() => {
    const isSpecial = window.location.href.includes("evamp");
    document.documentElement.style.setProperty(
      '--btn-primary-bg-color',
      isSpecial ? '#a73485' : '#007bff'
    );
  }, [location]);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <>
      <Routes>
        <Route path='/' element={<><PageTitle title="Login" /><Login /></>} />
      </Routes>

      {(location.pathname !== '/') &&
        <>
          <ScrollbarStyles />
          <AdminHeaderProvider>
            <AdminHeader toggleSidebar={toggleSidebar} />
            <Sidebar isCollapsed={isSidebarCollapsed} />
            <div className={`p-4 main-content ${isSidebarCollapsed ? 'collapsed' : ''}`}>
              <Routes>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['Add reply', 'Edit tickets']} />}>
                  <Route path="/tickets/ticket/:id" element={<> <PageTitle title="Ticket Details" /><TicketReply /></>} />
                </Route>


                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['List tickets']} />}>
                  <Route path="/tickets" element={<> <PageTitle title="Tickets" /><TicketSupport /></>} />
                </Route>
                {/* <Route path="/tickets" element={<TicketSupport />} /> */}

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['Add new tickets']} />}>
                  <Route path="/tickets/ticket" element={<><PageTitle title="New Ticket" /><TicketSupportDetails /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['Edit tickets']} />}>
                  <Route path="/tickets/ticket/:id/:setting" element={<> <PageTitle title="Ticket Details" /><TicketReply /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['Change Settings']} />}>
                  <Route path="/email-settings" element={<><PageTitle title="Email Settings" /><Settings /></>} />
                  <Route path="/email-logs" element={<><PageTitle title="Email Log" /><MailLog /></>} />
                  <Route path="/permission" element={<><PageTitle title="Permissions" /><Permissions /></>} />
                </Route>

                <Route path="/profile" element={<><PageTitle title="Profile" /><Profile /></>} />

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['List tickets']} />}>
                  <Route path="/aging-report" element={<><PageTitle title="Aging Report" /><AgingReport /></>} />
                </Route>


                {/* setUp pages */}

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['View Staff']} />}>
                  <Route path="/staff" element={<><PageTitle title="Staff" /><Staff /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['Edit Staff']} />}>
                  <Route path="/staff/:id" element={<><PageTitle title="Staff Details" /><StaffDetails /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['List Department']} />}>
                  <Route path="/tickets/departments" element={<><PageTitle title="Departments" /><Departments /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['View Predefined Reply']} />}>
                  <Route path="/tickets/predefined-replies" element={<><PageTitle title="Predefined Replies" /><PreDefinedReplies /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['View Priority']} />}>
                  <Route path="/tickets/ticket-priority" element={<><PageTitle title="Ticket Priority" /><TicketPriority /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['View Status']} />}>
                  <Route path="/tickets/ticket-statuses" element={<><PageTitle title="Ticket Statuses" /><TicketStatuses /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['View Services']} />}>
                  <Route path="/tickets/services" element={<><PageTitle title="Services" /><Services /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['List tickets']} />}>
                  <Route path="/tickets/customers" element={<><PageTitle title="Customers" /><Customers /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['List tickets']} />}>
                  <Route path="/tickets/turn-around-time" element={<><PageTitle title="Turn Around Times" /><TurnAroundTime /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['List tickets']} />}>
                  <Route path="/tickets/make-of-chargers" element={<><PageTitle title="Make Of Chargers" /><MakeOfCharger /></>} />
                </Route>

                <Route element={<ProtectedRoute requiredRoles={['admin', 'staff']} requiredPermissions={['List tickets']} />}>
                  <Route path="/tickets/mode-of-complaints" element={<><PageTitle title="Mode Of Complaints" /><ModeOfComplaint /></>} />
                </Route>
                
              </Routes>
            </div>
          </AdminHeaderProvider>
        </>
      }
    </>
  );
}

export default App;
