import React from 'react';
import Select from 'react-select';

function CustomSelect({ field, form, setSelectCustomrecurring, menuPlacement, onChange, ...props }) {
  const { setFieldValue, setFieldTouched } = form;

  const handleChange = (option) => {
    setFieldValue(field.name, option);
    setFieldTouched(field.name, true, false);

    if (setSelectCustomrecurring) {
      if (field.name === 'recurring' && option && option.value === 'custom') {
        setSelectCustomrecurring(option.value);
      } else {
        setSelectCustomrecurring(option.value);
      }
    }

    if (field.name === 'invoice_items' || field.name === 'state_edit' || field.name === 'city_edit' || field.name === 'city' || field.name === 'state' || field.name === 'role' || field.name === 'assignTicket_edit' || field.name === 'service_edit' || field.name === 'priority_edit' || field.name === 'status' || field.name === 'predefinedReply' || field.name === 'ticket_body' || field.name === 'customer' || field.name === 'ignorecustomer' || field.name === 'selectstaff' || field.name === 'encryption' || field.name === 'emailTo' || field.name === 'templates'  || field.name === 'newitem_tax' || field.name === 'item_tax' || field.name === 'discount' || field.name === 'related' || field.name === 'customer_id') {

      if (onChange) {
        onChange(option); // Pass the selected option to the parent component
      }
    }
  };

  return (
    <Select
      {...field}
      {...props}
      menuPlacement={menuPlacement ? menuPlacement : 'auto'}
      onChange={handleChange}
    />
  );
}

 export default CustomSelect;


// import React from 'react';
// import Select from 'react-select';

// function CustomSelect({ field, form, setSelectCustomrecurring, menuPlacement, onChange, ...props }) {
//   const { name, value } = field; // Destructure name and value from field
//   const { setFieldValue, setFieldTouched } = form;

//   // Function to handle value change
//   const handleChange = (option) => {
//     setFieldValue(name, option); // Set field value
//     setFieldTouched(name, true); // Set field touched

//     // Additional logic if needed
//     if (setSelectCustomrecurring) {
//       if (name === 'recurring' && option && option.value === 'custom') {
//         setSelectCustomrecurring(option.value);
//       } else {
//         setSelectCustomrecurring(option.value);
//       }
//     }

//     if (name === 'invoice_items' || name === 'ignorecustomer' || name === 'customer'  || name === 'selectstaff' || name === 'encryption' || name === 'emailTo' || name === 'templates' || name === 'newitem_tax' || name === 'item_tax' || name === 'discount' || name === 'related' || name === 'customer_id') {
//       if (onChange) {
//         onChange(option); // Pass the selected option to the parent component
//       }
//     }
//   };

//   // Get initial value
//   const initialValue = props.options.find(option => option.value === value);

//   return (
//     <Select
//       {...field}
//       {...props}
//       menuPlacement={menuPlacement ? menuPlacement : 'auto'}
//       onChange={handleChange}
//       value={initialValue} // Set initial value
//     />
//   );
// }

// export default CustomSelect;



