import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import CustomTablePagination from '../../../Parts/CustomTablePagination';
import axiosInstance from '../../../../../Axios/Axios';
import SkeletonComponent from '../../../Parts/SkeletonComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { CSVLink } from 'react-csv';
import NewChargerModal from '../Modals/NewChargerModal';
import { useNotification } from '../../../../Notification/NotificationProvider';



function MakeOfCharger() {

    const token = useSelector(state => state.login.token);
    const user = useSelector((state) => state.login.user);
    const [chargers, setChargers] = useState([]);
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(2);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [editingChargerId, setEditingChargerId] = useState(null)
    const [initialCharger, setInitialCharger] = useState('')
    const { showNotification } = useNotification();
    const dispatch = useDispatch();


    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchChargers = useCallback((signal) => {
        axiosInstance.get(`/support-ticket-make-of-chargers?page=${page + 1}&item=${rowsPerPage}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            signal: signal,
        }).then((res) => {
            setLoading(false)
            setChargers(res.data.make_of_chargers.data);
            // try {
            //     const [
            //       chargerResponse,
            //     ] = Promise.all([
            //       axiosInstance.get("/support-ticket-make-of-chargers", {
            //         headers: { Authorization: `Bearer ${token}` },
            //         signal: signal,
            //       }),
            //     ]);
            //     dispatch({
            //       type: 'SET_INITIAL_DATA',
            //       payload: {
            //         chargers: chargerResponse.data.make_of_chargers.data,
            //       }
            //     });
            //   } catch (error) {
            //     console.error(error);
            //   }
            setTotalItem(res.data.make_of_chargers.meta.total);
            setLastPage(res.data.make_of_chargers.meta.last_page);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                console.error(error.message);
            }
        })
    }, [token, page, rowsPerPage]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchChargers(signal);

        return () => {
            controller.abort();
        };

    }, [fetchChargers]);


    const addedNewCharger = () => {
        fetchChargers();
        setEditingChargerId(null)
    }

    const handleChargerDelete = (id, charger) => {
        if (window.confirm(`Are you sure to delete ${charger} Make of Charger?`)) {
            axiosInstance.delete(`/support-ticket-make-of-chargers/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    fetchChargers();
                    setPage(0);
                }
            }).catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
        }
    };

    return (
        <div className='container customer-Groups-list'>
            <div className="row">
                <div className="col section">
                    <div className="row">
                        <div className="col">
                            {
                                (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                                    <button className='btn btn-primary btn-sm text-white' data-bs-toggle="modal" data-bs-target="#newChargerModal">+ New Charger</button>
                                )
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">
                            {/* <CSVLink data={exportData} filename='Customers.csv' className='btn btn-light btn-sm'>Export</CSVLink> */}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col text-center">
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>#</strong></TableCell>
                                            <TableCell><strong>Make of Charger</strong></TableCell>
                                            {
                                                (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                                                    <TableCell><strong>Action</strong></TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {loading ?
                                        <TableBody>
                                            {[...Array(2)].map((_, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    {
                                                        (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                                        )
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        : (
                                            chargers.length === 0 ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={3} className='text-center'>
                                                            No data available.
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {(rowsPerPage > 0 && chargers || []
                                                    ).map((charger, index) => (
                                                        <TableRow key={charger.id}>
                                                            <TableCell>
                                                                {/* {page* rowsPerPage + index + 1} */}
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    permissions.includes('Edit Services') && roles.includes(role) ? (
                                                                        <Link
                                                                            onClick={() => {
                                                                                setEditingChargerId(charger.id);
                                                                                setInitialCharger(charger.name);
                                                                            }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#newChargerModal"
                                                                        >
                                                                            {charger.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <Link > {charger.name}</Link>
                                                                    )
                                                                }
                                                            </TableCell>
                                                            {
                                                                (permissions.includes('Edit Services') || permissions.includes('Delete Services')) && roles.includes(role) && (
                                                                    <TableCell>
                                                                        {permissions.includes('Edit Services') && (
                                                                            <FontAwesomeIcon
                                                                                icon={faPenToSquare}
                                                                                className='me-2 edit-icon'
                                                                                onClick={() => {
                                                                                    setEditingChargerId(charger.id);
                                                                                    setInitialCharger(charger.name);
                                                                                }}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#newChargerModal"
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Edit"
                                                                            />
                                                                        )}

                                                                        {permissions.includes('Delete Services') && (
                                                                            <FontAwesomeIcon
                                                                                onClick={() => handleChargerDelete(charger.id, charger.name)}
                                                                                icon={faTrashCan}
                                                                                className='delete-icon'
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Delete" />
                                                                        )}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )
                                        )}
                                </Table>
                            </TableContainer>
                            {chargers.length !== 0 && (
                                <CustomTablePagination
                                    page={page}
                                    setPage={setPage}
                                    count={totalItem}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    lastPage={lastPage}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <NewChargerModal editingChargerId={editingChargerId} initialCharger={initialCharger} addedNewCharger={addedNewCharger} />
        </div>
    )
}

export default MakeOfCharger