// import React, { useEffect, useState } from 'react';


// const Notification = ({ message, type, onClose }) => {
//   const [timeLeft, setTimeLeft] = useState(100);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTimeLeft((prev) => prev - 1);
//     }, 100);

//     const timer = setTimeout(onClose, 2500); // Show notification for 10 seconds
//     return () => {
//       clearTimeout(timer);
//       clearInterval(interval);
//     };
//   }, [onClose]);

//   return (
//     <div className={`notification ${type}`}>
//       {message}
//       <div className="time-bar" style={{ width: `${timeLeft}%` }} />
//     </div>
//   );
// };

// export default Notification;





// Notification.js
// import React, { useEffect, useState } from 'react';


// const Notification = ({ message, type, onClose }) => {
//   const [hide, setHide] = useState(false);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setHide(true);
//     }, 3000); // Show notification for 10 seconds

//     return () => clearTimeout(timer);
//   }, []);

//   useEffect(() => {
//     if (hide) {
//       const hideTimer = setTimeout(onClose, 500); // Hide notification after slide out animation
//       return () => clearTimeout(hideTimer);
//     }
//   }, [hide, onClose]);

//   return (
//     <div className={`notification ${type} ${hide ? 'hide' : ''}`}>
//       <button className="close-btn" onClick={onClose}>×</button>
//       {message}
//       <div className="time-bar" />
//     </div>
//   );
// };

// export default Notification;


import React, { useEffect, useState } from 'react';
import { FaBell } from "react-icons/fa";

const Notification = ({ message, type, onClose }) => {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHide(true);
    }, 3000); // Show notification for 3 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (hide) {
      const hideTimer = setTimeout(onClose, 500); // Hide notification after slide out animation
      return () => clearTimeout(hideTimer);
    }
  }, [hide, onClose]);

  return (
    <div className={`notification ${type} ${hide ? 'hide' : ''}`}>
      <FaBell className='me-2' />
      <button className="close-btn" onClick={onClose}>&#215;</button>
      {message}
      <div className="time-bar" />
    </div>
  );
};

export default Notification;








