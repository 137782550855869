import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CustomSelect from '../../Parts/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faXmark, faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaRegFilePdf, FaFile } from "react-icons/fa";
import { BsFiletypeCsv, BsFileSpreadsheet, BsFileText } from "react-icons/bs";
import axiosInstance from '../../../../Axios/Axios';
import Loading from '../../Parts/Loading';
import NewServicesModal from '../SetUp/Modals/NewServicesModal';
import Replys from './Replys';
import { useNotification } from '../../../Notification/NotificationProvider';



const TicketReply = () => {

    const { showNotification } = useNotification();
    const closeRef = useRef(null);
    const navigate = useNavigate()
    const { id, setting } = useParams();
    const token = useSelector((state) => state.login.token);
    const user = useSelector(state => state.login.user);
    // const initial_services = useSelector((state) => state.login.services);
    // const initial_departments = useSelector((state) => state.login.departments);
    // const initial_staffs = useSelector((state) => state.login.staffs);
    // const initial_priorities = useSelector((state) => state.login.priorities);
    // const initial_preDefinedReplies = useSelector((state) => state.login.preDefinedReplies);
    // const initial_statuses = useSelector((state) => state.login.statuses);
    // const initial_customers = useSelector((state) => state.login.customers);
    // const initial_states = useSelector((state) => state.login.states);
    // const initial_complaints = useSelector((state) => state.login.complaints);
    // const initial_chargers = useSelector((state) => state.login.chargers);
    const [activeTab, setActiveTab] = useState('addReply');
    const [updateNoteId, setUpdateNoteId] = useState(null);
    const quillRef = useRef(null);
    const [value, setValue] = useState({ quill: '' });
    const [valRue, setRValue] = useState({ quill: '' });
    const [file, setFile] = useState(null);
    const [ticketToReply, setTicketToReply] = useState([]);
    const [ticketStatus, setTicketStatus] = useState([]);
    const [preDefinedReplies, setPreDefinedReplies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [staffs, setStaffs] = useState([]);
    const [services, setServices] = useState([]);
    const [prioritys, setPrioritys] = useState([]);
    const [replyNotes, setReplyNotes] = useState([]);
    const [ticketReplys, setTicketReplys] = useState([]);
    const [tId, setTId] = useState('');
    const [reload, setReload] = useState(false);
    const [editingReplyID, setEditingReplyID] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [complaints, setComplaints] = useState([]);
    const [chargers, setChargers] = useState([]);

    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];

    const logged_id = user.id;

    const [inputValue, setInputValue] = useState('');
    const [emails, setEmails] = useState([]);

    const dropzoneRef = useRef(null);
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [popupImage, setPopupImage] = useState(null);


    const [editingNoteId, setEditingNoteId] = useState(null);
    const handleEditClick = (noteId) => {
        if (editingNoteId === noteId.id) {
            setEditingNoteId(null);
        } else {
            setEditingNoteId(noteId.id);
            setInitialValues({ Update_note: noteId.body })
        }
    };

    const [initialValues, setInitialValues] = useState({
        add_note: '',
        email_cc: '',
        status_response: '',
        assignTicket_edit: '',
        service_edit: "",
        priority_edit: '',
        email_edit: '',
        name_edit: '',
        subject_edit: '',
        status: '',
        customer_edit: '',
        state_edit: '',
        city_edit: '',
        mode_of_complaint: '',
        make_of_charger: ''
    });

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addEmail();
        } else if (e.key === 'Backspace' && !inputValue) {
            removeLastEmail();
        }
    };

    const addEmail = () => {
        const trimmedValue = inputValue.trim();
        if (trimmedValue && validateEmail(trimmedValue)) {
            setEmails([...emails, trimmedValue]);
            setInputValue('');
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const removeEmail = (index) => {
        setEmails(emails.filter((email, i) => i !== index));
    };

    const removeLastEmail = () => {
        setEmails(emails.slice(0, -1));
    };

    const fetchTicketForReply = async (signal) => {
        axiosInstance.get(`support-ticket/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            signal: signal,
        }).then((res) => {
            setLoading(false);
            const ticket = res.data.data;
            setTicketToReply(res.data.data);
            setTId(ticket.created.id);
            setTicketReplys(res.data.replies);
            setReplyNotes(res.data.notes);
            setInitialValues({
                subject_edit: ticket.subject,
                name_edit: ticket.name,
                email_edit: ticket.email,
                assignTicket_edit: { label: ticket.staff_id?.name, value: ticket.staff_id?.id },
                priority_edit: { label: ticket.ticket_priority_id?.name, value: ticket.ticket_priority_id?.id },
                service_edit: { label: ticket.ticket_service_id?.name, value: ticket.ticket_service_id?.id },
                status: { label: ticket.ticket_status_id?.name, value: ticket.ticket_status_id?.id },
                customer_edit: { label: ticket.customer?.name, value: ticket.customer?.id },
                state_edit: { label: ticket.state?.name, value: ticket.state?.id },
                city_edit: { label: ticket.city?.city, value: ticket.city?.id },
                mode_of_complaint: { label: ticket.mode_of_complaint?.name, value: ticket.mode_of_complaint?.id },
                make_of_charger: { label: ticket.make_of_charger?.name, value: ticket.make_of_charger?.id },
            })
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                setLoading(false)
                navigate('/');
            } else {
                console.error(error.message);
            }
        })
    }

    const stripHtmlTags = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || '';
    };
    //console.log(ticketReplys);

    const fetchInitialValues = useCallback(async (signal) => {
        try {
            const [ticket_statusResponse, servicesResponse, staffResponse, ticket_priorityResponse, pre_defined_repliesResponse, customerResponse, stateResponse, complaintsResponse, chargersResponse] = await Promise.all([
                axiosInstance.get('/ticket-statuses', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/services', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/staff', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/ticket-priority', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/pre-defined-replies', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/support-ticket-customers', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/states', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/support-ticket-mode-of-complaints', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/support-ticket-make-of-chargers', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
            ]);

            setTicketStatus(ticket_statusResponse.data.ticketStatuses.data);
            setServices(servicesResponse.data.services.data);
            setStaffs(staffResponse.data.staff.data);
            setPrioritys(ticket_priorityResponse.data.ticketPriorities.data);
            setPreDefinedReplies(pre_defined_repliesResponse.data.preDefinedReplies.data);
            setCustomers(customerResponse.data.customers.data);
            setStates(stateResponse.data.states);
            setComplaints(complaintsResponse.data.mode_of_complaints.data)
            setChargers(chargersResponse.data.make_of_chargers.data)
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    const addedNewService = () => {
        fetchInitialValues();
    }

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        if (setting) {
            setActiveTab(setting);
        }

        // if (!initial_services || initial_services.length === 0) {
        //     fetchInitialValues(signal);
        // } else {
        //     setLoading(true);
        //     setTicketStatus(initial_statuses);
        //     setServices(initial_services);
        //     setStaffs(initial_staffs);
        //     setPrioritys(initial_priorities);
        //     setPreDefinedReplies(initial_preDefinedReplies);
        //     setCustomers(initial_customers);
        //     setStates(initial_states);
        //     setComplaints(initial_complaints)
        //     setChargers(initial_chargers)
        //     setLoading(false);
        // }

        fetchInitialValues(signal);

        return () => {
            controller.abort();
        };

    }, [token]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchTicketForReply(signal);

        return () => {
            controller.abort();
        };

    }, [id, token, setting, fetchInitialValues, reload])

    const handleBodySelection = (selectedOption) => {
        const selectedReplyId = selectedOption.value;
        const reply = preDefinedReplies.find(reply => reply.id === selectedReplyId);
        setValue({ quill: reply.reply });
    }


    const handleStatusSelection = (selection) => {
        const status_is = selection.value;

        if (status_is) {
            axiosInstance.post(`/support-ticket/${id}/is-active`, { ticket_status_id: status_is }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                // console.log(res);
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    fetchTicketForReply();
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            });
        }
    }


    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['image', 'video'],
                ['clean'],
            ],
        },
    };

    const onSubmit = (formData, { resetForm }) => {

        if (activeTab === "setting") {

            const ticket_service_id = formData?.service_edit.value;
            const staff_id = formData?.assignTicket_edit.value;
            const ticket_priority_id = formData?.priority_edit.value;
            const city_id = formData?.city_edit.value;
            const charger_id = formData?.make_of_charger?.value;
            const complaint_id = formData?.mode_of_complaint?.value;
            const customer_id = formData?.customer_edit?.value;

            const data_Update = {
                "subject": formData.subject_edit,
                "name": ticketToReply.name,
                "email": ticketToReply.email,
                "department_id": ticketToReply.ticket_department_id.id,
                "email_cc": ticketToReply.email_cc,
                "city_id": city_id,
                "customer_id": customer_id,
                "ticket_priority_id": ticket_priority_id ? ticket_priority_id : null,
                "ticket_status_id": ticketToReply.ticket_status_id ? ticketToReply.ticket_status_id.id : null,
                "ticket_service_id": ticket_service_id ? ticket_service_id : null,
                "staff_id": staff_id ? staff_id : null,
                "predefined_reply_id": ticketToReply.predefined_reply_id ? ticketToReply.predefined_reply_id.id : null,
                "ticket_body": ticketToReply.ticket_body,
                "attachment": ticketToReply.attachment,
                "make_of_charger_id": charger_id,
                "mode_of_complaint_id": complaint_id,
            }

            axiosInstance.put(`/support-ticket/${id}`, data_Update, {
                headers: {
                    'Authorization': `Bearer ${token}`,

                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    navigate('/tickets')
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            })

        } else if (activeTab === "addNote") {

            const data_note = {
                "support_ticket_id": Number(id),
                "user_id": user ? user.id : "",
                "body": formData.add_note
            }

            axiosInstance.post('support-ticket-note', data_note, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    navigate('/tickets')
                    setInitialValues({ add_note: '' })
                    setActiveTab("addReply");
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    setLoading(false);
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            })


        } else if (updateNoteId !== null) {
            const data_update_note = {
                "support_ticket_id": Number(id),
                "user_id": user.id,
                "body": formData.Update_note
            }

            axiosInstance.put(`support-ticket-note/${updateNoteId}`, data_update_note, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    setUpdateNoteId(null);
                    fetchTicketForReply();
                    setEditingNoteId(null);
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            })

        } else {
            setLoading(true)
            const emailString = emails.join(", ");
            const attachment_files = files.map((file) => file?.file);
            const data_Create = {
                "support_ticket_id": Number(id),
                "user_id": user ? user.id : "",
                "predefined_reply_id": formData.predefinedReply ? formData.predefinedReply.value : "",
                "body": value.quill,
                "email_cc": emailString,
                "attachment": attachment_files,
                "ticket_status_id": formData.status_response ? formData.status_response.value : null
            }


            axiosInstance.post('support-ticket-reply', data_Create, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }).then((res) => {
                if (res.data.success) {
                    setLoading(false)
                    const message = res.data.message;
                    showNotification(message, 'success');
                    fetchTicketForReply();
                    navigate('/tickets')
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    // console.error(error.message);
                }
            })
        }
    }

    function capitalizeFirstChar(str) {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    const handleNoteDelete = (noteDeleteId) => {
        if (window.confirm(`Are you sure to delete This Note?`)) {
            axiosInstance.delete(`/support-ticket-note/${noteDeleteId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    //setPage(0)
                    fetchTicketForReply();
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            });
        }
    }

    const handleReplyForm = (formData, { resetForm }) => {

        const data = {
            "support_ticket_id": id,
            "ticket_body": valRue.quill,
            "subject": ticketToReply.subject,
            "name": ticketToReply.name,
            "email": ticketToReply.email,
            "department_id": ticketToReply.ticket_department_id.id,
        }


        axiosInstance.put(`/support-ticket/${id}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then((res) => {
            if (res.data.success) {
                const message = res.data.message;
                showNotification(message, 'success');
                fetchTicketForReply();
                resetForm(); // Reset the form values
                closeRef.current.click();
            }

        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                showNotification(error.message, 'error');
                console.error(error.message);
            }
        });
    }


    const handleTicketDelete = (ticketDeleteId) => {
        if (window.confirm(`Are you sure to delete This Ticket?`)) {
            axiosInstance.delete(`/support-ticket/${ticketDeleteId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    //setPage(0)
                    navigate('/tickets')
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            });
        }
    }


    //state selection
    const handleStateSelection = (object) => {
        axiosInstance
            .get(`/cities/${object?.value}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setCities(res.data.cities);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate("/");
                } else {
                    console.error(error.message);
                }
            });

    }


    const handleDragOver = (event) => {
        event.preventDefault();
        dropzoneRef.current.classList.add('drag-over');
    };


    const handleDragLeave = () => {
        dropzoneRef.current.classList.remove('drag-over');
    };


    const handleDrop = (event) => {
        event.preventDefault();
        dropzoneRef.current.classList.remove('drag-over');
        const newFiles = Array.from(event.dataTransfer.files).map(file => ({
            file,
            progress: 100
        }));
        setFiles([...files, ...newFiles]);
    };


    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files).map(file => ({
            file,
            progress: 100
        }));
        setFiles([...files, ...newFiles]);
    };



    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
    };

    const handleImageClick = (image) => {
        setPopupImage(image);
    };

    // Function to close the popup
    const closePopup = () => {
        setPopupImage(null);
    };


    return (
        <div className='section container customer-page'>
            {loading && (
                <div className="loading-overlay">
                    <Loading />
                </div>
            )}
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                <Form>
                    <div className="row justify-content-between">
                        <div className="col d-flex align-items-center">
                            <h4 className='mb-0' style={{ marginRight: '20px' }}>&#35;{ticketToReply.id} - {ticketToReply?.ticket_service_id?.name}</h4>
                            {ticketToReply && ticketToReply.ticket_status_id &&
                                <div
                                    className="rounded d-inline-block"
                                    style={{
                                        fontSize: 14,
                                        padding: 4,
                                        width: 80,
                                        textAlign: "center",
                                        color: ticketToReply.ticket_status_id.color,
                                        borderColor: ticketToReply.ticket_status_id.color,
                                        borderWidth: 1,
                                        borderStyle: 'solid'
                                    }}
                                >
                                    {capitalizeFirstChar(ticketToReply.ticket_status_id.name)}
                                </div>
                            }
                        </div>
                        {((permissions.includes('Edit tickets') && logged_id == tId) || permissions.includes('Edit others tickets')) && roles.includes(role) && (
                            <div className="col-4 ">
                                <Field
                                    component={CustomSelect}
                                    placeholder="Change Status"
                                    name="status"
                                    id="status"
                                    options={ticketStatus?.map((ticketStatuss) => ({ label: ticketStatuss.name, value: ticketStatuss.id }))}
                                    onChange={handleStatusSelection}
                                />
                            </div>
                        )}
                    </div>
                    <hr className='my-4' />
                    <div className="section">
                        <div className="col p-0 ticket_header">
                            <div className="row pt-2 heading no-gutter">
                                <div className={`col-2 cp ${activeTab === 'addReply' ? 'active' : ''}`} onClick={() => handleTabClick('addReply')}><span>Add Reply</span></div>
                                <div className={`col-2 cp ${activeTab === 'addNote' ? 'active' : ''}`} onClick={() => handleTabClick('addNote')}><span>Add Note</span></div>
                                {((permissions.includes('Delete tickets') && logged_id == tId) || permissions.includes('Delete others tickets')) && roles.includes(role) && (
                                    <div className={`col-2 cp ${activeTab === 'setting' ? 'active' : ''}`} onClick={() => handleTabClick('setting')}><span>Settings</span></div>
                                )}
                            </div>
                        </div>
                        <hr className='mt-2 mb-4 text-secondary' />
                        {activeTab === 'addReply' && (
                            <div className='div'>
                                {replyNotes && replyNotes.length !== 0 && (
                                    <div className='my-3'>
                                        <h6>Private Staff Notes</h6>
                                        {replyNotes.map((replyNote, index) => (
                                            <div key={index} className="section bg-warning-subtle text-warning-emphasis my-2">
                                                <div className="row">
                                                    <div className="col-10">
                                                        <div className="row">
                                                            <div className="col m-0">
                                                                <h6 className='m-0'> {"Ticket note by " + replyNote.user?.name + " " + replyNote.user?.last_name}</h6>
                                                                <p style={{ fontSize: "13px" }}>{"Note added: " + new Date(replyNote.created_at).toLocaleString('en-GB')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col d-flex justify-content-end">
                                                        <div className="row justify-content-end">
                                                            <div className="col-1">
                                                                <FontAwesomeIcon
                                                                    icon={faPenToSquare}
                                                                    className='edit-icon '
                                                                    onClick={() => {
                                                                        // setToggle(!toggle)
                                                                        handleEditClick(replyNote)
                                                                        setUpdateNoteId(replyNote.id);
                                                                    }}
                                                                    data-tooltip-id="my-tooltip"
                                                                    data-tooltip-content="Edit Note"
                                                                />
                                                            </div>
                                                            <div className="col-1">
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan}
                                                                    className='edit-icon align-self-end'
                                                                    onClick={() => {
                                                                        handleNoteDelete(replyNote.id);
                                                                    }}
                                                                    data-tooltip-id="my-tooltip"
                                                                    data-tooltip-content="Delete Note"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {editingNoteId === replyNote.id ? (

                                                        <div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <Field as='textarea' rows={4} type="text" name="Update_note" className="form-control" id="update_note" />
                                                                </div>
                                                            </div>
                                                            <div className="row my-2">
                                                                <div className="col d-flex justify-content-end">
                                                                    <span className='btn btn-secondary btn-sm mx-2' onClick={() => { setEditingNoteId(null) }}>Close</span>
                                                                    <button className='btn btn-primary btn-sm text-white'>Update Note</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="col">
                                                            <h6>{replyNote.body}</h6>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <div className="row my-2">
                                    <div className="d-flex align-items-center">
                                        {((permissions.includes('Delete tickets') && logged_id == tId) || permissions.includes('Delete others tickets')) && roles.includes(role) && (
                                            <FontAwesomeIcon icon={faTrashCan} className='pe-2' cursor={"pointer"} style={{ height: 20, marginTop: -20 }} onClick={() => handleTicketDelete(ticketToReply.id)} />
                                        )}
                                        {ticketToReply?.ticket_priority_id ? (
                                            <span className={`alert alert-light`} style={{ fontSize: 14, padding: 5 }}>
                                                {"Priority: " + (ticketToReply.ticket_priority_id.name || "N/A")}
                                            </span>
                                        ) : (
                                            null // or you can replace with any other JSX element if needed
                                        )}
                                        {ticketToReply?.ticket_department_id ? (
                                            <span className={`alert alert-${'light'} mx-2`} style={{ fontSize: 14, padding: 5 }}>
                                                {"Department: " + (ticketToReply?.ticket_department_id?.name || "N/A")}
                                            </span>
                                        ) : (
                                            null
                                        )}
                                        {ticketToReply.staff_id ? (
                                            <span className={`alert alert-${'info'}`} style={{ fontSize: 14, padding: 5 }}>
                                                {"Assigned: " + (ticketToReply.staff_id ? (ticketToReply.staff_id.name ? ticketToReply.staff_id.name : "N/A") + ' ' + (ticketToReply.staff_id.last_name ? ticketToReply.staff_id.last_name : "") : "N/A")}
                                            </span>
                                        ) : null}
                                        {ticketToReply.make_of_charger ? (
                                            <span className={`alert alert-${'warning'} mx-2`} style={{ fontSize: 14, padding: 5 }}>
                                                {"Make of Charger: " + (ticketToReply?.make_of_charger?.name || "N/A")}
                                            </span>
                                        ) : null}
                                        {ticketToReply.mode_of_complaint ? (
                                            <span className={`alert alert-${'success'}`} style={{ fontSize: 14, padding: 5 }}>
                                                {"Mode of Complaint: " + (ticketToReply?.mode_of_complaint?.name || "N/A")}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <Field
                                            component={CustomSelect}
                                            placeholder="Insert Predefined reply"
                                            name="predefinedReply"
                                            id="predefinedReply"
                                            options={preDefinedReplies?.map((preDefinedReplie) => ({ label: preDefinedReplie.name, value: preDefinedReplie.id }))}
                                            onChange={handleBodySelection}
                                        />
                                    </div>
                                    <div className="col"></div>
                                </div>
                                <div className="row mb-5">
                                    <div className='mb-2' style={{ height: 250 }} >
                                        <ReactQuill
                                            theme="snow"
                                            name="quill"
                                            ref={quillRef}
                                            value={value.quill}
                                            onChange={(value) => {
                                                setValue({ ...value, quill: value });
                                            }}
                                            modules={modules}
                                            style={{ height: '90%' }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="department" className="form-label">Change Status</label>
                                                    <Field
                                                        component={CustomSelect}
                                                        placeholder="Change Status"
                                                        name="status_response"
                                                        id="status_response"
                                                        options={ticketStatus?.map((ticketStatuss) => ({ label: ticketStatuss.name, value: ticketStatuss.id }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                {/* <div className="mb-3">
                                            <label htmlFor="email_CC" className="form-label">CC</label>
                                            <Field
                                                id="emailCC"
                                                name="email_cc"
                                                type="email"
                                                className="form-control"
                                            />
                                        </div> */}
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">CC</label>
                                                    <div className="multi-email-input">
                                                        {emails.map((email, index) => (
                                                            <div key={index} className="tag">
                                                                {email}
                                                                <span className="remove" onClick={() => removeEmail(index)}>
                                                                    &times;
                                                                </span>
                                                            </div>
                                                        ))}
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={inputValue}
                                                            onChange={handleInputChange}
                                                            onKeyDown={handleKeyDown}
                                                            placeholder="Enter email and press Enter or comma"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-3">
                                        <div className="row">
                                            <div className="col">
                                                <div
                                                    id="dropzoneTaskComment"
                                                    className="dropzoneDragArea dz-default dz-message task-comment-dropzone dz-clickable"
                                                    onDragOver={handleDragOver}
                                                    onDragLeave={handleDragLeave}
                                                    onDrop={handleDrop}
                                                    onClick={handleClick}
                                                    ref={dropzoneRef}
                                                >
                                                    <div className="dropzone-content">
                                                        <FontAwesomeIcon icon={faUpload} className="upload-icon" />
                                                        <span className="upload-text">Drop or Click here to upload files</span>
                                                        <span className="accepted-file-types">Accepted file types: JPEG, Doc, PDF, PNG</span>
                                                    </div>
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                        multiple
                                                    />
                                                </div>
                                                <div className="files-list">
                                                    {files.map((fileObj, index) => (
                                                        <div key={index} className="file-item">
                                                            <span>{fileObj.file.name}</span>
                                                            <div className="progress-bar">
                                                                <div
                                                                    className="progress"
                                                                    style={{ width: `${fileObj.progress}%` }}
                                                                />
                                                            </div>
                                                            <FontAwesomeIcon
                                                                className="text-danger"
                                                                icon={faXmark}
                                                                onClick={() => handleRemoveFile(index)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {permissions.includes('Add reply') && roles.includes(role) && (
                                    <div className="row invoice-footer">
                                        <div className="col text-end">
                                            <button type='submit' className="btn btn-primary btn-sm text-white fw-medium">Add Response</button>
                                        </div>
                                    </div>
                                )}
                                <div className="section reply">
                                    <div className="row">
                                        <div className="col-3 title">
                                            <div className="row">
                                                <div className="col">
                                                    <sapn className="text-secondary fs-6">Created by</sapn>
                                                    <h4>{ticketToReply?.name}</h4>
                                                </div>
                                            </div>
                                            <hr className='text-secondary' />
                                            <div className="row">
                                                <div className="col">
                                                    <sapn className="text-secondary fs-6">Customer</sapn>
                                                    <h4>{ticketToReply?.customer?.name}</h4>
                                                </div>
                                            </div>
                                            <hr className='text-secondary' />
                                            <div className="row">
                                                <div className="col">
                                                    <sapn className="text-secondary fs-6">City & State</sapn>
                                                    <h4>{ticketToReply?.city?.city}, {ticketToReply?.state?.name}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-end">
                                                    <FontAwesomeIcon
                                                        icon={faPenToSquare}
                                                        className='edit-icon align-self-end'
                                                        onClick={() => {
                                                            setEditingReplyID(ticketToReply.id);
                                                            setRValue({ quill: ticketToReply.ticket_body })
                                                        }}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#updateRReplyModal"
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Edit Reply"
                                                    />
                                                </div>
                                            </div>
                                            {/* Second row */}
                                            <div className="row">
                                                <div className="col">
                                                    {/* <h4>{stripHtmlTags(ticketToReply.ticket_body)}</h4> */}
                                                    <div dangerouslySetInnerHTML={{ __html: ticketToReply?.ticket_body }} />
                                                </div>
                                            </div>
                                            <div className="row my-2">
                                                <div className="col">
                                                    {ticketToReply.attachments && ticketToReply.attachments.length > 0 && (
                                                        <>
                                                            {ticketToReply.attachments.map((attachment) => (
                                                                <div key={attachment.id} className='row mb-2'>
                                                                    <div className="col">
                                                                        <p>{attachment.attachment_original_name}</p>
                                                                        {attachment.attachment?.toLowerCase()?.endsWith('.pdf') ? (
                                                                            <>
                                                                                <FaRegFilePdf className='mx-1 text-primary' />
                                                                                <a
                                                                                    href={attachment.attachment}
                                                                                    target='_blank'
                                                                                    rel='noopener noreferrer'
                                                                                    className='text-primary'
                                                                                    download={attachment.attachment_original_name}
                                                                                >
                                                                                    Download PDF
                                                                                </a>
                                                                            </>
                                                                        ) : attachment.attachment_original_name?.toLowerCase().endsWith('.csv') ? (
                                                                            <>
                                                                                <BsFiletypeCsv className='mx-1 text-primary' />
                                                                                <a
                                                                                    href={attachment.attachment}
                                                                                    target='_blank'
                                                                                    rel='noopener noreferrer'
                                                                                    className='text-primary'
                                                                                    download={attachment.attachment_original_name}
                                                                                >
                                                                                    Download CSV
                                                                                </a>
                                                                            </>
                                                                        ) : attachment.attachment_original_name?.toLowerCase().endsWith('.txt') ? (
                                                                            <>
                                                                                <BsFileText className='mx-1 text-primary' />
                                                                                <a
                                                                                    href={attachment.attachment}
                                                                                    target='_blank'
                                                                                    rel='noopener noreferrer'
                                                                                    className='text-primary'
                                                                                    download={attachment.attachment_original_name}
                                                                                >
                                                                                    Download TXT
                                                                                </a>
                                                                            </>
                                                                        ) : attachment.attachment_original_name?.toLowerCase().endsWith('.xlsx') ? (
                                                                            <>
                                                                                <BsFileSpreadsheet className='mx-1 text-primary' />
                                                                                <a
                                                                                    href={attachment.attachment}
                                                                                    target='_blank'
                                                                                    rel='noopener noreferrer'
                                                                                    className='text-primary'
                                                                                    download={attachment.attachment_original_name}
                                                                                >
                                                                                    Download XLSX
                                                                                </a>
                                                                            </>
                                                                        ) : attachment.attachment?.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) ? (
                                                                            <img
                                                                                src={attachment.attachment}
                                                                                alt="Image"
                                                                                style={{ width: '200px', height: "200px" }}
                                                                                onClick={() => handleImageClick(attachment.attachment)}
                                                                            />
                                                                        ) : attachment.attachment ? (
                                                                            <>
                                                                                <FaFile className='mx-1 text-primary' />
                                                                                <a
                                                                                    href={attachment?.attachment}
                                                                                    target='_blank'
                                                                                    rel='noopener noreferrer'
                                                                                    className='text-primary'
                                                                                    download
                                                                                >
                                                                                    Download File
                                                                                </a>
                                                                            </>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {popupImage && (
                                                                <div className="image-popup-overlay" onClick={closePopup}>
                                                                    <div className="image-popup-content">
                                                                        <span className="image-popup-close" onClick={closePopup}>&times;</span>
                                                                        <img src={popupImage} alt="Popup Attachment" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-top my-3"></div>
                                    <div className='text-secondary'>{"Posted: " + new Date(ticketToReply.created_at).toLocaleString('en-GB')}</div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'addNote' && (
                            <div className="container">
                                <div className="mb-3">
                                    <label htmlFor="add_note" className="form-label">Note</label>
                                    <Field as='textarea' rows={4} type="text" name="add_note" className="form-control" id="add_note" />
                                </div>
                                <div className="row mt-5">
                                    <div className="col text-end">
                                        <button className="btn btn-primary btn-sm text-white fw-medium">Add Note</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* {activeTab === 'reminders' && (
                            <h2>hi</h2>
                        )}
                        {activeTab === 'otherTickets' && (
                            <h2>hi</h2>
                        )}
                        {activeTab === 'Task' && (
                            <h2>hi</h2>
                        )} */}
                        {((permissions.includes('Delete tickets') && logged_id == tId) || permissions.includes('Delete others tickets')) && roles.includes(role) && (
                            activeTab === 'setting' && (
                                <div className="row">
                                    <div className="col">
                                        <div className="mb-3">
                                            <label htmlFor="service" className="form-label">
                                                <span className="required-star">*</span>Service
                                            </label>
                                            <Field
                                                name="service_edit"
                                                component={CustomSelect}
                                                options={services?.map((service) => ({ value: service.id, label: service.name }))}
                                            />
                                            <ErrorMessage name='service' component='div' className='text-danger' />
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="name" className="form-label">Auther Name</label>
                                                    <Field
                                                        id="name"
                                                        name="name_edit"
                                                        type="text"
                                                        className="form-control"
                                                        style={{ color: 'grey', cursor: 'not-allowed' }}
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Cannot Edit Name"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email address</label>
                                                    <Field
                                                        id="email"
                                                        name="email_edit"
                                                        type="email"
                                                        className="form-control"
                                                        style={{ color: 'grey', cursor: 'not-allowed' }}
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Cannot Edit Email"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="mode_of_complaint" className="form-label">
                                                        Mode of Complaint
                                                    </label>
                                                    <Field
                                                        name="mode_of_complaint"
                                                        component={CustomSelect}
                                                        options={complaints?.map((complaint) => ({ value: complaint.id, label: complaint.name }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="state" className="form-label">
                                                        State
                                                    </label>
                                                    <Field
                                                        name="state_edit"
                                                        component={CustomSelect}
                                                        options={states?.map((state) => ({ value: state.id, label: state.name }))}
                                                        onChange={handleStateSelection}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="mb-3">
                                            <label htmlFor="assignTicket" className="form-label">Assign Ticket &#40;default is current user&#41;</label>
                                            <Field
                                                component={CustomSelect}
                                                id="assignTicket"
                                                name="assignTicket_edit"
                                                options={staffs?.map((staff) => ({ label: staff.name, value: staff.id }))}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="priority" className="form-label">Priority</label>
                                                    <Field
                                                        component={CustomSelect}
                                                        id="priority"
                                                        name="priority_edit"
                                                        options={prioritys?.map((priority) => ({ label: priority.name, value: priority.id }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-3">
                                                    <label htmlFor="customer" className="form-label">Customer</label>
                                                    <Field
                                                        name="customer_edit"
                                                        component={CustomSelect}
                                                        options={customers?.map((customer) => ({ value: customer.id, label: customer.name }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="make_of_charger" className="form-label">
                                                Make of Charger
                                            </label>
                                            <Field
                                                name="make_of_charger"
                                                component={CustomSelect}
                                                options={chargers?.map((charger) => ({ value: charger.id, label: charger.name }))}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">
                                                City
                                            </label>
                                            <Field
                                                name="city_edit"
                                                component={CustomSelect}
                                                options={cities?.map((city) => ({ value: city.id, label: city.city }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col text-end">
                                            <button type='submit' className="btn btn-primary btn-sm text-white fw-medium">Update</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </Form>
            </Formik>

            <div className="modal fade" tabIndex="-1" id="updateRReplyModal">
                <div className="modal-dialog modal-dialog-scrollable" style={{ maxWidth: 700, height: 800 }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>Edit</h5>
                            <button type="button" className="btn-close" ref={closeRef} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                onSubmit={handleReplyForm}
                            >
                                <Form>
                                    <div className='mb-3' style={{ height: 250 }} >
                                        <ReactQuill
                                            theme="snow"
                                            name="quill"
                                            ref={quillRef}
                                            value={valRue.quill}
                                            onChange={(value) => {
                                                setRValue({ ...value, quill: value });
                                            }}
                                            modules={modules}
                                            style={{ height: '80%' }}
                                        />
                                    </div>
                                    <div className="modal-footer mt-5">
                                        <button type="submit" className="btn btn-primary text-white btn-sm">
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <Replys replies={ticketReplys} reload={reload} setReload={setReload} />
            <NewServicesModal addedNewService={addedNewService} />
        </div >
    )
}

export default TicketReply