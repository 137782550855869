import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../../Axios/Axios';
import SkeletonComponent from '../../../Parts/SkeletonComponent';
import CustomTablePagination from '../../../Parts/CustomTablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import NewTurnAroundTimeModal from '../Modals/NewTurnAroundTimeModal';
import { useNotification } from '../../../../Notification/NotificationProvider';
import { CSVLink } from 'react-csv';


function TurnAroundTime() {

    const token = useSelector(state => state.login.token);
    const [turnAroundTime, setTurnAroundTime] = useState([]);
    const [initialTime, setInitialTime] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [services, setServices] = useState([]);
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const { showNotification } = useNotification();
    const [searchTerm, setSearchTerm] = useState("");

    const exportData = turnAroundTime ? turnAroundTime.map((time, index) => ({
        '#': index + 1,
        'Service': time?.customer?.name,
        'Customer': time?.service?.name,
        'Time (min)': time?.turn_around_time
    })) : [];


    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeSearch = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchInitialValues = useCallback(async (signal) => {
        try {
            const [
                servicesResponse,
                customerResponse
            ] = await Promise.all([
                axiosInstance.get("/services", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/support-ticket-customers", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
            ]);
            setServices(servicesResponse.data.services.data);
            setCustomers(customerResponse.data.customers.data)
        } catch (error) {
            console.error(error);
        }
    }, [token]);


    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchInitialValues(signal);

        return () => {
            controller.abort();
        };

    }, [fetchInitialValues]);


    const fetchTimer = useCallback((signal) => {
        axiosInstance.get(`/support-ticket-turn-around-time?search=${searchTerm}&page=${page + 1}&item=${rowsPerPage}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            signal: signal,
        }).then((res) => {
            setLoading(false);
            setTurnAroundTime(res.data.tats.data);
            setTotalItem(res.data.tats.meta.total);
            setLastPage(res.data.tats.meta.last_page);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                setLoading(false)
                navigate('/');
            } else {
                console.error(error.message);
            }
        })
    }, [token, searchTerm, page, rowsPerPage]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchTimer(signal);

        return () => {
            controller.abort();
        };

    }, [fetchTimer]);

    const addedNewCTime = () => {
        fetchTimer();
        setInitialTime(null);
    }

    const handleTimeDelete = (TurnAroundTime) => {
        if (window.confirm(`Are you sure to delete this Timer?`)) {
            axiosInstance.delete(`/support-ticket-turn-around-time/${TurnAroundTime.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    fetchTimer();
                    setPage(0);
                }
            }).catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
        }
    };


    return (
        <div className='container customer-Groups-list'>
            <div className="row">
                <div className="col section">
                    <div className="row">
                        <div className="col">
                            {
                                (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                                    <button className='btn btn-primary btn-sm text-white' onClick={() => setInitialTime(null)} data-bs-toggle="modal" data-bs-target="#newTurnAroundTimeModal">+ New Turn Around Time</button>
                                )
                            }
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-3">
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1" style={{ height: "34px" }}><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                                <input type="text" className="form-control" onChange={handleChangeSearch} placeholder="Search" aria-label="search" style={{ height: "34px" }} value={searchTerm} aria-describedby="basic-addon1" />
                            </div>
                        </div>
                        <div className="col-auto">
                            <CSVLink data={exportData} filename='TurnAroundTimes.csv' className='btn btn-light btn-sm'>Export</CSVLink>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col text-center">
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='left'><strong>#</strong></TableCell>
                                            <TableCell align='left'><strong>Customer Name</strong></TableCell>
                                            <TableCell align='left'><strong>Service Name</strong></TableCell>
                                            <TableCell align='left'><strong>Turnaround time if not replied </strong>&#40;min&#41;</TableCell>
                                            <TableCell align='left'><strong>Turnaround time if replied </strong>&#40;min&#41;</TableCell>
                                            <TableCell align='left'><strong>Turnaround time if processing </strong>&#40;min&#41;</TableCell>
                                            <TableCell align='left'><strong>Auto close</strong></TableCell>
                                            <TableCell align='left'><strong>Auto close when replied</strong></TableCell>
                                            {
                                                (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                                                    <TableCell align='left'><strong>Action</strong></TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {loading ?
                                        <TableBody>
                                            {[...Array(2)].map((_, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    {
                                                        (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                                        )
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        : (
                                            turnAroundTime.length === 0 ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={8} className='text-center'>
                                                            No data available.
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {(rowsPerPage > 0 && turnAroundTime || []
                                                    ).map((turnAroundTime, index) => (
                                                        <TableRow key={turnAroundTime.id}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{turnAroundTime?.customer?.name}</TableCell>
                                                            <TableCell>{turnAroundTime?.service?.name}</TableCell>
                                                            <TableCell>{turnAroundTime?.turn_around_time_if_not_replied ? turnAroundTime?.turn_around_time_if_not_replied : "-"}</TableCell>
                                                            <TableCell>{turnAroundTime?.turn_around_time_if_replied ? turnAroundTime?.turn_around_time_if_replied : "-"}</TableCell>
                                                            <TableCell>{turnAroundTime?.turn_around_time_for_processing ? turnAroundTime?.turn_around_time_for_processing : "-"}</TableCell>
                                                            <TableCell>
                                                                <span
                                                                    className={`border rounded p-1 ${turnAroundTime?.auto_close === 1 ? 'border-success text-success ' : 'border-danger text-danger'}`}
                                                                >
                                                                    {turnAroundTime?.auto_close === 1 ? "Yes" : "No"}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span
                                                                    className={`border rounded p-1 ${turnAroundTime?.auto_close_when_replied === 1 ? 'border-success text-success' : 'border-danger text-danger'}`}
                                                                >
                                                                    {turnAroundTime?.auto_close_when_replied === 1 ? "Yes" : "No"}
                                                                </span>
                                                            </TableCell>
                                                            {
                                                                (permissions.includes('Edit Services') || permissions.includes('Delete Services')) && roles.includes(role) && (
                                                                    <TableCell>
                                                                        {permissions.includes('Edit Services') && (
                                                                            <FontAwesomeIcon
                                                                                icon={faPenToSquare}
                                                                                className='me-2 edit-icon'
                                                                                onClick={() => {
                                                                                    setInitialTime(turnAroundTime);
                                                                                }}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#newTurnAroundTimeModal"
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Edit"
                                                                            />
                                                                        )}
                                                                        {permissions.includes('Delete Services') && (
                                                                            <FontAwesomeIcon
                                                                                onClick={() => handleTimeDelete(turnAroundTime)}
                                                                                icon={faTrashCan}
                                                                                className='delete-icon'
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Delete" />
                                                                        )}
                                                                    </TableCell>
                                                                )
                                                            }

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )
                                        )}
                                </Table>
                            </TableContainer>
                            {turnAroundTime.length !== 0 && (
                                <CustomTablePagination
                                    page={page}
                                    setPage={setPage}
                                    count={totalItem}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    lastPage={lastPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <NewTurnAroundTimeModal services={services} customers={customers} initialTime={initialTime} addedNewCTime={addedNewCTime} />
        </div>
    )
}

export default TurnAroundTime