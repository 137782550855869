import React, { createContext, useState } from 'react';


export const AdminHeaderContext = createContext();


const AdminHeaderProvider = ({ children }) => {
    const [details, setDetails] = useState(false);
    
    return (
        <AdminHeaderContext.Provider value={{ details, setDetails }}>
            {children}
        </AdminHeaderContext.Provider>
    );
};

export default AdminHeaderProvider;
