import React, { useRef, useState, useEffect } from 'react';
import axiosInstance from '../../../../../Axios/Axios';
import { useSelector } from 'react-redux';
import { useNotification } from '../../../../Notification/NotificationProvider';


function NewTicketPriorityModal({ addedNewticketPriority, editingTicketId, initialTicketName }) {
    const [ticketname, setTicketname] = useState(initialTicketName || '');
    const token = useSelector(state => state.login.token);
    const [error, seterror] = useState(false)
    const closeRef = useRef();
    const { showNotification } = useNotification();


    useEffect(() => {
        if (editingTicketId && initialTicketName) {
            setTicketname(initialTicketName);
        }
    }, [editingTicketId, initialTicketName]);

    const handleNewGroupForm = (e) => {
        e.preventDefault();

        if (ticketname === '') {
            seterror(true)
            return
        }
        const newGroup = {
            name: ticketname,
        };

        const request = editingTicketId
            ? axiosInstance.put(`/ticket-priority/${editingTicketId}`, newGroup, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            : axiosInstance.post('/ticket-priority', newGroup, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

        request
            .then((response) => {
                if (response.data.success) {
                    const message = response.data.message;
                    showNotification(message, 'success');
                    addedNewticketPriority();
                    closeRef.current.click();
                    setTicketname('');
                }
            })
            .catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
    };
    const handleTicketnameChange = (e) => {
        const inputValue = e.target.value;
        setTicketname(inputValue);

        if (inputValue.trim() === '') {
            seterror(true);
        } else {
            seterror(false);
        }
    }
    return (
        <div className="modal fade" tabIndex="-1" id='newTicketPriorityModal'>
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleNewGroupForm}>
                        <div className="modal-header">
                            <h5 className="modal-title">{editingTicketId ? 'Edit Priority' : 'Add Prority'}</h5>
                            <button type="button" className="btn-close" ref={closeRef} onClick={() => { addedNewticketPriority(); setTicketname(''); }} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="ticketname" className="form-label"><span className='required-star'>*</span>Priority name</label>
                            <input type="text" value={ticketname} onChange={handleTicketnameChange} className="form-control form-control-sm" id="ticketname" name="ticketname" />
                            {error && <p className='m-0 text-danger'>Priority Ticket name is required</p>}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary btn-sm text-white">{editingTicketId ? 'Save Changes' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default NewTicketPriorityModal;
