import React, { useRef, useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../../Axios/Axios';
import CustomSelect from "../../../Parts/CustomSelect";
import { NewTurnAroundTimeValidation } from '../../../../../Yup/Yup';
import { useNotification } from '../../../../Notification/NotificationProvider';

const NewTurnAroundTimeModal = ({ customers, services, initialTime, addedNewCTime }) => {
    const token = useSelector((state) => state.login.token);
    const { showNotification } = useNotification();
    const closeRef = useRef();

    const [loading, setLoading] = useState(false);

    const defaultValues = {
        service: "",
        customer: "",
        turnAroundTimeIfReplied: "",
        turnAroundTimeIfNotReplied: "",
        turnAroundTimeForProcessing: "",
        autoClose: false,
        autoCloseReplied: false
    };

    const [initialValues, setInitialValues] = useState(defaultValues);

    useEffect(() => {
        if (initialTime) {
            setInitialValues({
                service: { label: initialTime?.service?.name, value: initialTime?.service?.id },
                customer: { label: initialTime?.customer?.name, value: initialTime?.customer?.id },
                turnAroundTimeIfReplied: initialTime?.turn_around_time_if_replied || "",
                turnAroundTimeIfNotReplied: initialTime?.turn_around_time_if_not_replied || "",
                turnAroundTimeForProcessing: initialTime?.turn_around_time_for_processing || "",
                autoClose: initialTime?.auto_close === 1,
                autoCloseReplied: initialTime?.auto_close_when_replied === 1
            });
        } else {
            setInitialValues(defaultValues);
        }
    }, [initialTime]);

    const onSubmit = async (formData, { resetForm }) => {
        setLoading(true);

        const newTime = {
            customer_id: formData?.customer?.value,
            service_id: formData?.service?.value,
            turn_around_time_if_not_replied: formData?.turnAroundTimeIfNotReplied,
            turn_around_time_if_replied: formData?.turnAroundTimeIfReplied,
            turn_around_time_for_processing: formData?.turnAroundTimeForProcessing,
            auto_close: formData?.autoClose ? 1 : 0,
            auto_close_when_replied: formData?.autoCloseReplied ? 1 : 0
        };

        try {
            const response = initialTime
                ? await axiosInstance.put(`/support-ticket-turn-around-time/${initialTime?.id}`, newTime, { headers: { 'Authorization': `Bearer ${token}` } })
                : await axiosInstance.post('/support-ticket-turn-around-time', newTime, { headers: { 'Authorization': `Bearer ${token}` } });

            if (response.data.success) {
                showNotification(response.data.message, 'success');
                resetForm();
                addedNewCTime();
                closeRef.current.click();
            }
        } catch (error) {
            showNotification(error.message, 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal fade" tabIndex="-1" id="newTurnAroundTimeModal">
            <div className="modal-dialog" style={{ maxWidth: 650 }}>
                <div className="modal-content">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={NewTurnAroundTimeValidation}
                        onSubmit={onSubmit}
                    >
                        {({ resetForm }) => (
                            <Form>
                                <div className="modal-header">
                                    <h5 className="modal-title">{initialTime ? 'Edit Turn Around Time' : 'Add Turn Around Time'}</h5>
                                    <button type="button" ref={closeRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="customer" className="form-label">
                                            <span className="required-star">*</span>Customer
                                        </label>
                                        <Field
                                            component={CustomSelect}
                                            id="customer"
                                            name="customer"
                                            options={customers?.map(customer => ({ label: customer.name, value: customer.id }))}
                                        />
                                        <ErrorMessage name="customer" component="div" className="text-danger" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="service" className="form-label">
                                            <span className="required-star">*</span>Service
                                        </label>
                                        <Field
                                            component={CustomSelect}
                                            id="service"
                                            name="service"
                                            options={services?.map(service => ({ label: service.name, value: service.id }))}
                                        />
                                        <ErrorMessage name="service" component="div" className="text-danger" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="turnAroundTimeIfNotReplied" className="form-label">
                                            Turnaround time if not replied &#40;In Minutes&#41;
                                        </label>
                                        <Field type="text" className="form-control form-control-sm" id="turnAroundTimeIfNotReplied" name="turnAroundTimeIfNotReplied" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="turnAroundTimeIfReplied" className="form-label">
                                            Turnaround time if replied &#40;In Minutes&#41;
                                        </label>
                                        <Field type="text" className="form-control form-control-sm" id="turnAroundTimeIfReplied" name="turnAroundTimeIfReplied" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="turnAroundTimeForProcessing" className="form-label">
                                            Turnaround time if processing &#40;In Minutes&#41;
                                        </label>
                                        <Field type="text" className="form-control form-control-sm" id="turnAroundTimeForProcessing" name="turnAroundTimeForProcessing" />
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col d-flex">
                                            <div className="form-check me-2">
                                                <Field
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="autoClose"
                                                    name="autoClose"
                                                />
                                                <label className="form-check-label" htmlFor="autoClose">Auto close</label>
                                            </div>
                                            <div className="form-check">
                                                <Field
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="autoCloseReplied"
                                                    name="autoCloseReplied"
                                                />
                                                <label className="form-check-label" htmlFor="autoCloseReplied">Auto close when replied.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-light"
                                        data-bs-dismiss="modal"
                                        onClick={() => resetForm()}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-sm text-white">
                                        {loading ? "Please wait..." : initialTime ? "Update" : "Save"}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default NewTurnAroundTimeModal;
