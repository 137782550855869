import React, { useState, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SkeletonComponent from '../../../Parts/SkeletonComponent';
import axiosInstance from '../../../../../Axios/Axios';
import CustomTablePagination from '../../../Parts/CustomTablePagination';
import { useNotification } from "../../../../Notification/NotificationProvider";

function MailLog() {

    const [loading, setLoading] = useState(true);
    const [emailLogs, setEmailLogs] = useState([]);
    const navigate = useNavigate();
    const token = useSelector((state) => state.login.token);
    const [totalItem, setTotalItem] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(0);
    const [selectedLog, setSelectedLog] = useState(null);
    const { showNotification } = useNotification();

    const fetchEmailLog = useCallback(
        async (signal) => {
            axiosInstance.get(`email-log?page=${page + 1}&item=${rowsPerPage}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                signal: signal,
            }
            )
                .then((res) => {
                    setEmailLogs(res.data.logs.data);
                    setTotalItem(res.data.logs.meta.total);
                    setLastPage(res.data.logs.meta.last_page);
                    setLoading(false);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        navigate("/login");
                    } else {
                        console.log(error.message);
                    }
                });
        },
        [token, page, rowsPerPage]
    );

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchEmailLog(signal);

        return () => {
            controller.abort();
        };

    }, [fetchEmailLog])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleLogSelect = (log) => {
        setSelectedLog(log);
    }


    return (
        <div className="section container invoicePage">
            <div className="row mb-3">
                <div className="col">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="m-0">
                            <strong>Email Logs</strong>
                        </h4>
                    </div>
                </div>
            </div>
            <hr className="mt-2 mb-3" />
            <div className="row mt-2 justify-content-center">
                <div className="col text-center">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>ID</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Email</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Date</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Details</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {loading ? (
                                <TableBody>
                                    {[...Array(4)].map((_, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <TableCell>
                                                <SkeletonComponent line={1} />
                                            </TableCell>
                                            <TableCell>
                                                <SkeletonComponent line={1} />
                                            </TableCell>
                                            <TableCell>
                                                <SkeletonComponent line={1} />
                                            </TableCell>
                                            <TableCell>
                                                <SkeletonComponent line={1} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : emailLogs.length === 0 ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={4} className="text-center">
                                            No any Email logs available.
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {emailLogs &&
                                        emailLogs.length !== 0 &&
                                        emailLogs.map((emailLog, index) => (
                                            <TableRow key={emailLog.id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{emailLog?.to}</TableCell>
                                                <TableCell>
                                                    <div>
                                                        {new Date(emailLog.created_at).toLocaleDateString()}
                                                    </div>
                                                    <div>
                                                        {new Date(emailLog.created_at).toLocaleTimeString()}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{<FontAwesomeIcon icon={faCommentDots} onClick={(() => handleLogSelect(emailLog))} className='fs-4' data-bs-toggle="modal" data-bs-target="#newEmailLogModal" />}</span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </div>
                {emailLogs.length !== 0 && (
                    <CustomTablePagination
                        page={page}
                        setPage={setPage}
                        count={totalItem}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        lastPage={lastPage}
                    />
                )}
            </div>

            <div className="modal fade" tabIndex="-1" id='newEmailLogModal'>
                <div className="modal-dialog" style={{ maxWidth: 500 }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Email</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h6 className='text-dark'>Subject: {selectedLog?.subject}</h6>
                            <h6>CC: {selectedLog?.cc != "" ? selectedLog?.cc : "-"}</h6>
                            <p className=''>Body: <div className='ms-5' dangerouslySetInnerHTML={{ __html: selectedLog?.body }} /></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-sm" data-bs-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MailLog