import React from 'react';
import IconButton from '@mui/material/IconButton';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const CustomTablePagination = ({ page, setPage, count, rowsPerPage, onPageChange, handleChangeRowsPerPage, lastPage }) => {
    return (
        <TablePagination
            rowsPerPageOptions={[2,5,10,15]}
            component='div'
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={(subProps) => {
                const { page} = subProps;
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => setPage(0)} disabled={page === 0} aria-label="first page">
                            <FirstPageIcon />
                        </IconButton>
                        <IconButton onClick={() => setPage(page - 1)} disabled={page === 0} aria-label="previous page">
                            <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton onClick={() => setPage(page + 1)} disabled={page >= lastPage - 1} aria-label="next page">
                            <KeyboardArrowRight />
                        </IconButton>
                        <IconButton onClick={() => setPage(lastPage - 1)} disabled={page >= lastPage - 1} aria-label="last page">
                            <LastPageIcon />
                        </IconButton>
                    </div>
                );
            }}
        />
    );
};

export default CustomTablePagination;
