import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../../../../Axios/Axios';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNotification } from '../../../../Notification/NotificationProvider';


const NewPreDefinedReplies = ({ addedNewPreDefinedReplies, editingRepliesId, editInitialValues }) => {
    const [formValues, setFormValues] = useState({
        name: '',
        reply: '',
    });
    const [errors, setErrors] = useState({});
    const token = useSelector((state) => state.login.token);
    const closeRef = useRef();
    const { showNotification } = useNotification();


    const resetFormValues = () => {
        setFormValues({
            name: '',
            reply: '',
        });
    };
    useEffect(() => {
        if (editingRepliesId && editInitialValues) {
            setFormValues({
                name: editInitialValues.name,
                reply: editInitialValues.reply,
            });
        } else {
            resetFormValues();

        }
    }, [editingRepliesId, editInitialValues]);

    const validateForm = () => {
        const newErrors = {};

        if (!formValues.name.trim()) {
            newErrors.name = 'Predefined reply name is required';
        }

        if (!formValues.reply.trim()) {
            newErrors.reply = 'Reply message content is required';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const onSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            const formData = {
                name: formValues.name,
                reply: formValues.reply,
            };

            const request = editingRepliesId
                ? axiosInstance.put(`/pre-defined-replies/${editingRepliesId}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                : axiosInstance.post('/pre-defined-replies', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            request
                .then((response) => {
                    if (response.data.success) {
                        const message = response.data.message;
                        showNotification(message, 'success');
                        addedNewPreDefinedReplies();
                        resetFormValues();
                        closeRef.current.click();
                    }
                })
                .catch((error) => {
                    showNotification(error.message, 'error');
                    console.error('Submission error:', error);
                });
        }
    };

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['image', 'video'],
                ['clean'],
            ],
        },
    };

    return (
        <div className="modal fade" tabIndex="-1" id="newPredefinedRepliesModal">
            <div className="modal-dialog" style={{ maxWidth: 750 }}>
                <div className="modal-content">
                    <form onSubmit={onSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title">{editingRepliesId ? 'Edit Predefined Reply' : 'Add New Predefined Reply'}</h5>
                            <button type="button" className="btn-close" ref={closeRef} onClick={resetFormValues} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    <span className="required-star">*</span>Predefined reply name
                                </label>
                                <input
                                    type="text"
                                    className='form-control form-control-sm'
                                    id="name"
                                    name="name"
                                    value={formValues.name}
                                    onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                                />
                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                            </div>
                            <div className="mb-3" style={{ height: 250 }}>
                                <ReactQuill
                                    theme="snow"
                                    value={formValues.reply}
                                    onChange={(value) => setFormValues({ ...formValues, reply: value })}
                                    modules={modules}
                                    style={{ height: '100%' }}
                                />
                            </div>
                            {errors.reply && <div className="text-danger mt-4">{errors.reply}</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary btn-sm text-white">
                                {editingRepliesId ? 'Save Changes' : 'Save'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewPreDefinedReplies;
