import React, { useRef, useState, useEffect } from 'react';
import axiosInstance from '../../../../../Axios/Axios';
import { useSelector } from 'react-redux';
import { useNotification } from '../../../../Notification/NotificationProvider';

function NewServicesModal({ addedNewService, editingServiceId, initialServiceName }) {
    const [servicename, setServicename] = useState(initialServiceName || '');
    const token = useSelector(state => state.login.token);
    const [error, seterror] = useState(false)
    const closeRef = useRef();
    const { showNotification } = useNotification();

    useEffect(() => {
        if (editingServiceId && initialServiceName) {
            setServicename(initialServiceName);
        }
    }, [editingServiceId, initialServiceName]);

    const handleNewGroupForm = (e) => {
        e.preventDefault();
        if (servicename === '') {
            seterror(true)
            return
        }

        const newGroup = {
            name: servicename,
        };

        const request = editingServiceId
            ? axiosInstance.put(`/services/${editingServiceId}`, newGroup, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            : axiosInstance.post('/services', newGroup, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

        request
            .then((response) => {
                if (response.data.success) {
                   const message = response.data.message;
                    showNotification(message, 'success');
                        addedNewService();
                        closeRef.current.click();
                        setServicename('');
                }
            })
            .catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
    };
    const handleServicenameChange = (e) => {
        const inputValue = e.target.value;
        setServicename(inputValue);

        if (inputValue.trim() === '') {
            seterror(true);
        } else {
            seterror(false);
        }
    }
    return (
        <div className="modal fade" tabIndex="-1" id='newServiceModal'>
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleNewGroupForm}>
                        <div className="modal-header">
                            <h5 className="modal-title">{editingServiceId ? 'Edit Service' : 'Add New Service'}</h5>
                            <button type="button" className="btn-close" ref={closeRef} onClick={() => { addedNewService(); setServicename(''); }} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="servicename" className="form-label"><span className='required-star'>*</span>Service Name</label>
                            <input type="text" value={servicename} onChange={handleServicenameChange} className="form-control form-control-sm" id="servicename" name="servicename" />
                            {error && <p className='m-0 text-danger'>Service name is required</p>}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary btn-sm text-white">{editingServiceId ? 'Save Changes' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default NewServicesModal;
