import { thunk } from 'redux-thunk';
import { applyMiddleware, legacy_createStore as createStore, combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

// import { composeWithDevTools } from 'redux-devtools-extension';

// Reducers
import LoginReducer from './LoginReducer';


const encryptor = encryptTransform({
  secretKey: 'werwerrwerweriohweruwehrweihr',
  onError: function (error) {
    // Handle the error
   // console.log(error);

  },

  inbound: (state, key) => {
    //console.log('Inbound state:', state);
    return state;
  },
  outbound: (state, key) => {
    //console.log('Outbound state:', state);
    return state;
  },
});
// Combine Reducers
const rootReducer = combineReducers({
  login: LoginReducer,
});

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor],

};
const middleWare = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
export const store = createStore(persistedReducer, (applyMiddleware(...middleWare)));
export const persistor = persistStore(store);

store.subscribe(() => {
 // console.log('State after rehydration:', store.getState());
});