import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import axiosInstance from '../../../../../Axios/Axios';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CustomSelect from '../../../Parts/CustomSelect';
import { NewReportSchema } from '../../../../../Yup/Yup';
import PieChart from '../../../Charts/PieChart'
import BarChart from '../../../Charts/BarChart';
import DonutChart from '../../../Charts/DonutChart';


function AgingReport() {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const token = useSelector((state) => state.login.token);
    const [customers, setCustomers] = useState([]);
    const [status, setStatus] = useState([]);
    const [customersReport, setCustomersReport] = useState([]);
    const [services, setServices] = useState([]);
    const [aging, setAging] = useState([]);
    const closeRef = useRef();


    const [initialValues, setInitialValues] = useState({
        customer: "",
        start_date: new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0]
    });


    const fetchCustomers = (signal) => {
        axiosInstance.get('support-ticket-customers', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            signal: signal,
        }
        )
            .then((res) => {
                const { customers } = res.data;
                setCustomers(customers.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate("/login");
                } else {
                    console.error(error.message);
                }
            });
    };


    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchCustomers(signal);

        return () => {
            controller.abort();
        };

    }, []);

    const fetchInitialValues = useCallback(async (signal) => {
        try {
            const [ticket_statusResponse, ticket_customerResponse, ticket_serviceResponse, ticket_agingResponse] = await Promise.all([
                axiosInstance.get('/support-ticket-counts', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/support-ticket-customer-report', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/support-ticket-service-report', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
                axiosInstance.get('/support-ticket-aging-count', { headers: { 'Authorization': `Bearer ${token}` }, signal: signal, }),
            ]);

            setStatus(ticket_statusResponse.data.data);
            setCustomersReport(ticket_customerResponse.data.data);
            setServices(ticket_serviceResponse.data.data);
            setAging(ticket_agingResponse.data.aging);
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchInitialValues(signal);

        return () => {
            controller.abort();
        };

    }, [])


    const handleSubmit = (formData, { resetForm }) => {
        const customer_id = formData?.customer?.value;
        setLoading(true);
        const data = {
            start_date: formData?.start_date,
            end_date: formData?.end_date,
            customer_id: customer_id
        }

        axiosInstance.post('aging-report', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 200 && res.data.download_url) {
                    closeRef.current.click();
                    const link = document.createElement('a');
                    link.href = res.data.download_url;
                    link.setAttribute('download', 'support_ticket_report.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setLoading(false);
                    resetForm();
                } else {
                    console.error('Failed to generate report:', res.data.message);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate("/login");
                } else {
                    console.error(error.message);
                }
            });
    }

    const statusReport = {
        data: status,
        title_status: "Total Customer Complaints Received"
    };

    const customerReport = {
        data: customersReport,
        title_status: "Customer wise Complaints Received"
    };

    const agingReport = {
        data: aging,
        title_status: "Aging of Open & Pending Complaints"
    };



    return (
        <div className="section container invoicePage">
            <div className="row mb-3">
                <div className="col">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="m-0 fw-semibold">
                            Report Summary
                        </h4>
                    </div>
                </div>
                <div className="col text-end">
                    <button className='btn btn-primary btn-sm' data-bs-toggle="modal" data-bs-target="#newReportModal">Download Report</button>
                </div>
            </div>
            <hr className="mt-2 mb-3" />
            <div className="row d-flex g-2 gap-4 mt-2">
                <div className="col">
                    <PieChart
                        status_report={statusReport}
                    />
                </div>
                <div className="col">
                    <PieChart
                        status_report={customerReport}
                    />
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-3"></div>
                <div className="col-6">
                    <DonutChart
                        status_report={agingReport}
                    />
                </div>
                <div className="col-3"></div>
            </div>
            <div className="row mt-5">
                <div className="col">
                    <BarChart serviceReport={services} />
                </div>
            </div>


            <div className="modal fade" tabIndex="-1" id="newReportModal">
                <div className="modal-dialog" style={{ maxWidth: 700 }}>
                    <div className="modal-content">
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            onSubmit={handleSubmit}
                            validationSchema={NewReportSchema}
                        >
                            <Form>
                                <div className="modal-header">
                                    <h5 className="modal-title">Download Report</h5>
                                    <button type="button" className="btn-close" ref={closeRef} data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row mb-3">
                                        <div className="col">
                                            <label htmlFor="customer" className="form-label">
                                                Customer
                                            </label>
                                            <Field
                                                component={CustomSelect}
                                                id="customer"
                                                name="customer"
                                                options={customers?.map((customer) => ({ label: customer.name, value: customer.id }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <label htmlFor="start_date" className="form-label">
                                                <span className='text-danger'>*</span>Start Date
                                            </label>
                                            <Field
                                                id="start_date"
                                                name="start_date"
                                                type="date"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                            />
                                            <ErrorMessage name='start_date' component='div' className='text-danger' />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <label htmlFor="end_date" className="form-label">
                                                <span className='text-danger'>*</span>End Date
                                            </label>
                                            <Field
                                                id="end_date"
                                                name="end_date"
                                                type="date"
                                                className="form-control"
                                                onFocus={(e) => e.currentTarget.showPicker()}
                                            />
                                            <ErrorMessage name='end_date' component='div' className='text-danger' />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary btn-sm text-white">
                                        {loading ? "Please wait.." : "Download"}
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgingReport