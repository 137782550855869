import React, { createContext, useContext, useRef } from 'react';
import NotificationContainer from './NotificationContainer';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const notificationRef = useRef();

  const showNotification = (message, type = 'default') => {
    if (notificationRef.current) {
      notificationRef.current.addNotification(message, type);
    }
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      <NotificationContainer ref={notificationRef} />
      {children}
    </NotificationContext.Provider>
  );
};
