import React, { useEffect, useState, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomSelect from "../../Parts/CustomSelect";
import NewServicesModal from "../SetUp/Modals/NewServicesModal";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../Axios/Axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NewTicketSchema } from "../../../../Yup/Yup";
import { useNotification } from "../../../Notification/NotificationProvider";
import Loading from "../../Parts/Loading";
import NewCustomerModal from "../SetUp/Modals/NewCustomerModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faUpload } from '@fortawesome/free-solid-svg-icons';


const TicketSupportDetails = () => {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const token = useSelector((state) => state.login.token);
  // const initial_services = useSelector((state) => state.login.services);
  // const initial_departments = useSelector((state) => state.login.departments);
  // const initial_staffs = useSelector((state) => state.login.staffs);
  // const initial_priorities = useSelector((state) => state.login.priorities);
  // const initial_preDefinedReplies = useSelector((state) => state.login.preDefinedReplies);
  // const initial_statuses = useSelector((state) => state.login.statuses);
  // const initial_customers = useSelector((state) => state.login.customers);
  // const initial_states = useSelector((state) => state.login.states);
  // const initial_complaints = useSelector((state) => state.login.complaints);
  // const initial_chargers = useSelector((state) => state.login.chargers);
  const [services, setServices] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [prioritys, setPrioritys] = useState([]);
  const [preDefinedReplies, setPreDefinedReplies] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [complaints, setComplaints] = useState([]);
  const [chargers, setChargers] = useState([]);
  const quillRef = useRef(null);
  const [value, setValue] = useState({ quill: "" });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);

  const dropzoneRef = useRef(null);
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    email_cc: "",
    assignTicket: "",
    ticketStatus: "",
    priority: "",
    service: "",
    customer: "",
    ticket_body: "",
    //file: [],
    quill: "",
    state: "",
    city: "",
    mode_of_complaint: "",
    make_of_charger: ""
  });


  const fetchInitialValues = useCallback(async (signal) => {
    try {
      const [
        servicesResponse,
        departmentsResponse,
        staffResponse,
        ticket_priorityResponse,
        pre_defined_repliesResponse,
        statusesResponse,
        customerResponse,
        stateResponse,
        complaintsResponse,
        chargersResponse
      ] = await Promise.all([
        axiosInstance.get("/services", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/departments", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/staff", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/ticket-priority", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/pre-defined-replies", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/ticket-statuses", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/support-ticket-customers", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/states", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/support-ticket-mode-of-complaints", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/support-ticket-make-of-chargers", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
      ]);

      setServices(servicesResponse.data.services.data);
      setDepartments(departmentsResponse.data.departments.data);
      setStaffs(staffResponse.data.staff.data);
      setPrioritys(ticket_priorityResponse.data.ticketPriorities.data);
      setPreDefinedReplies(
        pre_defined_repliesResponse.data.preDefinedReplies.data
      );
      setStatuses(statusesResponse.data.ticketStatuses.data);
      const status = statusesResponse.data.ticketStatuses?.data?.find((item) => item.name.toLowerCase() === 'open');
      setInitialValues((prevValues) => ({
        ...prevValues,
        ticketStatus: {
          label: status?.name,
          value: status?.id,
        },
      }));
      setCustomers(customerResponse.data?.customers?.data);
      setStates(stateResponse.data?.states);
      setComplaints(complaintsResponse.data?.mode_of_complaints?.data)
      setChargers(chargersResponse.data?.make_of_chargers?.data)
      setLoading(false)

      // dispatch({
      //   type: 'SET_INITIAL_DATA',
      //   payload: {
      //     services: servicesResponse.data.services.data,
      //     departments: departmentsResponse.data.departments.data,
      //     staffs: staffResponse.data.staff.data,
      //     priorities: ticket_priorityResponse.data.ticketPriorities.data,
      //     preDefinedReplies: pre_defined_repliesResponse.data.preDefinedReplies.data,
      //     statuses: statusesResponse.data.ticketStatuses.data,
      //     customers: customerResponse.data.customers.data,
      //     states: stateResponse.data.states,
      //     complaints: complaintsResponse.data.mode_of_complaints.data,
      //     chargers: chargersResponse.data.make_of_chargers.data,
      //   }
      // });

    } catch (error) {
      console.error(error);
    }
  }, [token]);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["image", "video"],
        ["clean"],
      ],
    },
  };


  const addedNewCustomer = () => {
    fetchInitialValues();
  };

  const addedNewService = () => {
    fetchInitialValues();
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    // if (!initial_services || initial_services.length === 0) {
    //   fetchInitialValues(signal);
    // } else {
    //   setServices(initial_services);
    //   setDepartments(initial_departments);
    //   setStaffs(initial_staffs);
    //   setPrioritys(initial_priorities);
    //   setPreDefinedReplies(initial_preDefinedReplies);
    //   setStatuses(initial_statuses);
    //   const status = initial_statuses?.find((item) => item.name.toLowerCase() === 'open');
    //   setInitialValues((prevValues) => ({
    //     ...prevValues,
    //     ticketStatus: {
    //       label: status?.name,
    //       value: status?.id,
    //     },
    //   }));
    //   setCustomers(initial_customers);
    //   setStates(initial_states);
    //   setComplaints(initial_complaints)
    //   setChargers(initial_chargers)
    //   setLoading(false);
    // }

    fetchInitialValues(signal);

    return () => {
      controller.abort();
    };

  }, [fetchInitialValues]);


  const handleBodySelection = (selectedOption) => {
    const selectedReplyId = selectedOption.value;
    const reply = preDefinedReplies.find(
      (reply) => reply.id === selectedReplyId
    );
    setValue({ quill: reply.reply });
  };

  const handleNewTicket = (formData, { resetForm }) => {
    const ticket_priority_id = formData.priority?.value;
    const ticket_service_id = formData.service?.value;
    const staff_id = formData.assignTicket?.value;
    const ticket_status_id = formData.ticketStatus?.value;
    const customer_id = formData?.customer?.value;
    const predefined_reply_id = formData.ticket_body
      ? formData.ticket_body.value
      : "";
    const firstDepartmentId = departments.length > 0 ? departments[0].id : null;
    const city_id = formData?.city?.value;
    const charger_id = formData?.make_of_charger?.value;
    const complaint_id = formData?.mode_of_complaint?.value;
    const attachment_file = files?.map(file => file?.file);

    const data = {
      name: formData.name,
      email: formData.email,
      department_id: firstDepartmentId,
      email_cc: formData.email_cc,
      customer_id: customer_id,
      make_of_charger_id: charger_id,
      mode_of_complaint_id: complaint_id,
      city_id: city_id,
      ticket_priority_id: ticket_priority_id ? ticket_priority_id : null,
      ticket_status_id: ticket_status_id ? ticket_status_id : null,
      ticket_service_id: ticket_service_id ? ticket_service_id : null,
      staff_id: staff_id,
      predefined_reply_id: predefined_reply_id ? predefined_reply_id : null,
      ticket_body: value.quill,
      attachment: attachment_file,
    };

    setLoading(true);

    axiosInstance
      .post("support-ticket", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        //console.log(res);
        if (res.data.success) {
          setLoading(false);
          const message = res.data.message;
          showNotification(message, "success");
          resetForm();
          navigate("/tickets");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setLoading(false)
          navigate("/");
        } else {
          showNotification(error.message, "error");
          console.error(error.message);
        }
      });
  };


  const handleStateSelection = (object) => {
    axiosInstance
      .get(`/cities/${object?.value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCities(res.data.cities)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate("/");
        } else {
          console.error(error.message);
        }
      });

  }

  const handleDragOver = (event) => {
    event.preventDefault();
    dropzoneRef.current.classList.add('drag-over');
  };


  const handleDragLeave = () => {
    dropzoneRef.current.classList.remove('drag-over');
  };


  const handleDrop = (event) => {
    event.preventDefault();
    dropzoneRef.current.classList.remove('drag-over');
    const newFiles = Array.from(event.dataTransfer.files).map(file => ({
      file,
      progress: 100
    }));
    setFiles([...files, ...newFiles]);
  };


  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map(file => ({
      file,
      progress: 100
    }));
    setFiles([...files, ...newFiles]);
  };


  const handleRemoveFile = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  return (
    <div className="div container">
      {loading && (
        <div className="loading-overlay">
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="col section">
          <div className="row">
            <div className="col">
              <h5 className="m-0">Ticket Information</h5>
            </div>
          </div>
          <hr />
          <div className="row px-2">
            <div className="col">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={handleNewTicket}
                validationSchema={NewTicketSchema}
              >
                <Form>
                  <div className="row gap-3">
                    <div className="col">
                      {/* <div className="mb-3">
                          <label htmlFor="subject" className="form-label">
                            <span className="required-star">*</span>Subject
                          </label>
                          <Field
                            id="subject"
                            name="subject"
                            type="text"
                            className="form-control"
                          />
                          <ErrorMessage name='subject' component='div' className='text-danger' />
                        </div> */}
                      <div className="item-group mb-3">
                        <label htmlFor="service" className="form-label">
                          <span className="required-star">*</span>Service
                        </label>
                        <div className="d-flex w-100">
                          <Field
                            name="service"
                            className="w-100"
                            component={CustomSelect}
                            placeholder="Add Service"
                            options={services?.map((service) => ({ value: service.id, label: service.name }))}
                          />
                          <button
                            className="addNewBtn"
                            data-bs-toggle="modal"
                            type="button"
                            data-bs-target="#newServiceModal"
                          >
                            +
                          </button>
                        </div>
                        <ErrorMessage name='service' component='div' className='text-danger' />
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              <span className="required-star">*</span>Ticket Author Name
                            </label>
                            <Field
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage name='name' component='div' className='text-danger' />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              <span className="required-star">*</span>Email
                              address
                            </label>
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              className="form-control"
                            />
                            <ErrorMessage name='email' component='div' className='text-danger' />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-3">
                            <label htmlFor="email_CC" className="form-label">
                              CC
                            </label>
                            <Field
                              id="emailCC"
                              name="email_cc"
                              type="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label
                              htmlFor="ticketStatus"
                              className="form-label"
                            >
                              Ticket Status
                            </label>
                            <Field
                              component={CustomSelect}
                              id="ticketStatus"
                              name="ticketStatus"
                              options={statuses?.map((status) => ({
                                label: status.name,
                                value: status.id,
                              })) || []}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              htmlFor="mode_of_complaint"
                              className="form-label"
                            >
                              Mode of Complaint
                            </label>
                            <Field
                              component={CustomSelect}
                              id="mode_of_complaint"
                              name="mode_of_complaint"
                              options={complaints?.map((complaint) => ({
                                label: complaint?.name,
                                value: complaint?.id,
                              })) || []}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label htmlFor="assignTicket" className="form-label">
                          Assign Ticket &#40;default is current user&#41;
                        </label>
                        <Field
                          component={CustomSelect}
                          id="assignTicket"
                          name="assignTicket"
                          options={staffs?.map((staff) => ({ label: staff.name, value: staff.id }))}
                        />
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="priority" className="form-label">
                              Priority
                            </label>
                            <Field
                              component={CustomSelect}
                              id="priority"
                              name="priority"
                              options={prioritys?.map((priority) => ({ label: priority.name, value: priority.id }))}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="item-group mb-3">
                            <label htmlFor="customer" className="form-label">
                              <span className="required-star">*</span>Customer
                            </label>
                            <div className="d-flex w-100">
                              <Field
                                name="customer"
                                className="w-100"
                                component={CustomSelect}
                                placeholder="Add Customer.."
                                options={customers?.map((customer) => ({ value: customer.id, label: customer.name }))}
                              />
                              <button
                                className="addNewBtn"
                                data-bs-toggle="modal"
                                type="button"
                                data-bs-target="#newCustomerModal"
                              >
                                +
                              </button>
                            </div>
                            <ErrorMessage name='customer' component='div' className='text-danger' />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="state" className="form-label">
                              <span className="required-star">*</span>Site Location State
                            </label>
                            <Field
                              component={CustomSelect}
                              name="state"
                              id="state"
                              options={states?.map((state) => ({ label: state.name, value: state.id }))}
                              onChange={handleStateSelection}
                            />
                            <ErrorMessage name='state' component='div' className='text-danger' />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="city" className="form-label">
                              <span className="required-star">*</span> Site Location City
                            </label>
                            <Field
                              component={CustomSelect}
                              id="city"
                              name="city"
                              options={cities?.map((city) => ({ label: city.city, value: city.id }))}
                            />
                            <ErrorMessage name='city' component='div' className='text-danger' />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              htmlFor="make_of_charger"
                              className="form-label"
                            >
                              Make of Charger
                            </label>
                            <Field
                              component={CustomSelect}
                              id="make_of_charger"
                              name="make_of_charger"
                              options={chargers?.map((charger) => ({
                                label: charger.name,
                                value: charger.id,
                              })) || []}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <div className="row mb-3">
                    <div className="col-6">
                      <div
                        id="dropzoneTaskComment"
                        className="dropzoneDragArea dz-default dz-message task-comment-dropzone dz-clickable"
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={handleClick}
                        ref={dropzoneRef}
                      >
                        <div className="dropzone-content">
                          <FontAwesomeIcon icon={faUpload} className="upload-icon" />
                          <span className="upload-text">Drop or Click here to upload files</span>
                          <span className="accepted-file-types">Accepted file types: JPEG, Doc, PDF, PNG</span>
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          multiple
                        />
                      </div>

                    </div>
                    <div className="col-6">
                      <div className="files-list">
                        {files.map((fileObj, index) => (
                          <div key={index} className="file-item">
                            <span>{fileObj.file.name}</span>
                            <div className="progress-bar">
                              <div
                                className="progress"
                                style={{ width: `${fileObj.progress}%` }}
                              />
                            </div>
                            <FontAwesomeIcon
                              className="text-danger"
                              icon={faXmark}
                              onClick={() => handleRemoveFile(index)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <label htmlFor="ticketBody" className="form-label">
                        Ticket Body
                      </label>
                      <Field
                        component={CustomSelect}
                        id="ticketBody"
                        name="ticket_body"
                        placeholder="Insert predefined reply"
                        options={preDefinedReplies?.map((preDefinedReplie) => ({ label: preDefinedReplie.name, value: preDefinedReplie.id }))}
                        onChange={handleBodySelection}
                      />
                    </div>
                    {/* <div className="col">
                      <label htmlFor="file" className="form-label">
                        Attachment
                      </label>
                      <Field
                        type="file"
                        id="fileInput"
                        name="file"
                        className="form-control"
                        onChange={(event) => {
                          setFile(event.currentTarget.files[0]);
                        }}
                      />
                    </div> */}

                  </div>
                  <div className="row mb-5">
                    <div className="mb-5" style={{ height: 350 }}>
                      <ReactQuill
                        theme="snow"
                        name="quill"
                        ref={quillRef}
                        value={value.quill}
                        onChange={(value) => {
                          setValue({ ...value, quill: value });
                        }}
                        modules={modules}
                        style={{ height: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="row invoice-footer">
                    <div className="col text-end">
                      <Link
                        to={"/tickets"}
                        type="button"
                        className="btn btn-light btn-sm fw-medium mx-2"
                      >
                        Close
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm text-white fw-medium"
                      >
                        Open Ticket
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <NewCustomerModal addedNewCustomer={addedNewCustomer} />
      <NewServicesModal addedNewService={addedNewService} />
    </div>
  );
};

export default TicketSupportDetails;
