import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { newTicketStatusValidation } from '../../../../../Yup/Yup';
import axiosInstance from '../../../../../Axios/Axios';
import { useSelector } from 'react-redux';
import { useNotification } from '../../../../Notification/NotificationProvider';


const NewTicketStatusModal = ({ addedNewTicketStatus, editingTicketStatusId, initialTicketStatusName }) => {
    const [initialValues, setInitialValues] = useState({
        name: initialTicketStatusName?.name || '',
        color: initialTicketStatusName?.color || '#000000',
        order: initialTicketStatusName?.order || '',
    });

    const [selectedColor, setSelectedColor] = useState(initialTicketStatusName?.color || '#000000');

    const token = useSelector((state) => state.login.token);
    const closeRef = useRef();

    const { showNotification } = useNotification();

    const resetValue = () => {
        setInitialValues({
            name: '',
            color: '#000000',
            order: ''
        });
        setSelectedColor('#000000');
    };

    useEffect(() => {
        if (editingTicketStatusId && initialTicketStatusName) {
            setInitialValues({
                name: initialTicketStatusName.name,
                color: initialTicketStatusName.color,
                order: initialTicketStatusName.order,
            });
            setSelectedColor(initialTicketStatusName.color);
        } else {
            resetValue();
        }
    }, [editingTicketStatusId, initialTicketStatusName]);

    const onSubmit = (formData, { resetForm }) => {
        formData.color = selectedColor; // Assign the selected color to the formData
        const request = editingTicketStatusId
            ? axiosInstance.put(`/ticket-statuses/${editingTicketStatusId}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            : axiosInstance.post('/ticket-statuses', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

        request
            .then((response) => {
                if (response.data.success) {
                    const message = response.data.message;
                    showNotification(message, 'success');
                    resetForm();
                    addedNewTicketStatus();
                    resetForm();
                    closeRef.current.click();
                }
            })
            .catch((error) => {
                showNotification(error.message, 'error');
                console.error('Submission error:', error);
            });
    };

    return (
        <div className="modal fade" tabIndex="-1" id="newTicketStatusModal">
            <div className="modal-dialog" style={{ maxWidth: 650 }}>
                <div className="modal-content">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={newTicketStatusValidation}
                        onSubmit={onSubmit}>
                        <Form>
                            <div className="modal-header">
                                <h5 className="modal-title">{editingTicketStatusId ? 'Edit Ticket Status' : 'Add New Ticket Status'}</h5>
                                <button type="button" className="btn-close" ref={closeRef} onClick={() => { resetValue(); addedNewTicketStatus(); }} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        <span className="required-star">*</span>Ticket Status Name
                                    </label>
                                    <Field type="text" className="form-control form-control-sm" id="name" name="name" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </div>

                                <label htmlFor="color" className="form-label"><span className="required-star">*</span>Color</label>
                                <div className="input-group">
                                    <Field
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="color"
                                        name="color"
                                        value={selectedColor}
                                        onChange={(e) => setSelectedColor(e.target.value)}
                                    />
                                    <span className="input-group-text">
                                        <input
                                            type="color"
                                            id="colorPicker"
                                            value={selectedColor}
                                            onChange={(e) => setSelectedColor(e.target.value)}
                                        />
                                    </span>
                                </div>
                                <ErrorMessage name="color" component="div" className="text-danger" />

                                <div className="my-3">
                                    <label htmlFor="order" className="form-label"><span className="required-star">*</span>Order</label>
                                    <Field type="number" className="form-control form-control-sm" id="order" name="order" />
                                    <ErrorMessage name="order" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary btn-sm text-white">
                                    {editingTicketStatusId ? 'Save Changes' : 'Save'}
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default NewTicketStatusModal;
