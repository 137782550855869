import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../../Axios/Axios';
import SkeletonComponent from '../../../Parts/SkeletonComponent';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { useCallback } from 'react';
import NewTicketPriorityModal from '../Modals/NewTicketPriorityModal';
import CustomTablePagination from '../../../Parts/CustomTablePagination';
import { useNotification } from '../../../../Notification/NotificationProvider';


function TicketPriority() {

    const token = useSelector(state => state.login.token);
    const user = useSelector((state) => state.login.user);
    const navigate = useNavigate()
    const [ticketNames, setTicketNames] = useState([]);
    const [page, setPage] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(true)
    const [initialTicketName, setInitialTicketName] = useState('')
    const [editingTicketId, setEditingTicketId] = useState(null)
    const { showNotification } = useNotification();

    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];



    const fetchTicketPriorities = useCallback((signal) => {
        axiosInstance.get(`/ticket-priority?page=${page + 1}&item=${rowsPerPage}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            signal: signal,
        }).then((res) => {
            setLoading(false)
            setTicketNames(res.data.ticketPriorities.data);
            setTotalItem(res.data.ticketPriorities.meta.total);
            setLastPage(res.data.ticketPriorities.meta.last_page);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                console.error(error.message);
            }
        })
    }, [token, page, rowsPerPage]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchTicketPriorities(signal);

        return () => {
            controller.abort();
        };

    }, [fetchTicketPriorities]);



    const addedNewticketPriority = () => {
        fetchTicketPriorities()
        setEditingTicketId(null)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleGroupDelete = (id, groupname) => {
        if (window.confirm(`Are you sure to delete ${groupname} group?`)) {
            axiosInstance.delete(`/ticket-priority/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    fetchTicketPriorities();
                    setPage(0);
                }
            }).catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
        }
    };
    const exportData = ticketNames ? ticketNames.map((ticket, index) => ({
        '#': index + 1,
        'Ticket name': ticket.name,
    })) : [];


    return (
        <div className='container customer-Groups-list'>
            <div className="row">
                <div className="col section">
                    <div className="row">
                        <div className="col">
                            {
                                (permissions.includes('Delete Priority') || permissions.includes('Edit Priority')) && roles.includes(role) && (
                                    <button className='btn btn-primary btn-sm text-white' data-bs-toggle="modal" data-bs-target="#newTicketPriorityModal">+ New Priority</button>
                                )
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">
                            <CSVLink data={exportData} filename='Ticket-Priorities.csv' className='btn btn-light btn-sm'>Export</CSVLink>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col text-center">
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>#</strong></TableCell>
                                            <TableCell><strong>Ticket Priority name</strong></TableCell>
                                            {
                                                (permissions.includes('Delete Priority') || permissions.includes('Edit Priority')) && roles.includes(role) && (
                                                    <TableCell><strong>Action</strong></TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {loading ?
                                        <TableBody>
                                            {[...Array(2)].map((_, rowIndex) => (
                                                <TableRow key={rowIndex}>

                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    {
                                                        (permissions.includes('Delete Priority') || permissions.includes('Edit Priority')) && roles.includes(role) && (
                                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                                        )
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        : (

                                            ticketNames.length === 0 ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={3} className='text-center'>
                                                            No data available. Add Ticket Priority first.
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (

                                                <TableBody>
                                                    {(rowsPerPage > 0 && ticketNames || []
                                                    ).map((ticket, index) => (
                                                        <TableRow key={ticket.id}>

                                                            <TableCell>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    permissions.includes('Edit Priority') && roles.includes(role) ? (
                                                                        <Link
                                                                            onClick={() => {
                                                                                setEditingTicketId(ticket.id);
                                                                                setInitialTicketName(ticket.name);
                                                                            }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#newTicketPriorityModal"
                                                                        >
                                                                            {ticket.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <Link > {ticket.name}</Link>
                                                                    )
                                                                }
                                                            </TableCell>
                                                            {
                                                                (permissions.includes('Edit Priority') || permissions.includes('Delete Priority')) && roles.includes(role) && (
                                                                    <TableCell>
                                                                        {permissions.includes('Edit Priority') && (
                                                                            <FontAwesomeIcon
                                                                                icon={faPenToSquare}
                                                                                className='me-2 edit-icon'
                                                                                onClick={() => {
                                                                                    setEditingTicketId(ticket.id);
                                                                                    setInitialTicketName(ticket.name);
                                                                                }}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#newTicketPriorityModal"
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Edit Group"
                                                                            />
                                                                        )}
                                                                        {permissions.includes('Delete Priority') && (
                                                                            <FontAwesomeIcon
                                                                                onClick={() => handleGroupDelete(ticket.id, ticket.name)}
                                                                                icon={faTrashCan}
                                                                                className='delete-icon'
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Delete Group" />
                                                                        )}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )
                                        )}
                                </Table>
                            </TableContainer>
                            {ticketNames.length !== 0 && (
                                <CustomTablePagination
                                    page={page}
                                    setPage={setPage}
                                    count={totalItem}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    lastPage={lastPage}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <Tooltip id="my-tooltip" />
            <NewTicketPriorityModal addedNewticketPriority={addedNewticketPriority} editingTicketId={editingTicketId} initialTicketName={initialTicketName} />
        </div>
    )
}

export default TicketPriority