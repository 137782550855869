// import React from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// const ProtectedRoute = ({ requiredRoles, requiredPermissions }) => {
//     const user = useSelector((state) => state.login.user);
//     const permissions = useSelector((state) => state.login.permissions);
//     const role = useSelector((state) => state.login.role);

//    // console.log("permissions", permissions);


//     function updatePermissions(newPermissions) {
//         // If newPermissions is already an object, return it
//         if (typeof newPermissions === 'object' && !Array.isArray(newPermissions)) {
//             return newPermissions;
//         }

//         // Convert the array to an object with indices as keys
//         const updatedPermissions = {};
//         newPermissions.forEach((permission, index) => {
//             updatedPermissions[index] = permission;
//         });

//         return updatedPermissions;
//     }

//    // console.log("permissions", permissions);

//     const updatedDataObject = updatePermissions(permissions);
//     const updatedDataArray = Object.values(updatedDataObject);
//     //console.log("updatedDataArray", updatedDataArray);

//     const hasRole = (requiredRoles) => {
//         return requiredRoles.includes(role);
//     };

//     const hasPermission = (requiredPermissions) => {
//         return requiredPermissions.some(permission => permissions.includes(permission));
//     };

//     return hasRole(requiredRoles) && hasPermission(requiredPermissions) ? <Outlet /> : <Navigate to="/" />;
// };

// export default ProtectedRoute;




import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ requiredRoles, requiredPermissions }) => {
    const user = useSelector((state) => state.login.user);
    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);

    const hasRole = useMemo(() => requiredRoles.includes(role), [requiredRoles, role]);

    const hasPermission = useMemo(() => {
        if (Array.isArray(permissions)) {
            return requiredPermissions.some(permission => permissions.includes(permission));
        }
        if (typeof permissions === 'object') {
            return requiredPermissions.some(permission => Object.values(permissions).includes(permission));
        }
        return false;
    }, [requiredPermissions, permissions]);

    return hasRole && hasPermission ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
