import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { newDepartmentValidation } from '../../../../../Yup/Yup';
import axiosInstance from '../../../../../Axios/Axios';
import { useSelector } from 'react-redux';
import { useNotification } from '../../../../Notification/NotificationProvider';


const NewDepartmentModal = ({ addedNewDepartment, editingDepartmentId, editInitialValues }) => {

    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        subjectStatus: '',
        turnAroundTime: '',
    });


    const [emailInputValue, setEmailInputValue] = useState('');
    const [emails, setEmails] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [ccInputValue, setCcInputValue] = useState('');
    const [ccEmails, setCcEmails] = useState([]);
    const { showNotification } = useNotification();

    const handleEmailInputChange = (e) => {
        setEmailInputValue(e.target.value);
    };

    const handleEmailKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addEmail();
        } else if (e.key === 'Backspace' && !emailInputValue) {
            removeLastEmail();
        }
    };

    const addEmail = () => {
        const trimmedValue = emailInputValue.trim();
        if (!trimmedValue) {
            setEmailError('Email is required.');
        } else if (validateEmail(trimmedValue)) {
            setEmails([...emails, trimmedValue]);
            setEmailInputValue('');
            setEmailError('');
        } else {
            setEmailError('Please enter a valid email address.');
        }
    };

    const handleCcInputChange = (e) => {
        setCcInputValue(e.target.value);
    };

    const handleCcKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addCcEmail();
        } else if (e.key === 'Backspace' && !ccInputValue) {
            removeLastCcEmail();
        }
    };

    const addCcEmail = () => {
        const trimmedValue = ccInputValue.trim();
        if (trimmedValue && validateEmail(trimmedValue)) {
            setCcEmails([...ccEmails, trimmedValue]);
            setCcInputValue('');
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const removeEmail = (index) => {
        setEmails(emails.filter((email, i) => i !== index));
    };

    const removeLastEmail = () => {
        setEmails(emails.slice(0, -1));
    };

    const removeCcEmail = (index) => {
        setCcEmails(ccEmails.filter((email, i) => i !== index));
    };

    const removeLastCcEmail = () => {
        setCcEmails(ccEmails.slice(0, -1));
    };


    const token = useSelector((state) => state.login.token);
    const closeRef = useRef();


    useEffect(() => {
        if (editingDepartmentId && editInitialValues) {
            if (typeof editInitialValues?.email === 'string') {
                setEmails(editInitialValues.email.split(',').map(email => email.trim()));
            } else if (Array.isArray(editInitialValues?.email)) {
                setEmails(editInitialValues.email);
            }

            if (typeof editInitialValues?.email_cc === 'string') {
                setCcEmails(editInitialValues.email_cc.split(',').map(email => email.trim()));
            } else if (Array.isArray(editInitialValues?.email_cc)) {
                setCcEmails(editInitialValues.email_cc);
            }

            setInitialValues({
                name: editInitialValues?.name,
                turnAroundTime: editInitialValues?.turn_around_time,
                subjectStatus: editInitialValues?.email_subject_status
            });
        }
    }, [editingDepartmentId, editInitialValues]);

    const onSubmit = (formData, { resetForm }) => {

        const data = {
            name: formData?.name,
            turn_around_time: formData?.turnAroundTime,
            email_subject_status: formData?.subjectStatus,
            email: emails,
            email_cc: ccEmails,

        };

        const request = editingDepartmentId
            ? axiosInstance.put(`/departments/${editingDepartmentId}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            : axiosInstance.post('/departments', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

        request
            .then((response) => {
                if (response.data.success) {
                    const message = response.data.message;
                    showNotification(message, 'success');
                    resetForm();
                    addedNewDepartment();
                    resetInitialValue();
                    closeRef.current.click();
                }
            })
            .catch((error) => {
                showNotification(error.message, 'error');
                console.error('Submission error:', error);
            });
    };

    const resetInitialValue = () => {
        setInitialValues({
            name: '',
            turnAroundTime: '',
            subjectStatus: ''
        })
        setEmails([]);
        setCcEmails([]);
    }

    return (
        <div className="modal fade" tabIndex="-1" id="newDepartmentModal">
            <div className="modal-dialog" style={{ maxWidth: 650 }}>
                <div className="modal-content">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={newDepartmentValidation}
                        onSubmit={onSubmit}>
                        <Form>
                            <div className="modal-header">
                                <h5 className="modal-title">{editingDepartmentId ? 'Edit Department' : 'Add New Department'}</h5>
                                <button type="button" className="btn-close" ref={closeRef} onClick={() => { resetInitialValue(); addedNewDepartment(); }} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        <span className="required-star">*</span>Name
                                    </label>
                                    <Field type="text" className="form-control form-control-sm" id="name" name="name" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        <span className="required-star">*</span>Email
                                    </label>
                                    <div className="multi-email-input">
                                        {emails.map((email, index) => (
                                            <div key={index} className="tag">
                                                {email}
                                                <span className="remove" onClick={() => removeEmail(index)}>
                                                    &times;
                                                </span>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={emailInputValue}
                                            onChange={handleEmailInputChange}
                                            onKeyDown={handleEmailKeyDown}
                                            placeholder="Enter email and press Enter or comma"
                                        />
                                    </div>
                                    {emailError && <div className="text-danger">{emailError}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email_cc" className="form-label">CC</label>
                                    <div className="multi-email-input">
                                        {ccEmails.map((email, index) => (
                                            <div key={index} className="tag">
                                                {email}
                                                <span className="remove" onClick={() => removeCcEmail(index)}>
                                                    &times;
                                                </span>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={ccInputValue}
                                            onChange={handleCcInputChange}
                                            onKeyDown={handleCcKeyDown}
                                            placeholder="Enter email and press Enter or comma"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="subjectStatus" className="form-label">Email Subject Status</label>
                                    <Field type="text" className="form-control form-control-sm" id="subjectStatus" name="subjectStatus" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="turnAroundTime" className="form-label">
                                        <span className="required-star">*</span>Turn Around Time &#40;In Hours&#41;
                                    </label>
                                    <Field type="number" className="form-control form-control-sm" id="turnAroundTime" name="turnAroundTime" />
                                    <ErrorMessage name="turnAroundTime" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary btn-sm text-white">
                                    {editingDepartmentId ? 'Save Changes' : 'Save'}
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default NewDepartmentModal;
