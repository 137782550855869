import React, { useEffect, useState, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomSelect from "../Parts/CustomSelect";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../../Axios/Axios";
import { useNotification } from "../../Notification/NotificationProvider";
import { Avatar } from '@mui/material'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Loading from "../Parts/Loading";
import { AdminHeaderContext } from "../../Context/AdminHeaderContext/AdminHeaderContext";



const Profile = () => {
    const { showNotification } = useNotification();
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [showOPassword, setShowOPassword] = useState(false);
    const user = useSelector((state) => state.login.user);
    const token = useSelector((state) => state.login.token);
    const navigate = useNavigate();
    const [departments, setDepartments] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const { details, setDetails } = useContext(AdminHeaderContext);

    const permissions = user.roles[0].permissions.map((permission) => permission.name);
    const role = user.roles[0].name;


    const [initialValues, setInitialValues] = useState({
        name: "",
        last_name: "",
        email: "",
        password: "",
        phone: "",
        confirmPassword: "",
        oldPassword: "",
        department: "",
        file: null
    });


    const fetchDepartment = (signal) => {
        axiosInstance
            .get("departments", {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
                signal: signal,
            })
            .then((res) => {
                //console.log(res);
                setDepartments(res.data.departments.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate("/");
                } else {
                    console.error(error.message);
                }
            });
    };

    const fetchStaff = (signal) => {
        axiosInstance
            .get(`/staff/${user.staff.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
                signal: signal,
            })
            .then((res) => {
                // console.log(res.data.data);
                setLoading(false)
                setStaffs(res.data.data)
                const staff = res.data.data;
               // console.log(staff);
                setInitialValues({
                    name: staff?.name,
                    last_name: staff?.last_name,
                    email: staff?.email,
                    password: "",
                    phone: staff.phone ? staff.phone : "",
                    department: staff.departments?.map((department) => ({
                        label: department.name,
                        value: department.id,
                    })),
                });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate("/");
                } else {
                    console.error(error.message);
                }
            });
    };

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchStaff(signal);
        fetchDepartment(signal);

        return () => {
            controller.abort();
        };

    }, []);

    const handleSubmit = (values, { resetForm }) => {

        const department_id =
            values?.department && values.department.map((item) => item.value);
        const newStaffData = {
            is_active: user.staff.is_active,
            name: values.name,
            last_name: values.last_name,
            email: values.email,
            password: values?.password,
            phone: values.phone,
            departments: department_id,
            oldPassword: values?.oldPassword,
            confirmPassword: values?.confirmPassword,
            profile_picture: file
        };


        if (!error) {
            axiosInstance
                .post(`/staff/${user.staff.id}`, newStaffData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    if (response.data.success) {
                        setDetails(response.data.data)
                        fetchStaff();
                        showNotification("Profile Updated Successfully", "success");
                        navigate("/profile");
                    }
                })
                .catch((error) => {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.errors &&
                        error.response.data.errors.email
                    ) {
                        const errorMessage = error.response.data.errors.email[0];
                        showNotification(errorMessage, "error");
                        console.error("Submission error:", errorMessage); // Log the error to console
                    } else {
                        showNotification(error.message, "error");
                        console.error("Submission error:", error);
                    }
                });
        }

    };

    return (
        <>
            <div className="container section div">
                {loading && (
                    <div className="loading-overlay">
                        <Loading />
                    </div>
                )}
                <h5>Profile</h5>
                <hr />
                <div className="row">
                    <div className="col-1 d-flex justify-content-start">
                        <Avatar className='border border-secondary' alt={"Avtar"} src={staffs.profile_picture} style={{ height: 80, width: 80, fontSize: '4.25rem' }} />
                    </div>
                    <div className="col-2 d-flex  align-items-center mx-1" >
                        <h4>{staffs.name}&nbsp;{staffs.last_name}</h4>
                    </div>
                </div>
                <div className="row p-4 position-relative">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        //validationSchema={profilePasswordValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            First Name
                                        </label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="last_name" className="form-label">
                                            Last Name
                                        </label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="last_name"
                                            name="last_name"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <Field
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className='mb-3'>
                                        <label htmlFor="Profile-Image" className="form-label">Profile Image</label>
                                        <Field
                                            type="file"
                                            id="fileInput"
                                            name="file"
                                            accept="image/*" // Only allow image files
                                            className='form-control form-control-sm'
                                            onChange={(event) => {
                                                const selectedFile = event.currentTarget.files[0];
                                                if (selectedFile && selectedFile.type.includes('image')) {
                                                    setFile(selectedFile);
                                                    setError(false);
                                                } else {
                                                    setError(true)
                                                    event.target.value = null;
                                                }
                                            }}
                                        />
                                        {error && <span className="text-danger">Please select a valid image file.</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="assignTicket" className="form-label">
                                            Assign Department
                                        </label>
                                        <Field
                                            component={CustomSelect}
                                            id="department"
                                            name="department"
                                            isMulti={true}
                                            options={departments?.map((department) => ({
                                                label: department.name,
                                                value: department.id,
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">
                                            Phone
                                        </label>
                                        <Field
                                            type="tel"
                                            className="form-control"
                                            id="phone"
                                            name="phone"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="oldPassword" className="form-label">
                                            Old Password
                                        </label>
                                        <div className=" input-group">
                                            <Field
                                                type={`${showOPassword ? "text" : "password"}`}
                                                className="form-control "
                                                id="oldPassword"
                                                name="oldPassword"
                                            />
                                            <span
                                                className="input-group-text eye-icon-btn"
                                                onClick={() => setShowOPassword(!showOPassword)}
                                            >
                                                {showOPassword ? (
                                                    <FontAwesomeIcon icon={faEyeSlash} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faEye} />
                                                )}
                                            </span>
                                        </div>
                                        <ErrorMessage name="oldPassword" component="div" className="text-danger" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">
                                            New Password
                                        </label>
                                        <div className=" input-group">
                                            <Field
                                                type={`${showPassword ? "text" : "password"}`}
                                                className="form-control "
                                                id="password"
                                                name="password"
                                            />
                                            <span
                                                className="input-group-text eye-icon-btn"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? (
                                                    <FontAwesomeIcon icon={faEyeSlash} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faEye} />
                                                )}
                                            </span>
                                        </div>
                                        <ErrorMessage name="password" component="div" className="text-danger" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label htmlFor="confirmpassword" className="form-label">
                                            Confirm Password
                                        </label>
                                        <div className=" input-group">
                                            <Field
                                                type={`${showCPassword ? "text" : "password"}`}
                                                className="form-control "
                                                id="confirmPassword"
                                                name="confirmPassword"
                                            />
                                            <span
                                                className="input-group-text eye-icon-btn"
                                                onClick={() => setShowCPassword(!showCPassword)}
                                            >
                                                {showCPassword ? (
                                                    <FontAwesomeIcon icon={faEyeSlash} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faEye} />
                                                )}
                                            </span>
                                        </div>
                                        <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                    </div>
                                </div>

                            </div>
                            <div className="row invoice-footer">
                                <div className="col text-end">
                                    <Link
                                        to={"/tickets"}
                                        type="submit"
                                        className="btn btn-light btn-sm fw-medium mx-2"
                                    >
                                        Close
                                    </Link>
                                    {permissions.includes('Allow profile update') && (
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-sm text-white fw-medium"
                                        >
                                            Save
                                        </button>
                                    )}
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default Profile;
