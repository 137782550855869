import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../../Axios/Axios';
import { useNotification } from '../../../../Notification/NotificationProvider';


function NewChargerModal({ editingChargerId, initialCharger, addedNewCharger }) {


  const [charger, setCharger] = useState('');
  const token = useSelector(state => state.login.token);
  const [error, seterror] = useState(false)
  const closeRef = useRef();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (editingChargerId && initialCharger) {
      setCharger(initialCharger);
    }
  }, [editingChargerId, initialCharger]);

  const handleNewChargerForm = (e) => {
    e.preventDefault();
    if (charger === '') {
      seterror(true)
      return
    }

    const newCharger = {
      name: charger,
    }

    const request = editingChargerId
      ? axiosInstance.put(`/support-ticket-make-of-chargers/${editingChargerId}`, newCharger, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      : axiosInstance.post('/support-ticket-make-of-chargers', newCharger, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

    request
      .then((response) => {
        if (response.data.success) {
          const message = response.data.message;
          showNotification(message, 'success');
          addedNewCharger();
          closeRef.current.click();
          setCharger('');
        }
      })
      .catch((error) => {
        showNotification(error.message, 'error');
        console.error(error);
      });
  };

  const handleChargerChange = (e) => {
    const inputValue = e.target.value;
    setCharger(inputValue);

    if (inputValue.trim() === '') {
      seterror(true);
    } else {
      seterror(false);
    }
  }

  return (
    <div className="modal fade" tabIndex="-1" id='newChargerModal'>
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleNewChargerForm}>
            <div className="modal-header">
              <h5 className="modal-title">{editingChargerId ? 'Edit Charger' : 'Add New Charger'}</h5>
              <button type="button" className="btn-close" ref={closeRef} data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label htmlFor="charger" className="form-label"><span className='required-star'>*</span>Charger Name</label>
              <input type="text" value={charger} onChange={handleChargerChange} className="form-control form-control-sm" id="charger" name="charger" />
              {error && <p className='m-0 text-danger'>Charger name is required.</p>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light btn-sm" data-bs-dismiss="modal" aria-label="Close">Close</button>
              <button type="submit" className="btn btn-primary btn-sm text-white">{editingChargerId ? 'Update' : 'Save'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewChargerModal