import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../../../../Axios/Axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNotification } from '../../../../Notification/NotificationProvider';



function NewCustomerModal({ addedNewCustomer, editingCustomerId, initialCustomerName }) {

    const [customerName, setCustomerName] = useState('');
    const token = useSelector(state => state.login.token);
    const [error, seterror] = useState(false)
    const closeRef = useRef();
    const { showNotification } = useNotification();
    const dispatch = useDispatch();


    useEffect(() => {
        if (editingCustomerId && initialCustomerName) {
            setCustomerName(initialCustomerName);
        }
    }, [editingCustomerId, initialCustomerName]);

    const handleNewCustomerForm = (e) => {
        e.preventDefault();
        if (customerName === '') {
            seterror(true)
            return
        }

        const newCustomer = {
            name: customerName,
        }

        const request = editingCustomerId
            ? axiosInstance.put(`/support-ticket-customers/${editingCustomerId}`, newCustomer, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            : axiosInstance.post('/support-ticket-customers', newCustomer, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

        request
            .then((response) => {
                if (response.data.success) {
                    const message = response.data.message;
                    showNotification(message, 'success');
                    addedNewCustomer();
                    closeRef.current.click();
                    setCustomerName('');
                }
            })
            .catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
    };

    const handleCustomernameChange = (e) => {
        const inputValue = e.target.value;
        setCustomerName(inputValue);

        if (inputValue.trim() === '') {
            seterror(true);
        } else {
            seterror(false);
        }
    }

    return (
        <div className="modal fade" tabIndex="-1" id='newCustomerModal'>
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleNewCustomerForm}>
                        <div className="modal-header">
                            <h5 className="modal-title">{editingCustomerId ? 'Edit Customer' : 'Add New Customer'}</h5>
                            <button type="button" className="btn-close" ref={closeRef} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="customername" className="form-label"><span className='required-star'>*</span>Customer Name</label>
                            <input type="text" value={customerName} onChange={handleCustomernameChange} className="form-control form-control-sm" id="customername" name="customername" />
                            {error && <p className='m-0 text-danger'>Customer name is required</p>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-sm" data-bs-dismiss="modal" aria-label="Close">Close</button>
                            <button type="submit" className="btn btn-primary btn-sm text-white">{editingCustomerId ? 'Update' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewCustomerModal