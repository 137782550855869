// // NotificationContainer.js
// import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
// import Notification from './Notification';

// const NotificationContainer = forwardRef((props, ref) => {
//   const [notifications, setNotifications] = useState([]);

//   const addNotification = useCallback((message, type) => {
//     const id = Date.now();
//     setNotifications((prevNotifications) => [
//       ...prevNotifications,
//       { id, message, type },
//     ]);
//   }, []);

//   const removeNotification = useCallback((id) => {
//     setNotifications((prevNotifications) =>
//       prevNotifications.filter((notification) => notification.id !== id)
//     );
//   }, []);

//   useImperativeHandle(ref, () => ({
//     addNotification,
//   }));

//   return (
//     <div className="notification-container">
//       {notifications.map((notification) => (
//         <Notification
//           key={notification.id}
//           message={notification.message}
//           type={notification.type}
//           onClose={() => removeNotification(notification.id)}
//         />
//       ))}
//     </div>
//   );
// });

// export default NotificationContainer;


// // NotificationContainer.js
// import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
// import Notification from './Notification';

// const NotificationContainer = forwardRef((props, ref) => {
//   const [notifications, setNotifications] = useState([]);

//   const addNotification = useCallback((message, type) => {
//     const id = Date.now();
//     setNotifications((prevNotifications) => [
//       ...prevNotifications,
//       { id, message, type },
//     ]);
//   }, []);

//   const removeNotification = useCallback((id) => {
//     setNotifications((prevNotifications) =>
//       prevNotifications.filter((notification) => notification.id !== id)
//     );
//   }, []);

//   useImperativeHandle(ref, () => ({
//     addNotification,
//   }));

//   return (
//     <div className="notification-container">
//       {notifications.map((notification) => (
//         <Notification
//           key={notification.id}
//           message={notification.message}
//           type={notification.type}
//           onClose={() => removeNotification(notification.id)}
//         />
//       ))}
//     </div>
//   );
// });

// export default NotificationContainer;

import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import Notification from './Notification';

const NotificationContainer = forwardRef((props, ref) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((message, type) => {
    const id = Date.now();
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id, message, type },
    ]);
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  useImperativeHandle(ref, () => ({
    addNotification,
  }));

  return (
    <div className="notification-container">
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </div>
  );
});

export default NotificationContainer;


