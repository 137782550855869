import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../../Axios/Axios'
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { useNotification } from '../../../../Notification/NotificationProvider';
import Loading from '../../../Parts/Loading';



const Permissions = () => {

    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const token = useSelector((state) => state.login.token);
    const user = useSelector((state) => state.login.user);
    const role_permission = useSelector((state) => state.login.permission);
    const [checkedPermissions, setCheckedPermissions] = useState({});
    const [selectCheck, setSelectCheck] = useState({});
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(true);
    const [initialCheckedState, setInitialCheckedState] = useState({});
    const [staffs, setStaffs] = useState([]);

    const staffId = user?.staff.id;
    const dispatch = useDispatch();

    const permissionss = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const test = useSelector((state) => state.login.test);
    //console.log(test);

    //console.log("before", permissionss);
    //console.log("before", role);

    //console.log("before staff", staffs.permissions);

    // change bg when url is match for evamp
    const isEvamp = window.location.href.includes("evamp");

    // Determine logo based on the URL
    const backgroundColor = isEvamp
        ? '#29306e'
        : '#1e293b';



    const fetchInitialValues = async (signal) => {
        try {
            const [
                rolesResponse,
                permissionsResponse,
                rolePermissionsResponse,
                staffResponse
            ] = await Promise.all([
                axiosInstance.get("/roles", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/permissions", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/role-permission", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get(`/staff/${staffId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
            ]);
            setLoading(false);
            setRoles(rolesResponse.data.data);
            setPermissions(permissionsResponse.data.data);
            setRolePermissions(rolePermissionsResponse.data.data);
            setStaffs(staffResponse.data.data);
            const initialCheckedState = {};
            const initialSelectCheckState = {};

            rolesResponse.data.data.forEach(role => {
                initialCheckedState[role] = {};
                initialSelectCheckState[role] = true; // Assume 'Select All' is checked by default

                permissionsResponse.data.data.forEach(permission => {
                    initialCheckedState[role][permission] = false;
                    if (rolePermissionsResponse.data.data[role] && rolePermissionsResponse.data.data[role].includes(permission)) {
                        initialCheckedState[role][permission] = true;
                    }
                });

                const allPermissionsChecked = Object.values(initialCheckedState[role]).every(val => val);
                initialSelectCheckState[role] = allPermissionsChecked;
            });

            setSelectCheck(initialSelectCheckState);
            setCheckedPermissions(initialCheckedState);
            setInitialCheckedState(initialCheckedState);
        } catch (error) {
            console.error(error);
        }

    }

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchInitialValues(signal);

        return () => {
            controller.abort();
        };

    }, [token]);


    const handleCheckboxChange = (role, permission) => {
        setCheckedPermissions(prevState => ({
            ...prevState,
            [role]: {
                ...prevState[role],
                [permission]: !prevState[role][permission]
            }
        }));
    };


    const checkValidation = (roles) => {
        let isValid = true;
        roles.forEach(role => {
            if (role.permissions.length === 0) {
                isValid = false;
                const message = "Please select atleast one Permission in each role!!";
                showNotification(message, 'error');
            }
        });

        return isValid;
    };


    const handleCheckAll = (role) => {
        const updatedPermissions = { ...checkedPermissions[role] };
        const allChecked = Object.values(updatedPermissions).every(val => val);

        for (const permission in updatedPermissions) {
            updatedPermissions[permission] = !allChecked;
        }

        const updatedSelectCheck = !allChecked;

        setCheckedPermissions(prevState => ({
            ...prevState,
            [role]: updatedPermissions
        }));

        setSelectCheck(prevState => ({
            ...prevState,
            [role]: updatedSelectCheck
        }));
    };






    const handleSubmit = async () => {
        const permissionsToSubmit = [];
        for (const [role, permissions] of Object.entries(checkedPermissions)) {

            const checkedPermissionNames = [];
            for (const [permission, isChecked] of Object.entries(permissions)) {
                if (isChecked) {
                    checkedPermissionNames.push(permission);
                }
            }
            permissionsToSubmit.push({ role, permissions: checkedPermissionNames });
        }

        if (checkValidation(permissionsToSubmit)) {

            const data = {
                "role_permissions": permissionsToSubmit
            }

            axiosInstance
                .post('/role-permission', data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    if (response.data.success) {
                        axiosInstance.get(`/staff/${staffId}`)
                            .then((res) => {
                                if (res.data.success) {
                                    const test = "test after";
                                    const updatePermission = staffs.permissions;
                                    // console.log("updatedPermissionsArray", staffs.permissions);
                                    const updatedRole = staffs ? staffs.role?.toString() : null;
                                    let timer;
                                    if (staffs.permissions) {
                                        timer = setTimeout(() => {
                                            dispatch({ type: 'UPDATE_PERMISSIONS', payload: updatePermission })
                                            dispatch({ type: 'UPDATE_ROLE', payload: updatedRole })
                                            dispatch({ type: 'UPDATE_TEST', payload: test })

                                        }, 1000); // 2 seconds delay
                                    }
                                }
                            }).catch((error) => {
                                if (error.response && error.response.status === 401) {
                                    // navigate('/');
                                } else {
                                    showNotification(error.message, 'error');
                                    console.error(error.message);
                                }
                            })



                        const message = response.data.message;
                        showNotification(message, 'success');

                    }
                })
                .catch((error) => {
                    showNotification(error.message, 'error');
                    console.error('Submission error:', error);
                });
        }
    };


    return (
        <div className=''>
            {loading && (
                <div className="loading-overlay">
                    <Loading />
                </div>
            )}
            {roles.map((role, index) => (
                <div className="table-responsive mb-3" key={index}>
                    <table className="table table-striped">
                        <thead className="bg-lightblue text-white">
                            <tr>
                                <th className='text-white' style={{ backgroundColor }}>Role</th>
                                <th colSpan={3} className="text-center text-white" style={{ backgroundColor, justifyContent: 'center' }}>Permissions</th>
                                <th className="text-center text-white" style={{ backgroundColor }}>
                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        <label >Select All
                                            <input
                                                type="checkbox"
                                                className="form-check-input ms-2"
                                                checked={selectCheck[role]}
                                                onChange={() => handleCheckAll(role)}
                                            />
                                        </label>
                                    </div>
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            {permissions.reduce((rows, permission, permIndex) => {
                                if (permIndex % 4 === 0) {
                                    rows.push(permissions.slice(permIndex, permIndex + 4));
                                }
                                return rows;
                            }, []).map((chunk, chunkIndex) => (
                                <tr key={chunkIndex} className={chunkIndex % 2 === 0 ? 'bg-light' : 'bg-dark'}>
                                    {chunkIndex === 0 && (
                                        <td roleCell={true} rowSpan={Math.ceil(permissions.length / 4)} className="align-middle text-center">
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                                <h6>{role[0].toUpperCase() + role.slice(1)}</h6>
                                            </div>
                                        </td>
                                    )}
                                    {chunk.map((permission, permIndex) => (
                                        <td key={permIndex} className="align-middle">
                                            <label className='fw-normal'>
                                                <input
                                                    type="checkbox"
                                                    className='mx-2 form-check-input'
                                                    checked={checkedPermissions[role] ? checkedPermissions[role][permission] : false}
                                                    onChange={() => handleCheckboxChange(role, permission)}
                                                />
                                                {permission}
                                            </label>
                                        </td>

                                    ))}
                                    {chunk.length < 4 && Array(4 - chunk.length).fill().map((_, i) => (
                                        <td key={i}></td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

            <div className='div mt-4'>
                <div className='invoice-footer' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Permissions