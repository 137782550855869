import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function SkeletonComponent({ line, height, ...props }) {
    return (
        <Skeleton count={line} height={height || 30} {...props}/>
    )
}

export default SkeletonComponent