import React, { useState, useEffect } from "react";
import CustomSelect from "../../../Parts/CustomSelect";
import { Formik, Form, Field, ErrorMessagem } from "formik";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../../Axios/Axios";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../Notification/NotificationProvider";
import Loading from "../../../Parts/Loading";


const Settings = () => {
  const { showNotification } = useNotification();
  const token = useSelector((state) => state.login.token);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    encryption: "",
    smtpHost: "",
    smtpPort: "",
    email: "",
    smtpUsername: "",
    smtpPass: "",
  });


  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchdata(signal);

    return () => {
      controller.abort();
    };

  }, []);

  const fetchdata = (signal) => {
    const all_keys = [
      "encryption",
      "smtpHost",
      "email",
      "smtpPort",
      "smtpUsername",
      "smtpPass",
    ];

    const keys = JSON.stringify(all_keys);

    axiosInstance
      .get(`/get-email-settings?keys=${keys}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: signal,
      })
      .then((res) => {
        // console.log(res);
        
        setLoading(false);
        setInitialValues({
          encryption: {
            label: res.data.data.encryption.value,
            value: res.data.data.encryption.id,
          },
          smtpHost: res.data.data.smtpHost.value,
          smtpPort: res.data.data.smtpPort.value,
          email: res.data.data.email.value,
          smtpUsername: res.data.data.smtpUsername.value,
          smtpPass: res.data.data.smtpPass.value,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate("/");
        } else {
          console.error(error.message);
        }
      });
  };

  const onSubmit = (formData, { resetForm }) => {
    //console.log("selectEncryption", formData);
    const data = {
      encryption: formData.encryption.label,
      smtpHost: formData.smtpHost,
      email: formData.email,
      smtpPort: formData.smtpPort,
      smtpUsername: formData.smtpUsername,
      smtpPass: formData.smtpPass,
    };


    axiosInstance
      .post("store-email-settings", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //console.log(res);
        if (res.data.success) {
          const message = res.data.message;
          showNotification(message, "success");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate("/");
        } else {
          showNotification(error.message, "error");
          console.error(error.message);
        }
      });
  };

  return (
    <div>
      {loading && (
        <div className="loading-overlay">
          <Loading />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="section container invoicePage">
            <div className="row ">
              <div className="col ">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Advanced Email System settings</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="section container invoicePage mt-3">
            {/* <div className="row mb-3">
                        <div className="col">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className='m-0'>Configure setting for this Staff member</h5>
                            </div>
                        </div>
                    </div> */}
            {/* <hr className='mt-3 mb-3' /> */}
            <div className="row mt-2 justify-content-center">
              <div className="col">
                <div className="col-8">
                  <div className="row">
                    <label htmlFor="encryption" className="form-label mb-1">
                      Email Encryption
                    </label>
                  </div>
                  <Field
                    component={CustomSelect}
                    name="encryption"
                    options={[
                      { label: "SSL", value: "SSL" },
                      { label: "TLS", value: "TLS" },
                    ]}
                  // value={initialValues.encryption}
                  />

                  <div className="row">
                    <div className="my-2">
                      <label htmlFor="smtphost" className="form-label mb-1">
                        SMTP Host
                      </label>
                      <Field
                        type="text"
                        className="form-control form-control-sm"
                        id="smtp_hosr"
                        name="smtpHost"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="">
                      <label htmlFor="smtpport" className="form-label mb-1">
                        SMTP Port
                      </label>
                      <Field
                        type="text"
                        className="form-control form-control-sm"
                        id="smtp_port"
                        name="smtpPort"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2">
                      <label htmlFor="email" className="form-label mb-1">
                        Email
                      </label>
                      <Field
                        type="email"
                        className="form-control form-control-sm"
                        id="email"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="">
                      <label htmlFor="smtpusername" className="form-label mb-1">
                        SMTP Username
                      </label>
                      <Field
                        type="text"
                        className="form-control form-control-sm"
                        id="smtp_username"
                        name="smtpUsername"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="my-2">
                      <label htmlFor="smtppass" className="form-label mb-1">
                        SMTP Password
                      </label>
                      <Field
                        type="password"
                        className="form-control form-control-sm"
                        id="smtp_pass"
                        name="smtpPass"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row create_note-footer mt-4">
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm text-white"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Settings;
