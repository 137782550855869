import React, { useRef, useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { newStaffMemberValidation } from "../../../../../Yup/Yup";
import axiosInstance from "../../../../../Axios/Axios";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useNotification } from "../../../../Notification/NotificationProvider";
import CustomSelect from "../../../Parts/CustomSelect";
import { useNavigate } from "react-router-dom";


const initialValues = {
  name: "",
  last_name: "",
  email: "",
  password: "",
  parent_company_id: "1",
  phone: "",
  is_staff: false,
  is_active: true,
  department: "",
  confirmPassword: "",
  profile_picture: null,
  role: ""
};

function NewStaffMember({ addedNewCustomer }) {
  const token = useSelector((state) => state.login.token);
  const [showPassword, setShowPassword] = useState(false);
  const { showNotification } = useNotification();
  const closeRef = useRef();
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [showCPassword, setShowCPassword] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchInitialValues(signal);

    return () => {
      controller.abort();
    };

  }, []);

  const fetchInitialValues = useCallback(async (signal) => {
    try {
      const [
        departmentsResponse,
        roleResponse
      ] = await Promise.all([
        axiosInstance.get("/departments", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
        axiosInstance.get("/roles", {
          headers: { Authorization: `Bearer ${token}` },
          signal: signal,
        }),
      ]);

      //setLoading(false)
      setDepartments(departmentsResponse.data.departments.data);
      setRoles(roleResponse.data.data)
      // console.log(roleResponse.data.data);

    } catch (error) {
      console.error(error);
    }
  }, [token]);



  const onSubmit = (values, { resetForm }) => {
    const department_id =
      values?.department && values.department.map((item) => item.value);

    const newStaffData = {
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      phone: values.phone,
      departments: department_id,
      role: values.role.value,
      confirmPassword: values?.confirmPassword,
      profile_picture: file
    };


    axiosInstance
      .post("/staff", newStaffData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success) {
          const message = response.data.message;
          showNotification(message, "success");
          resetForm();
          addedNewCustomer();
          closeRef.current.click();
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.email
        ) {
          const errorMessage = error.response.data.errors.email[0];
          showNotification(errorMessage, "error");
          console.error("Submission error:", errorMessage); // Log the error to console
        } else {
          showNotification(error.message, "error");
          console.error("Submission error:", error);
        }
      });
  };

  const handleCloseBtn = () => [];
  return (
    <div className="modal fade" tabIndex="-1" id="newStaffMember">
      <div className="modal-dialog" style={{ maxWidth: 650 }}>
        <div className="modal-content">
          <Formik
            initialValues={initialValues}
            validationSchema={newStaffMemberValidation} // Assuming you have defined Yup schema
            onSubmit={onSubmit}
          >
            <Form>
              <div className="modal-header">
                <h5 className="modal-title">Add Staff Member</h5>
                <button
                  type="button"
                  className="btn-close"
                  ref={closeRef}
                  onClick={handleCloseBtn}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {/* Form fields go here */}

                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    <span className="required-star">*</span>First Name
                  </label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="name"
                    name="name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="last_name" className="form-label">
                    <span className="required-star">*</span>Last Name
                  </label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="last_name"
                    name="last_name"
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    <span className="required-star">*</span>Email
                  </label>
                  <Field
                    type="email"
                    className="form-control form-control-sm"
                    id="email"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor="Profile-Image" className="form-label">Profile Image</label>
                  <Field
                    type="file"
                    id="fileInput"
                    name="file"
                    className='form-control form-control-sm'
                    onChange={(event) => {
                      setFile(event.currentTarget.files[0]); // Update file state
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="assignTicket" className="form-label">
                    Assign Department
                  </label>
                  <Field
                    component={CustomSelect}
                    id="department"
                    name="department"
                    isMulti={true}
                    options={departments?.map((department) => ({
                      label: department.name,
                      value: department.id,
                    }))}
                  />
                </div>

                {/* <label htmlFor="hourly_rate" className="form-label">Hourly Rate</label>
                                <div className=" input-group">
                                    <Field type="number" className="form-control form-control-sm" id="hourly_rate" name="hourly_rate" />
                                    <span className="input-group-text">&#36;</span>
                                </div>
                                <ErrorMessage name="hourly_rate" component="div" className="text-danger" /> */}

                <div className="my-3">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <Field
                    type="tel"
                    className="form-control form-control-sm"
                    id="phone"
                    name="phone"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        <span className="required-star">*</span>Password
                      </label>
                      <div className=" input-group">
                        <Field
                          type={`${showPassword ? "text" : "password"}`}
                          className="form-control form-control-sm"
                          id="password"
                          name="password"
                        />
                        <span
                          className="input-group-text eye-icon-btn"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="confirmpassword" className="form-label">
                        <span className="required-star">*</span>Confirm Password
                      </label>
                      <div className=" input-group">
                        <Field
                          type={`${showCPassword ? "text" : "password"}`}
                          className="form-control form-control-sm "
                          id="confirmPassword"
                          name="confirmPassword"
                        />
                        <span
                          className="input-group-text eye-icon-btn"
                          onClick={() => setShowCPassword(!showCPassword)}
                        >
                          {showCPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>
                      <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="role" className="form-label">
                        Role
                      </label>
                      <Field
                        component={CustomSelect}
                        id="role"
                        name="role"
                        options={roles?.map((role) => ({
                          label: role.charAt(0).toUpperCase() + role.slice(1),
                          value: role,
                        }))}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={handleCloseBtn}
                  className="btn btn-light btn-sm"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm text-white"
                >
                  Save
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default NewStaffMember;
