import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../Axios/Axios';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { emailValidationSchema, otpValidationSchema, passwordValidationSchema, loginSchema } from '../../Yup/Yup';
import Loading from '../Admin/Parts/Loading';
import EyeIcon from '../Admin/Parts/EyeIcon';
import { useNotification } from '../Notification/NotificationProvider';
import Cookies from 'js-cookie';


function Login() {

    const { showNotification } = useNotification();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isUserLoggedIn = useSelector(state => state.login.is_login);
    const [loading, setLoading] = useState(false)
    const [passwordTypeText, setPasswordTypeText] = useState(false)
    const [confirmPasswordTypeText, setConfirmPasswordTypeText] = useState(false)
    const [activeTab, setActiveTab] = useState('Login');
    const [emailSend, setEmailSend] = useState(false);
    const [verify, setVerify] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [permissions, setPermissions] = useState(null);
    const [isEvamp, setIsEvamp] = useState(false);
    const [role, setRole] = useState(null);
    const storePermissions = useSelector((state) => state.login.permissions);
    const storeRole = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];

    const isSpecialBackground = window.location.href.includes("evamp");

    //change icon based on url
    useEffect(() => {
        const isEvamp = window.location.href.includes("evamp");
        setIsEvamp(isEvamp);
    }, []);

    // useEffect(() => {
    //     const rememberMePref = Cookies.get('rememberMe');
    //     const authToken = Cookies.get('Token');
    //     if (authToken && rememberMePref) {
    //         navigate('/tickets')
    //     }    
    // }, [rememberMe, navigate,])


    useEffect(() => {
        if (storePermissions && storeRole) {
            const userPermissions = storePermissions ? storePermissions : null;
            const userRole = storeRole ? storeRole : null;
            setRole(userRole);
            setPermissions(userPermissions);
        }
    }, [storePermissions, storeRole]);

    function updatePermissions(newPermissions) {
        // If newPermissions is already an object, return it
        if (typeof newPermissions === 'object' && !Array.isArray(newPermissions)) {
            return newPermissions;
        }

        // Convert the array to an object with indices as keys
        const updatedPermissions = {};
        newPermissions.forEach((permission, index) => {
            updatedPermissions[index] = permission;
        });

        return updatedPermissions;
    }

    // useEffect(() => {
    //     let timer;
    //     if (permissions && role) {
    //         // console.log("permissions", permissions);
    //         const updatedDataObject = updatePermissions(permissions);
    //         const updatedDataArray = Object.values(updatedDataObject);
    //         // console.log("updatedDataArray", updatedDataArray);

    //         timer = setTimeout(() => {
    //             if (permissions.includes('List tickets') && roles.includes(role)) {
    //                 navigate('/tickets');
    //             } else {
    //                 navigate('/staff');
    //             }
    //         }, 200); // 2 seconds delay
    //     }
    // }, [isUserLoggedIn, navigate]);


    // useEffect(() => {
    //     if (isUserLoggedIn && permissions && role) {
    //         let arrayPermissions = null;

    //         // Convert permissions to an array if it's an object
    //         if (typeof permissions === 'object' && permissions !== null) {
    //             arrayPermissions = Object.keys(permissions)
    //                 .sort((a, b) => a - b)
    //                 .map(key => permissions[key]);
    //         } else {
    //             arrayPermissions = permissions;
    //         }

    //         // Check if arrayPermissions is an array and includes 'List tickets'
    //         if (permissions && permissions.includes('List tickets') && roles.includes(role)) {
    //             // Navigate to '/tickets'
    //             navigate('/tickets');
    //         } else {
    //             // Navigate to '/staff'
    //             navigate('/staff');
    //         }
    //     }
    // }, []);



    // Separate state variables for login and register forms
    const loginFormData = {
        loginemail: '',
        loginpassword: ''
    };

    const [initialValues, setInitialValues] = useState({

        otp: '', // Fix the typo in the form field name
        forgotEmail: '',
        confirmPassword: '',
        forgotpassword: '',
    })


    const [alert, setAlert] = useState({
        message: 'Invalid credential.',
        show: false
    })

    const handleFormChange = (form, resetForm) => {
        setActiveTab(form)
        resetForm()
    }

    const handleLoginForm = (values) => {
        setLoading(true)
        // console.log(values);
        //console.log(rememberMe);
        const formData = {
            email: values.loginemail,
            password: values.loginpassword,
        }
        axiosInstance.post('/login', formData)
            .then((res) => {
                setLoading(false)
                if (res.data.success) {
                    const token = res.data.token;
                    const permissions = res.data.user.roles?.[0].permissions?.map((permission) => permission.name);
                    const role = res.data.user.roles?.[0].name;
                    const currentDateTime = new Date().toLocaleString();
                    const test = "before test";
                    // console.log(permissions);
                    dispatch({ type: 'LOGIN', payload: true });
                    dispatch({ type: 'UPDATE_TOKEN', payload: token });
                    dispatch({ type: 'USER', payload: res.data.user });
                    dispatch({ type: 'PERMISSIONS', payload: permissions })
                    dispatch({ type: 'ROLE', payload: role })
                    dispatch({ type: 'LASTLOGIN', payload: currentDateTime })
                    // if (rememberMe) {
                    //     const options = rememberMe
                    //         ? { expires: 365 }
                    //         : undefined;
                    //     Cookies.set('Token', token, options);
                    //     Cookies.set('rememberMe', rememberMe, { expires: 365 });
                    // }

                    let timer;
                    if (permissions && role) {
                        // console.log("permissions", permissions);
                        const updatedDataObject = updatePermissions(permissions);
                        const updatedDataArray = Object.values(updatedDataObject);
                        // console.log("updatedDataArray", updatedDataArray);

                        timer = setTimeout(() => {
                            if (permissions.includes('List tickets') && roles.includes(role)) {
                                navigate('/tickets');
                            } else {
                                navigate('/staff');
                            }
                        }, 200); // 2 seconds delay
                    }
                    const message = res.data.message;
                    showNotification(message, 'success');

                }
                else {
                    setAlert({ ...alert, show: true })
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            })
    }

    const getValidationSchema = () => {
        if (verify) {
            return passwordValidationSchema;
        } else if (emailSend) {
            return otpValidationSchema;
        } else {
            return emailValidationSchema;
        }
    };

    const handleRegisterForm = (values) => {

        const formData = new FormData();
        formData.append('email', values.forgotEmail);
        if (values.otp) formData.append('code', values.otp);
        if (values.forgotpassword) formData.append('password', values.forgotpassword);
        if (values.confirmPassword) formData.append('password_confirmation', values.confirmPassword);


        // console.log(formData);

        if (!values.otp && !values.forgotpassword && !values.confirmPassword) {
            setLoading(true)
            axiosInstance.post('/password/email', formData, {
                headers: {
                    'Content-Type': 'application/form-data',
                    'Accept': 'application/json'
                },
            }).then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    setLoading(false)
                    setEmailSend(true);
                    setInitialValues({ forgotEmail: '' })
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            })

        } else if (!values.forgotpassword && !values.confirmPassword) {
            setLoading(true)
            axiosInstance.post('/password/code/check', formData, {
                headers: {
                    'Content-Type': 'application/form-data',
                    'Accept': 'application/json'
                },
            }).then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    setLoading(false)
                    setVerify(true);
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            })
        } else {
            setLoading(true)
            axiosInstance.post('/password/reset', formData, {
                headers: {
                    'Content-Type': 'application/form-data',
                    'Accept': 'application/json'
                },
            }).then((res) => {
                //  console.log(res);
                if (res.status === 200) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    setLoading(false)
                    setEmailSend(false);
                    setVerify(false)
                    setActiveTab('Login')
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            })
        }
    }


    return (
        <div className={`login-page ${isSpecialBackground ? 'special-login-background' : ''}`}>
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-4 login-card py-4">
                        {alert.show && (
                            <div className="alert alert-danger" role="alert">
                                {alert.message}
                            </div>
                        )}
                        <div className="row mb-1">
                            <div className="col text-center justify-content-center d-flex align-items-center">
                                {isEvamp ? <img style={{ width: 300 }} src={require('../../Assets/images/evamp-logo.jpg')} alt="ExcelBook" /> : <img style={{ width: 300 }} src={require('../../Assets/images/excelbooklogo.png')} alt="ExcelBook" />}

                            </div>
                        </div>
                        <hr />
                        <div className="row text-center pt-1 pb-2 title">
                            <div className="col">
                                <h4 className="mb-0 active">{activeTab}</h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col px-4">
                                {activeTab === 'Login' ?
                                    <Formik
                                        key="login-form"
                                        initialValues={loginFormData}
                                        validationSchema={loginSchema}
                                        onSubmit={handleLoginForm}
                                    >{({ errors, touched, resetForm }) => (
                                        <Form>
                                            <div className="mb-3">
                                                <label htmlFor="loginemail" className="form-label">
                                                    Email address
                                                </label>
                                                <Field
                                                    type="email"
                                                    id="loginemail"
                                                    name="loginemail"
                                                    className="form-control"
                                                />
                                                <ErrorMessage name="loginemail" component="div" className="text-danger" />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="loginpassword" className="form-label">
                                                    Password
                                                </label>
                                                <div className="item-group">
                                                    <div className='d-flex w-100'>
                                                        <Field
                                                            type={`${passwordTypeText ? "text" : "password"}`}
                                                            id="loginpassword"
                                                            name="loginpassword"
                                                            className="form-control password"
                                                        />
                                                        <button className='addNewBtn' type='button' onClick={() => setPasswordTypeText(!passwordTypeText)}><EyeIcon type={passwordTypeText ? "open" : "close"} /></button>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="loginpassword" component="div" className="text-danger" />
                                            </div>
                                            <div className="row">
                                                <div className="col hustify-content-start" style={{ display: 'none' }}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} type="checkbox" value="" id="flexCheckDefault" />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                            Remember Me
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <p className=" text-end">
                                                        <Link onClick={() => handleFormChange('Forgot Password', resetForm)}>Forgot Password?</Link>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary text-white">
                                                    Login
                                                </button>
                                            </div>
                                            {/* <p className="text-center mt-3">
                                                Don't have an account?{' '}
                                                <Link onClick={() => handleFormChange('Register', resetForm)}>Register</Link>
                                            </p> */}
                                        </Form>
                                    )}
                                    </Formik>
                                    :
                                    <Formik
                                        key="register-form"
                                        initialValues={initialValues}
                                        validationSchema={getValidationSchema}
                                        onSubmit={handleRegisterForm}
                                    >{({ errors, touched, resetForm }) => (
                                        <Form>
                                            <div className="mb-2">
                                                <label htmlFor="forgotEmail" className="form-label">
                                                    Email address
                                                </label>
                                                <Field
                                                    type="email"
                                                    id="forgotEmail"
                                                    name="forgotEmail"
                                                    className="form-control"
                                                />
                                                <ErrorMessage name="forgotEmail" component="div" className="text-danger" />
                                            </div>
                                            {
                                                emailSend && (
                                                    <div className="mb-2">
                                                        <label htmlFor="otp" className="form-label">
                                                            Enter OTP
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="otp"
                                                            name="otp"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage name="otp" component="div" className="text-danger" />
                                                    </div>
                                                )
                                            }

                                            {
                                                verify && (
                                                    <div>
                                                        <div className="mb-2">
                                                            <label htmlFor="forgotpassword" className="form-label">
                                                                Password
                                                            </label>
                                                            <div className="item-group">
                                                                <div className='d-flex w-100'>
                                                                    <Field
                                                                        type={`${passwordTypeText ? "text" : "password"}`}
                                                                        id="forgotpassword"
                                                                        name="forgotpassword"
                                                                        className="form-control password"
                                                                    />
                                                                    <button className='addNewBtn' type='button' onClick={() => setPasswordTypeText(!passwordTypeText)}><EyeIcon type={passwordTypeText ? "open" : "close"} /></button>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="forgotpassword" component="div" className="text-danger" />
                                                        </div>
                                                        <div className="mb-2">
                                                            <label htmlFor="confirmPassword" className="form-label">
                                                                Confirm Password
                                                            </label>
                                                            <div className="item-group">
                                                                <div className='d-flex w-100'>
                                                                    <Field
                                                                        type={`${confirmPasswordTypeText ? "text" : "password"}`}
                                                                        id="confirmPassword"
                                                                        name="confirmPassword"
                                                                        className="form-control password"
                                                                    />
                                                                    <button className='addNewBtn' type='button' onClick={() => setConfirmPasswordTypeText(!confirmPasswordTypeText)}><EyeIcon type={passwordTypeText ? "open" : "close"} /></button>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <div className="text-center mt-3">
                                                <button type="submit" className="btn btn-primary text-white">
                                                    Submit
                                                </button>
                                            </div>
                                            <p className="text-center mt-2">
                                                Go to the{' '}
                                                <Link onClick={() => handleFormChange('Login', resetForm, setEmailSend(false), setVerify(false))}>Login</Link>
                                            </p>
                                        </Form>
                                    )}
                                    </Formik>
                                }
                                {
                                    loading &&
                                    <Loading />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Login