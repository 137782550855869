import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../../Axios/Axios';
import SkeletonComponent from '../../../Parts/SkeletonComponent';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import NewDepartmentModal from '../Modals/NewDepartmentModal';
import CustomTablePagination from '../../../Parts/CustomTablePagination';
import { useNotification } from '../../../../Notification/NotificationProvider';



function Departments() {

    const token = useSelector(state => state.login.token);
    const user = useSelector((state) => state.login.user);
    const navigate = useNavigate()
    const [departments, setDepartments] = useState([]);
    const [page, setPage] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(true)
    const [editingDepartmentId, setEditingDepartmentId] = useState(null)
    const [eidtInitialValues, setEidtInitialValues] = useState(null)
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];


    const fetchDepartments = useCallback((signal) => {
        axiosInstance.get(`/departments?page=${page + 1}&item=${rowsPerPage}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            signal: signal,
        }).then((res) => {
            setLoading(false)
            //console.log(res.data.departments.data);
            setDepartments(res.data.departments.data);
            // try {
            //     const [
            //         departmentResponse,
            //     ] = Promise.all([
            //         axiosInstance.get("/departments", {
            //             headers: { Authorization: `Bearer ${token}` },
            //             signal: signal,
            //         }),
            //     ]);
            //     dispatch({
            //         type: 'SET_INITIAL_DATA',
            //         payload: {
            //             departments: departmentResponse.data.departments.data,
            //         }
            //     });
            // } catch (error) {
            //     console.error(error);
            // }
            setTotalItem(res.data.departments.meta.total);
            setLastPage(res.data.departments.meta.last_page);

        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                setLoading(false)
                navigate('/');
            } else {
                console.error(error.message);
            }
        })
    }, [token, page, rowsPerPage]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchDepartments(signal);

        return () => {
            controller.abort();
        };

    }, [fetchDepartments]);



    const addedNewDepartment = () => {
        fetchDepartments()
        setEditingDepartmentId(null)
        setEidtInitialValues(null)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDepartmentDelete = (id, groupname) => {
        if (window.confirm(`Are you sure to delete ${groupname} group?`)) {
            axiosInstance.delete(`/departments/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    fetchDepartments();
                    setPage(0)
                }
            }).catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
        }
    };
    const exportData = departments?.map((department, index) => ({
        '#': index + 1,
        'Department Name': department.name,
        'Department Email': department.email,
    }));

    return (
        <div className='container customer-Groups-list'>
            <div className="row">
                <div className="col section">
                    <div className="row">
                        <div className="col">
                            {departments.length < 5 ? (
                                <>
                                    {
                                        permissions.includes('Allow profile update') && roles.includes(role) && (
                                            <button
                                                className='btn btn-primary btn-sm text-white'
                                                data-bs-toggle="modal"
                                                data-bs-target="#newDepartmentModal"
                                            >
                                                + New Department
                                            </button>
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {permissions.includes('Allow profile update') && roles.includes(role) && (
                                        <button
                                            className='btn btn-primary btn-sm text-white'
                                            style={{ color: 'grey', cursor: 'not-allowed' }}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Cannot add department greater than 5"
                                        >
                                            + New Department
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">
                            <CSVLink data={exportData} filename='Departments.csv' className='btn btn-light btn-sm'>Export</CSVLink>
                        </div>
                    </div>


                    <div className="row mt-2">
                        <div className="col text-center">

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={70}><strong>ID</strong></TableCell>
                                            <TableCell><strong>Name</strong></TableCell>
                                            <TableCell><strong>Department Email</strong></TableCell>
                                            {
                                                (permissions.includes('Delete Department') || permissions.includes('Edit Department')) && roles.includes(role) && (
                                                    <TableCell><strong>Action</strong></TableCell>
                                                )
                                            }

                                        </TableRow>
                                    </TableHead>
                                    {loading ?
                                        <TableBody>
                                            {[...Array(2)].map((_, rowIndex) => (
                                                <TableRow key={rowIndex}>

                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    {
                                                        (permissions.includes('Delete Department') || permissions.includes('Edit Department')) && roles.includes(role) && (
                                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                                        )
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        : (

                                            departments.length === 0 ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={3} className='text-center'>
                                                            No data available. Add Departments first.
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {(rowsPerPage > 0 && departments
                                                    ).map((department, index) => (
                                                        <TableRow key={department.id}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                {
                                                                    permissions.includes('Edit Department') && roles.includes(role) ? (
                                                                        <Link
                                                                            onClick={() => {
                                                                                setEditingDepartmentId(department.id);
                                                                                setEidtInitialValues({
                                                                                    name: department.name,
                                                                                    email: department.email,
                                                                                    turn_around_time: department.turn_around_time,
                                                                                    email_cc: department.email_cc,
                                                                                    email_subject_status: department?.email_subject_status
                                                                                });
                                                                            }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#newDepartmentModal"
                                                                        >
                                                                            {department.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <Link > {department.name}</Link>
                                                                    )
                                                                }
                                                            </TableCell>
                                                            <TableCell><a href={`mailto:${department.email}`}>{department.email}</a></TableCell>
                                                            {
                                                                (permissions.includes('Edit Department') || permissions.includes('Delete Department')) && roles.includes(role) && (
                                                                    <TableCell>
                                                                        {permissions.includes('Edit Department') && (
                                                                            <FontAwesomeIcon
                                                                                icon={faPenToSquare}
                                                                                className='me-2 edit-icon'
                                                                                onClick={() => {
                                                                                    setEditingDepartmentId(department.id);
                                                                                    setEidtInitialValues({
                                                                                        name: department.name,
                                                                                        email: department.email,
                                                                                        turn_around_time: department.turn_around_time,
                                                                                        email_cc: department.email_cc,
                                                                                        email_subject_status: department?.email_subject_status
                                                                                    });
                                                                                }}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#newDepartmentModal"
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Edit"
                                                                            />
                                                                        )}
                                                                        {permissions.includes('Delete Department') && (
                                                                            <FontAwesomeIcon
                                                                                onClick={() => handleDepartmentDelete(department.id, department.name)}
                                                                                icon={faTrashCan}
                                                                                className='delete-icon'
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Delete"
                                                                            />
                                                                        )}
                                                                    </TableCell>
                                                                )
                                                            }

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )
                                        )}
                                </Table>
                            </TableContainer>
                            {departments.length !== 0 && (
                                <CustomTablePagination
                                    page={page}
                                    setPage={setPage}
                                    count={totalItem}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    lastPage={lastPage}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <Tooltip id="my-tooltip" />
            <NewDepartmentModal addedNewDepartment={addedNewDepartment} editingDepartmentId={editingDepartmentId} editInitialValues={eidtInitialValues} />
        </div>
    )
}

export default Departments