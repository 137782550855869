import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import NewStaffMember from "../Modals/NewStaffMember";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../../../Axios/Axios";
import SkeletonComponent from "../../../Parts/SkeletonComponent";
import { CSVLink } from "react-csv";
import CustomTablePagination from "../../../Parts/CustomTablePagination";
import { useNotification } from "../../../../Notification/NotificationProvider";

function Staff() {
  const token = useSelector((state) => state.login.token);
  const user = useSelector((state) => state.login.user);
  const navigate = useNavigate();
  const [staffMember, setStaffMember] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hoveredRow, setHoveredRow] = useState(null);
  const { showNotification } = useNotification();

  const role = user.roles[0].name;
  const permissions = user.roles[0].permissions.map((permission) => permission.name);
  const roles = ['admin', 'staff'];

  const fetchStaffMember = useCallback((signal) => {
    axiosInstance
      .get(`/staff?page=${page + 1}&item=${rowsPerPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: signal,
      })
      .then((res) => {
        setLoading(false);
        setStaffMember(res.data.staff.data);
        setTotalItem(res.data.staff.meta.total);
        setLastPage(res.data.staff.meta.last_page);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate("/");
        } else {
          console.error(error.message);
        }
      });
  }, [token, page, rowsPerPage]);



  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchStaffMember(signal);

    return () => {
      controller.abort();
    };

  }, [fetchStaffMember]);

  const addedNewCustomer = () => {
    fetchStaffMember();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleMouseEnter = (id) => {
    setHoveredRow(id);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  const handleStaffDelete = (id, firstname, lastname) => {
    if (window.confirm(`Are you sure to delete ${firstname} ${lastname}?`)) {
      axiosInstance
        .delete(`/staff/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const message = res.data.message;
            showNotification(message, "success");
            fetchStaffMember();
            setPage(0);
          }
        })
        .catch((error) => {
          showNotification(error.message, "error");
          console.error(error);
        });
    }
  };

  const exportData = staffMember
    ? staffMember.map((staff) => ({
      "First Name": staff.name,
      "Last Name": staff.last_name,
      "Email": staff.email,
      "Phone": staff.phone,
      "Hourly Rate": `${staff.hourly_rate}$`,
      "Active": staff.is_active === 1 ? "Yes" : "No",
    }))
    : [];

  const calculateTimeDifference = (lastLogin) => {
    const currentTimestamp = new Date().getTime();
    const lastLoginTimestamp = new Date(lastLogin).getTime();
    const timeDifference = currentTimestamp - lastLoginTimestamp;

    // Calculate days, hours, and minutes
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    // Determine the appropriate format based on the time difference
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    }
  };

  const handleToggleActive = (id, currentStatus) => {
    const selectedMember = staffMember.find((staff) => staff.id === id);

    if (selectedMember) {
      const updatedStaff = { ...selectedMember, is_active: !currentStatus };
      setStaffMember((prevStaff) =>
        prevStaff.map((staff) => (staff.id === id ? updatedStaff : staff))
      );

      axiosInstance
        .patch(`/staff/${id}`, updatedStaff, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const message = res.data.message;
            showNotification(message, "success");
          }
        })
        .catch((error) => {
          showNotification(error.message, "error");
          console.error(error);
        });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col section">
          <div className="row">
            <div className="col">
              {staffMember.length < 5 ? (
                <>
                  {
                    permissions.includes('Add Staff') && roles.includes(role) && (
                      <button
                        className="btn btn-primary btn-sm text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#newStaffMember"
                      >
                        + New Staff Member
                      </button>
                    )
                  }
                </>
              ) : (
                <>
                  {
                    permissions.includes('Add Staff') && roles.includes(role) && (
                      <button
                        className="btn btn-primary btn-sm text-white"
                        style={{ color: "grey", cursor: "not-allowed" }}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Cannot add staff greater than 5"
                      >
                        + New Staff Member
                      </button>
                    )
                  }
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col text-end">
              <CSVLink
                data={exportData}
                filename="Staff_Member_list.csv"
                className="btn btn-light btn-sm"
              >
                Export
              </CSVLink>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-center">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>First name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Last name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Role</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Last login</strong>
                      </TableCell>
                      {/* <TableCell><strong>Active</strong></TableCell> */}
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableBody>
                      {[...Array(2)].map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell>
                            <SkeletonComponent line={1} />
                          </TableCell>
                          <TableCell>
                            <SkeletonComponent line={1} />
                          </TableCell>
                          <TableCell>
                            <SkeletonComponent line={1} />
                          </TableCell>
                          <TableCell>
                            <SkeletonComponent line={1} />
                          </TableCell>
                          <TableCell>
                            <SkeletonComponent line={1} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : staffMember.length === 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={3} className="text-center">
                          No data available. Add Staff Member First.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {((rowsPerPage > 0 && staffMember) || []).map(
                        (staff, index) => (
                          <TableRow
                            key={staff.id}
                            onMouseEnter={() => handleMouseEnter(staff.id)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <TableCell>
                              {
                                permissions.includes('Edit Staff') && roles.includes(role) ? (
                                  <Link to={`/staff/${staff.id}`}>
                                    {`${staff.name} ${staff.last_name}`}
                                  </Link>
                                ) : (
                                  <Link >
                                    {`${staff.name} ${staff.last_name}`}
                                  </Link>
                                )
                              }
                              <div
                                style={{
                                  visibility: `${hoveredRow === staff.id
                                    ? "visible"
                                    : "hidden"
                                    }`,
                                }}
                              >
                                {
                                  permissions.includes('Edit Staff') && roles.includes(role) && (
                                    <span className="me-1">
                                      <Link to={`/staff/${staff.id}`}>View</Link>
                                    </span>
                                  )
                                }
                                {
                                  permissions.includes('Delete Staff') || permissions.includes('Edit Staff') && roles.includes(role) && (
                                    '|'
                                  )
                                }
                                {
                                  permissions.includes('Delete Staff') && roles.includes(role) && (
                                    <span
                                      className="text-danger ms-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleStaffDelete(
                                          staff.id,
                                          staff.name,
                                          staff.last_name
                                        )
                                      }
                                    >
                                      Delete
                                    </span>
                                  )
                                }
                              </div>
                            </TableCell>
                            <TableCell>
                              <a href={`mailto:${staff.email}`}>
                                {staff.email}
                              </a>
                            </TableCell>
                            <TableCell>
                              {staff.role ? staff.role : "-"}
                            </TableCell>
                            <TableCell>
                              {staff.last_action_by.updated_at &&
                                calculateTimeDifference(
                                  staff.last_action_by.updated_at
                                )}
                            </TableCell>
                            <TableCell style={{ display: "none" }}>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: 38, height: 18 }}
                                  role="switch"
                                  id={`flexSwitchCheckChecked${staff.id}`}
                                  checked={staff.is_active}
                                  onChange={() =>
                                    handleToggleActive(
                                      staff.id,
                                      staff.is_active
                                    )
                                  }
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              {staffMember.length !== 0 && (
                <CustomTablePagination
                  page={page}
                  setPage={setPage}
                  count={totalItem}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  lastPage={lastPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <NewStaffMember addedNewCustomer={addedNewCustomer} />
    </div>
  );
}

export default Staff;
