import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../../Axios/Axios';
import SkeletonComponent from '../../../Parts/SkeletonComponent';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { useCallback } from 'react';
import NewTicketStatusModal from '../Modals/NewTicketStatusModal';
import CustomTablePagination from '../../../Parts/CustomTablePagination';
import { useNotification } from '../../../../Notification/NotificationProvider';



function TicketStatuses() {

    const token = useSelector(state => state.login.token);
    const user = useSelector((state) => state.login.user);
    const [ticketStatusNames, setTicketStatusNames] = useState([]);
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [lastPage, setLastPage] = useState(0); const [loading, setLoading] = useState(true)
    const [initialTicketStatusName, SetInitialTicketStatusName] = useState('')
    const [editingTicketStatusId, setEditingTicketStatusId] = useState(null)
    const { showNotification } = useNotification();

    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];

    const fetchTicketStatuse = useCallback((signal) => {
        axiosInstance.get(`/ticket-statuses?page=${page + 1}&item=${rowsPerPage}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            signal: signal,
        }).then((res) => {
            setLoading(false)
            // console.log(res.data.ticketStatuses.data);
            setTicketStatusNames(res.data.ticketStatuses.data);
            setTotalItem(res.data.ticketStatuses.meta.total);
            setLastPage(res.data.ticketStatuses.meta.last_page);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                console.error(error.message);
            }
        })
    }, [token, page, rowsPerPage]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchTicketStatuse(signal);

        return () => {
            controller.abort();
        };

    }, [fetchTicketStatuse]);



    const addedNewTicketStatus = () => {
        fetchTicketStatuse()
        setEditingTicketStatusId(null)
        SetInitialTicketStatusName('')
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleStatusDelete = (id, groupname) => {
        if (window.confirm(`Are you sure to delete ${groupname} status?`)) {
            axiosInstance.delete(`/ticket-statuses/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    fetchTicketStatuse();
                    setPage(0)
                }
            }).catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
        }
    };
    const exportData = ticketStatusNames ? ticketStatusNames.map((ticket, index) => ({
        '#': index + 1,
        'Ticket name': ticket.name,
        'color code': ticket.color,
        'order': ticket.order,
    })) : [];


    return (
        <div className='container customer-Groups-list'>
            <div className="row">
                <div className="col section">
                    <div className="row">
                        <div className="col">
                            {
                                (permissions.includes('Delete Status') || permissions.includes('Edit Status')) && roles.includes(role) && (
                                    <button className='btn btn-primary btn-sm text-white' data-bs-toggle="modal" data-bs-target="#newTicketStatusModal">+ New Ticket Status</button>
                                )
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">
                            <CSVLink data={exportData} filename='Ticket-statuses.csv' className='btn btn-light btn-sm'>Export</CSVLink>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col text-center">
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>#</strong></TableCell>
                                            <TableCell><strong>Ticket status name</strong></TableCell>
                                            {
                                                (permissions.includes('Delete Status') || permissions.includes('Edit Status')) && roles.includes(role) && (
                                                    <TableCell><strong>Action</strong></TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {loading ?
                                        <TableBody>
                                            {[...Array(2)].map((_, rowIndex) => (
                                                <TableRow key={rowIndex}>

                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    {
                                                        (permissions.includes('Delete Status') || permissions.includes('Edit Status')) && roles.includes(role) && (
                                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                                        )
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        : (

                                            ticketStatusNames.length === 0 ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={3} className='text-center'>
                                                            No data available. Add Ticket status first.
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (

                                                <TableBody>
                                                    {(rowsPerPage > 0 && ticketStatusNames || []).map((ticket, index) => (
                                                        <TableRow key={ticket.id}>
                                                            <TableCell>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    permissions.includes('Edit Status') && roles.includes(role) ? (
                                                                        <Link
                                                                            onClick={() => {
                                                                                setEditingTicketStatusId(ticket.id);
                                                                            }}
                                                                        >
                                                                            {ticket.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <Link > {ticket.name}</Link>
                                                                    )
                                                                }
                                                            </TableCell>
                                                            {ticket.name !== 'Closed' ? (
                                                                <>
                                                                    {
                                                                        (permissions.includes('Edit Status') || permissions.includes('Delete Status')) && roles.includes(role) && (
                                                                            <TableCell>
                                                                                {permissions.includes('Edit Status') && (
                                                                                    <FontAwesomeIcon
                                                                                        icon={faPenToSquare}
                                                                                        className='me-2 edit-icon'
                                                                                        onClick={() => {
                                                                                            setEditingTicketStatusId(ticket.id);
                                                                                            SetInitialTicketStatusName({
                                                                                                name: ticket.name,
                                                                                                color: ticket.color,
                                                                                                order: ticket.order
                                                                                            });
                                                                                        }}
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#newTicketStatusModal"
                                                                                        data-tooltip-id="my-tooltip"
                                                                                        data-tooltip-content="Edit Group"
                                                                                    />
                                                                                )}
                                                                                {permissions.includes('Delete Status') && (
                                                                                    <FontAwesomeIcon
                                                                                        onClick={() => handleStatusDelete(ticket.id, ticket.name)}
                                                                                        icon={faTrashCan}
                                                                                        className='delete-icon'
                                                                                        data-tooltip-id="my-tooltip"
                                                                                        data-tooltip-content="Delete Group"
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        (permissions.includes('Edit Status') || permissions.includes('Delete Status')) && roles.includes(role) && (
                                                                            <TableCell>
                                                                                {permissions.includes('Edit Status') && (
                                                                                    <FontAwesomeIcon
                                                                                        icon={faPenToSquare}
                                                                                        className='me-2 edit-icon'
                                                                                        style={{ color: 'grey', cursor: 'not-allowed' }}
                                                                                        data-tooltip-id="my-tooltip"
                                                                                        data-tooltip-content="Cannot Edit Closed Status"
                                                                                    />
                                                                                )}
                                                                                {permissions.includes('Delete Status') && (
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTrashCan}
                                                                                        className='delete-icon'
                                                                                        style={{ color: 'grey', cursor: 'not-allowed' }}
                                                                                        data-tooltip-id="my-tooltip"
                                                                                        data-tooltip-content="Cannot Delete Closed Status"
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                </>
                                                            )}

                                                        </TableRow>
                                                    ))}
                                                </TableBody>

                                            )
                                        )}
                                </Table>
                            </TableContainer>
                            {ticketStatusNames.length !== 0 && (
                                <CustomTablePagination
                                    page={page}
                                    setPage={setPage}
                                    count={totalItem}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    lastPage={lastPage}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <Tooltip id="my-tooltip" />
            <NewTicketStatusModal addedNewTicketStatus={addedNewTicketStatus} editingTicketStatusId={editingTicketStatusId} initialTicketStatusName={initialTicketStatusName} />
        </div>
    )
}

export default TicketStatuses