import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faUsers, faCog, faTicket, faUserLock, faListCheck, faGears } from '@fortawesome/free-solid-svg-icons';

function Sidebar({ isCollapsed }) {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);
  const [arrowRotation, setArrowRotation] = useState({});
  const [isSetupMenuOpen, setIsSetupMenuOpen] = useState(false);
  const permissions = useSelector((state) => state.login.permissions);
  const role = useSelector((state) => state.login.role);

  const isSpecialBackground = window.location.href.includes("evamp");

  const handleParentClick = (index, label) => {
    if (label === 'Settings') {
      setIsSetupMenuOpen(true);
      return;
    }

    if ((location.pathname).includes(label.toLocaleLowerCase())) {
      return;
    }
    const resetArrowRotation = {};
    Object.keys(arrowRotation).forEach((key) => {
      resetArrowRotation[key] = 0;
    });
    setArrowRotation({ ...resetArrowRotation, [index]: arrowRotation[index] ? 0 : 270 });
    setActiveMenu(activeMenu === index ? null : index);
  };

  // setTimeout(() => {
  //   if((location.pathname).includes('setup')){
  //     setIsSetupMenuOpen(true)
  //   }
  // }, 300);


  const menuItems = [
    {
      id: 1, label: 'Ticket Support', link: '/tickets', requiredRoles: ['admin', 'staff'], requiredPermissions: ['List tickets'], icon: faTicket
    },
    {
      id: 2, label: 'Ticket Setup', requiredRoles: ['admin', 'staff'], requiredPermissions: ['List tickets'], submenus: [
        { label: 'Departments', link: '/tickets/departments', requiredRoles: ['admin', 'staff'], requiredPermissions: ['List Department'] },
        { label: 'Predefined Replies', link: '/tickets/predefined-replies', requiredRoles: ['admin', 'staff'], requiredPermissions: ['View Predefined Reply'] },
        { label: 'Ticket Priority', link: '/tickets/ticket-priority', requiredRoles: ['admin', 'staff'], requiredPermissions: ['View Priority'] },
        { label: 'Ticket Statuses', link: '/tickets/ticket-statuses', requiredRoles: ['admin', 'staff'], requiredPermissions: ['View Status'] },
        { label: 'Services', link: '/tickets/services', requiredRoles: ['admin', 'staff'], requiredPermissions: ['View Services'] },
        { label: 'Customer', link: '/tickets/customers', requiredRoles: ['admin', 'staff'], requiredPermissions: ['List tickets'] },
        { label: 'Turn Around Time', link: '/tickets/turn-around-time', requiredRoles: ['admin', 'staff'], requiredPermissions: ['List tickets'] },
        { label: 'Make Of Chargers', link: '/tickets/make-of-chargers', requiredRoles: ['admin', 'staff'], requiredPermissions: ['List tickets'] },
        { label: 'Mode Of Complaints', link: '/tickets/mode-of-complaints', requiredRoles: ['admin', 'staff'], requiredPermissions: ['List tickets'] },
      ], icon: faGears
    },
    { id: 3, label: 'Staff', link: '/staff', icon: faUsers, requiredRoles: ['admin', 'staff'], requiredPermissions: ['View Staff'] },
    { id: 4, label: 'Reports', link: '/aging-report', icon: faListCheck, requiredRoles: ['admin', 'staff'], requiredPermissions: ['List tickets'] },
    { id: 5, label: 'Settings', icon: faCog, requiredRoles: ['admin', 'staff'], requiredPermissions: ['Change Settings'] },
  ];


  const setUpMenuItems = [
    {
      id: 1, label: 'Advanced Emails', submenus: [
        { label: 'Settings', link: '/email-settings' },
        { label: 'Email Log', link: '/email-logs' },
      ]
    },
    { id: 2, label: 'Permissions', link: '/permission', icon: faUserLock },

  ];

  function updatePermissions(newPermissions) {
    // If newPermissions is already an object, return it
    if (typeof newPermissions === 'object' && !Array.isArray(newPermissions)) {
      return newPermissions;
    }

    // Convert the array to an object with indices as keys
    const updatedPermissions = {};
    newPermissions.forEach((permission, index) => {
      updatedPermissions[index] = permission;
    });

    return updatedPermissions;
  }


  const checkPermissions = (menuItem) => {
    if (menuItem.requiredPermissions && permissions) {

      //console.log("permissions", permissions);

      const updatedDataObject = updatePermissions(permissions);
      const updatedDataArray = Object.values(updatedDataObject);
      // console.log("updatedDataArray", updatedDataArray);

      if (!menuItem.requiredPermissions.every(permission => updatedDataArray.includes(permission))) {
        return false;
      }
    }
    if (menuItem.submenus) {
      const hasSubmenuPermission = menuItem.submenus.some(submenu => checkPermissions(submenu));
      if (hasSubmenuPermission) {
        return true;
      } else {
        return menuItem.requiredPermissions !== undefined;
      }
    }
    return menuItem.requiredPermissions !== undefined;
  };

  const filterMenuItems = (menuItems) => {
    return menuItems.reduce((acc, menuItem) => {
      if (!checkPermissions(menuItem)) {
        const filteredSubmenus = menuItem.submenus ? filterMenuItems(menuItem.submenus) : [];
        if (filteredSubmenus.length > 0) {
          return [...acc, ...filteredSubmenus];
        }
        return acc;
      }
      if (menuItem.submenus) {
        const filteredSubmenus = filterMenuItems(menuItem.submenus);
        return [...acc, { ...menuItem, submenus: filteredSubmenus }];
      }
      return [...acc, menuItem];
    }, []);
  };

  const filteredMenuItems = filterMenuItems(menuItems);

  const filteredSetUpMenuItems = setUpMenuItems.map((menuItem) => {
    if (menuItem.requiredPermissions) {
      const hasPermission = menuItem.requiredPermissions.every(permission => permissions.includes(permission));
      if (!hasPermission) {
        return { ...menuItem, submenus: null }; // Hide submenus if permission is not met
      }
    }
    return menuItem;
  });

  const handleSetupMenuClose = () => {
    setIsSetupMenuOpen(false);
  };


  return (
    <>
      <aside className={`sidebar ${isCollapsed ? 'collapsed' : ''} ${isSpecialBackground ? 'special-background' : ''}`}>
        <div className="container p-0">
          <div className="row">
            <div className="col">
              <ul>
                {filteredMenuItems.map((menuItem, index) => (
                  <React.Fragment key={index}>
                    <Link to={menuItem.link} onClick={() => handleParentClick(index, menuItem.label)}>
                      <li className={`mainmenu ${activeMenu === index ? 'active' : ''} ${(menuItem.submenus) ? 'has-submenus' : ''}`}                      >
                        {isCollapsed ? (
                          <FontAwesomeIcon icon={menuItem.icon} className='menu-icon' />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={menuItem.icon} className='menu-icon' />
                            {menuItem.label}
                          </>
                        )}
                        {menuItem.submenus && (
                          <img className="submenu-arrow" style={{ transform: `rotate(${arrowRotation[index] || 90}deg)` }} src={require('../../../Assets/images/collapse-arrow.png')} alt="navigation" />
                        )}
                      </li>
                    </Link>
                    {activeMenu === index && menuItem.submenus && (
                      <ul className='submenu' style={{ maxHeight: activeMenu === index ? `${menuItem.submenus.length * 40}px` : '0' }}>
                        {menuItem.submenus.map((submenu, submenuIndex) => (
                          <Link to={submenu.link} key={submenuIndex}>
                            <li className={location.pathname === submenu.link ? 'active' : ''}>{submenu.label}</li>
                          </Link>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </aside>

      {/* Setup Sidebar */}
      <aside className={`setup-sidebar ${isSetupMenuOpen ? 'open' : ''}  ${isSpecialBackground ? 'special-background' : ''}`}>
        <div className="setup-menu">
          <div className="row mx-2 justify-content-between align-itmes-center flex-nowrap">
            <div className="col">
              <span className='text-white' style={{ fontSize: 20 }}>Settings</span>
            </div>
            <div className="col text-end p-0 pt-1">
              <button type="button" className="btn-close btn-close-white me-1" onClick={handleSetupMenuClose} aria-label="Close"></button>
            </div>
          </div>
          <hr className='m-0 mt-2 mb-1 ms-1' style={{ color: "6687bd" }} />
          <ul>
            {setUpMenuItems.map((setupMenuItem, index) => (
              <React.Fragment key={index}>
                {setupMenuItem.submenus ? (
                  <>
                    <li
                      className={`mainmenu ${activeMenu === index ? 'active' : ''} has-submenus`}
                      onClick={() => handleParentClick(index, setupMenuItem.label, true)}
                    >
                      {setupMenuItem.label}
                      <img
                        className="submenu-arrow"
                        style={{ transform: `rotate(${arrowRotation[index] || 90}deg)` }}
                        src={require('../../../Assets/images/collapse-arrow.png')}
                        alt="navigation"
                      />
                    </li>
                    {activeMenu === index && (
                      <ul className="submenu" style={{ maxHeight: `${setupMenuItem.submenus.length * 40}px` }}>
                        {setupMenuItem.submenus.map((submenu, submenuIndex) => (
                          <Link to={submenu.link} key={submenuIndex}>
                            <li className={location.pathname === submenu.link ? 'active' : ''}>{submenu.label}</li>
                          </Link>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <Link to={setupMenuItem.link} key={index}>
                    <li className={`mainmenu ${activeMenu === index ? 'active' : ''}`}>
                      <FontAwesomeIcon icon={setupMenuItem.icon} className='menu-icon' />
                      {setupMenuItem.label}
                    </li>
                  </Link>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </aside>
      {/* <div className="collapse-btn" onClick={handleCollapseToggle}></div> */}
    </>
  );
}

export default Sidebar;
