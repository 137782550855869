import *as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  loginemail: Yup.string().email('Invalid email').required('Email is required'),
  loginpassword: Yup.string().required('Password is required'),
});

// Validation schema for the email step
const emailValidationSchema = Yup.object().shape({
  forgotEmail: Yup.string().email('Invalid email address').required('Email is required')
});

// Validation schema for the OTP step
const otpValidationSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required')
});

//Validation schema for the password reset step
const passwordValidationSchema = Yup.object().shape({
  forgotpassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('forgotpassword'), null], 'Passwords must match')
    .required('Confirm Password is required')
});


export { emailValidationSchema, otpValidationSchema, passwordValidationSchema };


export const newStaffMemberValidation = Yup.object().shape({
  name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().min(8).required('Password is required'),
  parent_company_id: Yup.string().required('Parent Company ID is required'),
  is_staff: Yup.boolean().required('Please select the staff status'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

export const updateStaffMemberValidation = Yup.object().shape({
  name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().min(8),
  parent_company_id: Yup.string().required('Parent Company ID is required'),
  hourly_rate: Yup.number().positive('Hourly Rate must be a positive number'),
  is_staff: Yup.boolean(),
  facebook: Yup.string(),
  linkedin: Yup.string(),
  skype: Yup.string(),
});

export const newDepartmentValidation = Yup.object().shape({
  name: Yup.string().required('Department Name is required.'),
  //email: Yup.string().email('Invalid email address').required('Department Email is required.'),
  turnAroundTime: Yup.number().required("Turn Around Time required.")
});

export const newPredefinedRapliyValidation = Yup.object().shape({
  name: Yup.string().required('Predefined Reply name is required'),
  reply: Yup.string().trim().required('Reply content is required'),

});

export const newTicketStatusValidation = Yup.object().shape({
  name: Yup.string().required('Ticket Status Name is required'),
  color: Yup.string().required('Color is required'),
  order: Yup.number().required('Order is required'),

});



export const NewTicketSchema = Yup.object().shape({
  service: Yup.mixed().test('is-object', 'Service is required.', value => {
    if (!value) return true; // Allow empty value
    return typeof value === 'object' && value !== null && 'value' in value && 'label' in value;
  }).required('Service is required.'),
  customer: Yup.mixed().test('is-object', 'Customer is required.', value => {
    if (!value) return true; // Allow empty value
    return typeof value === 'object' && value !== null && 'value' in value && 'label' in value;
  }).required('Customer is required.'),
  name: Yup.string().required('Name is required.'),
  email: Yup.string().email('Invalid email address').required('Email is required.'),
  state: Yup.mixed().test('is-object', 'State is required.', value => {
    if (!value) return true; // Allow empty value
    return typeof value === 'object' && value !== null && 'value' in value && 'label' in value;
  }).required('State is required.'),
  city: Yup.mixed().test('is-object', 'City is required.', value => {
    if (!value) return true; // Allow empty value
    return typeof value === 'object' && value !== null && 'value' in value && 'label' in value;
  }).required('City is required.'),
});

export const NewTurnAroundTimeValidation = Yup.object().shape({
  service: Yup.mixed().test('is-object', 'Service is required.', value => {
    if (!value) return true; // Allow empty value
    return typeof value === 'object' && value !== null && 'value' in value && 'label' in value;
  }).required('Service is required.'),
  customer: Yup.mixed().test('is-object', 'Customer is required.', value => {
    if (!value) return true; // Allow empty value
    return typeof value === 'object' && value !== null && 'value' in value && 'label' in value;
  }).required('Customer is required.'),
});

export const NewLineItemSchema = Yup.object().shape({
  description: Yup.string().required('Description is required.')
});

export const NewReportSchema = Yup.object().shape({
  start_date: Yup.date().required('Start Date is required.'),
  end_date: Yup.date().required('End Date is required.'),
});


