import React, { useState, useContext } from "react";
import "../Admin.scss";
import { Link } from "react-router-dom";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Tooltip } from "react-tooltip";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Avatar } from '@mui/material'
import { AdminHeaderContext } from "../../Context/AdminHeaderContext/AdminHeaderContext";


function AdminHeader({ toggleSidebar }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.user);
  const { details } = useContext(AdminHeaderContext);

  const isEvamp = window.location.href.includes("evamp");

  // Determine logo based on the URL
  const logoSrc = isEvamp
    ? require("../../../Assets/images/evamp-logo.jpg")
    : require("../../../Assets/images/excelbooklogo.png");
  const logoWidth = isEvamp ? 160 : 140;

  // console.log("user", user);

  const handleLogoutBtn = () => [
    dispatch({ type: "LOGIN", payload: false }),
    dispatch({ type: "UPDATE_TOKEN", payload: "" }),
    dispatch({
      type: 'SET_INITIAL_DATA',
      payload: {
        services: [],
        departments: [],
        staffs: [],
        priorities: [],
        preDefinedReplies: [],
        statuses: [],
        customers: [],
        states: [],
        complaints: [],
        chargers: [],
      }
    })
  ];

  return (
    <nav className="navbar admin navbar-expand-lg">
      <div className="align-items-center container-fluid justify-content-between">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="site d-flex align-items-center">
          <Link to="/tickets" className="me-5">
            <div className="logo me-2">
              <img
                style={{ width: logoWidth }}
                // src={require("../../../Assets/images/evamp-logo.jpg")}
                src={logoSrc}
                alt="excelbook"
              />
            </div>
          </Link>
          {/* <FontAwesomeIcon className="ms-4 cp" icon={faBars} onClick={toggleSidebar} />  */}
        </div>

        <div className="user d-flex align-items-center gap-3 position-relative">
          <Link
            to="/profile"
            style={{ color: "inherit" }}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Profile"
          >
            <div className="d-flex">
              <p className="m-0 me-1" style={{ fontSize: 16 }}>
                Hello {details ? details.name : user.name}!
              </p>
              <Avatar className='border border-secondary' alt={"Avtar"} src={details ? details.profile_picture : user.profile_picture} style={{ height: 25, width: 25, fontSize: '4.25rem' }} />
            </div>
          </Link>
          <Link to="/" onClick={handleLogoutBtn}>
            <LogoutRoundedIcon
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Log out"
            />
          </Link>
        </div>
      </div>
      <Tooltip id="my-tooltip" />
    </nav>
  );
}

export default AdminHeader;
