import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../../Axios/Axios';
import SkeletonComponent from '../../../Parts/SkeletonComponent';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import NewPreDefinedReplies from '../Modals/NewPreDefinedReplies';
import CustomTablePagination from '../../../Parts/CustomTablePagination';
import { useNotification } from '../../../../Notification/NotificationProvider';


function PreDefinedReplies() {

    const token = useSelector(state => state.login.token);
    const user = useSelector((state) => state.login.user);
    const navigate = useNavigate()
    const [preDefinedReplies, setPreDefinedReplies] = useState([]);
    const [page, setPage] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(true)
    const [editingRepliesId, setEditingRepliesId] = useState(null)
    const [eidtInitialValues, setEidtInitialValues] = useState(null);
    const { showNotification } = useNotification();

    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];



    const fetchPreDefinedReplies = useCallback((signal) => {
        axiosInstance.get(`/pre-defined-replies?page=${page + 1}&item=${rowsPerPage}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            signal: signal,
        }).then((res) => {
            setPreDefinedReplies(res.data.preDefinedReplies.data);
            setLoading(false);
            setTotalItem(res.data.preDefinedReplies.meta.total);
            setLastPage(res.data.preDefinedReplies.meta.last_page);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                setLoading(false)
                navigate('/');
            } else {
                console.error(error.message);
            }
        })
    }, [token, page, rowsPerPage])


    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchPreDefinedReplies(signal);

        return () => {
            controller.abort();
        };

    }, [fetchPreDefinedReplies]);



    // console.log("preDefinedReplies",preDefinedReplies);
    const addedNewPreDefinedReplies = () => {
        fetchPreDefinedReplies()
        setEditingRepliesId(null)
        setEidtInitialValues(null)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handlePreDefinedRepliesDelete = (id, replyname) => {
        if (window.confirm(`Are you sure to delete ${replyname} reply?`)) {
            axiosInstance.delete(`/pre-defined-replies/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    fetchPreDefinedReplies();
                    setPage(0);
                }
            }).catch((error) => {
                showNotification(error.message, 'error');
                console.error(error);
            });
        }
    };
    const exportData = preDefinedReplies ? preDefinedReplies.map((reply, index) => ({
        '#': index + 1,
        'Predefined Reply Name': reply.name,
        'Predefined Reply message': reply.reply,
    })) : [];


    return (
        <div className='container customer-Groups-list'>
            <div className="row">
                <div className="col section">
                    <div className="row">
                        <div className="col">
                            {
                                (permissions.includes('Delete Predefined Reply') || permissions.includes('Edit Predefined Reply')) && roles.includes(role) && (
                                    <button className='btn btn-primary btn-sm text-white' onClick={() => { setEditingRepliesId(null); setEidtInitialValues(null); }} data-bs-toggle="modal" data-bs-target="#newPredefinedRepliesModal">+ New Predefined Reply</button>
                                )
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">
                            <CSVLink data={exportData} filename='Predefined-Replies.csv' className='btn btn-light btn-sm'>Export</CSVLink>
                        </div>
                    </div>


                    <div className="row mt-2">
                        <div className="col text-center">

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={70}><strong>ID</strong></TableCell>
                                            <TableCell><strong>Predefined Reply Name</strong></TableCell>
                                            {
                                                (permissions.includes('Delete Predefined Reply') || permissions.includes('Edit Predefined Reply')) && roles.includes(role) && (
                                                    <TableCell><strong>Action</strong></TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {loading ?
                                        <TableBody>
                                            {[...Array(2)].map((_, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    <TableCell><SkeletonComponent line={1} /></TableCell>
                                                    {
                                                        (permissions.includes('Delete Department') || permissions.includes('Edit Department')) && roles.includes(role) && (
                                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                                        )
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        : (
                                            preDefinedReplies.length === 0 ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={3} className='text-center'>
                                                            No data available. Add Predefined Replies first.
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {(rowsPerPage > 0 && preDefinedReplies
                                                    ).map((reply, index) => (
                                                        <TableRow key={reply.id}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                {
                                                                    permissions.includes('Edit Predefined Reply') && roles.includes(role) ? (
                                                                        <Link
                                                                            onClick={() => {
                                                                                setEditingRepliesId(reply.id);
                                                                                setEidtInitialValues({
                                                                                    name: reply.name,
                                                                                    reply: reply.reply
                                                                                });
                                                                            }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#newPredefinedRepliesModal"
                                                                        >
                                                                            {reply.name}
                                                                        </Link>
                                                                    ) : (
                                                                        <Link> {reply.name}</Link>
                                                                    )
                                                                }
                                                            </TableCell>
                                                            {
                                                                (permissions.includes('Edit Predefined Reply') || permissions.includes('Delete Predefined Reply')) && roles.includes(role) && (
                                                                    <TableCell>
                                                                        {permissions.includes('Edit Predefined Reply') && (
                                                                            <FontAwesomeIcon
                                                                                icon={faPenToSquare}
                                                                                className='me-2 edit-icon'
                                                                                onClick={() => {
                                                                                    setEditingRepliesId(reply.id);
                                                                                    setEidtInitialValues({
                                                                                        name: reply.name,
                                                                                        reply: reply.reply
                                                                                    });
                                                                                }}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#newPredefinedRepliesModal"
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Edit"
                                                                            />
                                                                        )}
                                                                        {permissions.includes('Delete Predefined Reply') && (

                                                                            <FontAwesomeIcon
                                                                                onClick={() => handlePreDefinedRepliesDelete(reply.id, reply.name)}
                                                                                icon={faTrashCan}
                                                                                className='delete-icon'
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Delete" />
                                                                        )}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )
                                        )}
                                </Table>
                            </TableContainer>
                            {preDefinedReplies.length !== 0 && (
                                <CustomTablePagination
                                    page={page}
                                    setPage={setPage}
                                    count={totalItem}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    lastPage={lastPage}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <Tooltip id="my-tooltip" />
            <NewPreDefinedReplies addedNewPreDefinedReplies={addedNewPreDefinedReplies} editingRepliesId={editingRepliesId} editInitialValues={eidtInitialValues} />
        </div>
    )
}

export default PreDefinedReplies