import React, { useState, useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axiosInstance from '../../../../Axios/Axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNotification } from '../../../Notification/NotificationProvider';
import { FaRegFilePdf, FaFile } from "react-icons/fa";
import { BsFiletypeCsv, BsFileText, BsFileSpreadsheet } from "react-icons/bs";




const Replys = ({ replies, reload, setReload }) => {

    const navigate = useNavigate();
    const { id } = useParams();
    const token = useSelector((state) => state.login.token);
    const user = useSelector(state => state.login.user);
    const closeRef = useRef(null);
    const quillRef = useRef(null);
    const [value, setValue] = useState({ quill: '' });
    const [editingReplyID, setEditingReplyID] = useState(null);
    const { showNotification } = useNotification();

    const [popupImage, setPopupImage] = useState(null);


    const handleImageClick = (image) => {
        setPopupImage(image);
    };

    // Function to close the popup
    const closePopup = () => {
        setPopupImage(null);
    };


    const [initialValues, setInitialValues] = useState({
        quill: ''
    })


    const stripHtmlTags = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || '';
    };


    const handleReplyForm = (formData, { resetForm }) => {
        const data = {
            "support_ticket_id": id,
            "user_id": user.id,
            "body": value.quill
        }

        axiosInstance.put(`/support-ticket-reply/${editingReplyID}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then((res) => {
            if (res.data.success) {
                setReload(!reload);
                const message = res.data.message;
                showNotification(message, 'success');
                resetForm(); // Reset the form values
                closeRef.current.click();
            }

        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                navigate('/');
            } else {
                showNotification(error.message, 'error');
                console.error(error.message);
            }
        });
    }


    const handleReplyDelete = (delete_Id) => {
        if (window.confirm(`Are you sure to delete This Reply?`)) {
            axiosInstance.delete(`/support-ticket-reply/${delete_Id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.data.success) {
                    const message = res.data.message;
                    showNotification(message, 'success');
                    setReload(!reload);

                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                } else {
                    showNotification(error.message, 'error');
                    console.error(error.message);
                }
            });
        }
    }

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['image', 'video'],
                ['clean'],
            ],
        },
    };

    return (
        <div className='mt-5 mb-5'>
            {replies && replies.length !== 0 && replies.map((reply, index) => (
                <div className='section container reply my-4' key={index}>
                    <div>
                        <div className="row">
                            {/* User Info */}
                            <div className="col-3 title">
                                <div className="row">
                                    <div className="col">
                                        <h5>{reply.user.name} {reply.user.last_name}</h5>
                                        <h6>Staff</h6>
                                    </div>
                                </div>
                                <hr className='my-2 text-secondary w-100' />
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <button className='btn btn-danger btn-sm mt-1' onClick={() => handleReplyDelete(reply.id)} type='button'>Delete Reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Reply Content */}
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end">
                                        <FontAwesomeIcon
                                            icon={faPenToSquare}
                                            className='edit-icon align-self-end'
                                            onClick={() => {
                                                setEditingReplyID(reply.id);
                                                setValue({ quill: reply.body })
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#updateReplyModal"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Edit Reply"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {/* <h4>{stripHtmlTags(reply.body)}</h4> */}
                                        <div dangerouslySetInnerHTML={{ __html: reply?.body }} />
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col">
                                        {/* Check file type and render accordingly */}
                                        {reply.attachments && reply.attachments.length > 0 && (
                                            <>
                                                {reply.attachments.map((attachment) => (
                                                    <div key={attachment.id} className='row mb-2'>
                                                        <div className="col">
                                                            <p>{attachment.attachment_original_name}</p>
                                                            {attachment.attachment?.toLowerCase().endsWith('.pdf') ? (
                                                                <>
                                                                    <FaRegFilePdf className='mx-1 text-primary' />
                                                                    <a
                                                                        href={attachment.attachment}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className='text-primary'
                                                                        download={attachment.attachment_original_name}
                                                                    >
                                                                        Download PDF
                                                                    </a>
                                                                </>
                                                            ) : attachment.attachment_original_name?.toLowerCase().endsWith('.csv') ? (
                                                                <>
                                                                    <BsFiletypeCsv className='mx-1 text-primary' />
                                                                    <a
                                                                        href={attachment.attachment}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className='text-primary'
                                                                        download={attachment.attachment_original_name}
                                                                    >
                                                                        Download CSV
                                                                    </a>
                                                                </>
                                                            ) : attachment.attachment_original_name?.toLowerCase().endsWith('.txt') ? (
                                                                <>
                                                                    <BsFileText className='mx-1 text-primary' />
                                                                    <a
                                                                        href={attachment.attachment}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className='text-primary'
                                                                        download={attachment.attachment_original_name}
                                                                    >
                                                                        Download TXT
                                                                    </a>
                                                                </>
                                                            ) : attachment.attachment_original_name?.toLowerCase().endsWith('.xlsx') ? (
                                                                <>
                                                                    <BsFileSpreadsheet className='mx-1 text-primary' />
                                                                    <a
                                                                        href={attachment.attachment}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className='text-primary'
                                                                        download={attachment.attachment_original_name}
                                                                    >
                                                                        Download XLSX
                                                                    </a>
                                                                </>
                                                            ) : attachment.attachment?.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) ? (
                                                                <img
                                                                    src={attachment.attachment}
                                                                    alt="Image"
                                                                    style={{ width: '200px', height: "200px" }}
                                                                    onClick={() => handleImageClick(attachment.attachment)}
                                                                />
                                                            ) : attachment.attachment ? (
                                                                <>
                                                                    <FaFile className='mx-1 text-primary' />
                                                                    <a
                                                                        href={attachment?.attachment}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className='text-primary'
                                                                        download
                                                                    >
                                                                        Download File
                                                                    </a>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ))}
                                                {popupImage && (
                                                    <div className="image-popup-overlay" onClick={closePopup}>
                                                        <div className="image-popup-content">
                                                            <span className="image-popup-close" onClick={closePopup}>&times;</span>
                                                            <img src={popupImage} alt="Popup Attachment" />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-top my-3"></div>
                        <div className='text-secondary'>{"Posted: " + new Date(reply.created_at).toLocaleString('en-GB')}</div>
                    </div>
                </div>
            ))}



            <div className="modal fade" tabIndex="-1" id="updateReplyModal">
                <div className="modal-dialog modal-dialog-scrollable" style={{ maxWidth: 700, height: 800 }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>Edit</h5>
                            <button type="button" className="btn-close" ref={closeRef} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                onSubmit={handleReplyForm}
                            >
                                <Form>
                                    <div className='mb-3' style={{ height: 250 }} >
                                        <ReactQuill
                                            theme="snow"
                                            name="quill"
                                            ref={quillRef}
                                            value={value.quill}
                                            onChange={(value) => {
                                                setValue({ ...value, quill: value });
                                            }}
                                            modules={modules}
                                            style={{ height: '80%' }}
                                        />
                                    </div>
                                    <div className="modal-footer mt-5">
                                        <button className="btn btn-primary text-white btn-sm">
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Replys