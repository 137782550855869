import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../../Axios/Axios';
import SkeletonComponent from '../../../Parts/SkeletonComponent';
import CustomTablePagination from '../../../Parts/CustomTablePagination';
import NewCustomerModal from '../Modals/NewCustomerModal';
import { useNotification } from '../../../../Notification/NotificationProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { CSVLink } from 'react-csv';



function Customers() {

  const token = useSelector(state => state.login.token);
  const user = useSelector((state) => state.login.user);
  const [customersNames, setCustomersNames] = useState([]);
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [times, setTimes] = useState([]);
  const [initialCustomerName, setInitialCustomerName] = useState('')
  const [editingCustomerId, setEditingCustomerId] = useState(null)
  const { showNotification } = useNotification();
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.login.permissions);
  const role = useSelector((state) => state.login.role);
  const roles = ['admin', 'staff'];


  const fetchCustomerNames = useCallback((signal) => {
    axiosInstance.get(`/support-ticket-customers?page=${page + 1}&item=${rowsPerPage}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      signal: signal,
    }).then((res) => {
      setLoading(false)
      setCustomersNames(res.data.customers.data);
      // try {
      //   const [
      //     customerResponse,
      //   ] = Promise.all([
      //     axiosInstance.get("/support-ticket-customers", {
      //       headers: { Authorization: `Bearer ${token}` },
      //       signal: signal,
      //     }),
      //   ]);
      //   dispatch({
      //     type: 'SET_INITIAL_DATA',
      //     payload: {
      //       customers: customerResponse.data.customers.data,
      //     }
      //   });
      // } catch (error) {
      //   console.error(error);
      // }
      setTotalItem(res.data.customers.meta.total);
      setLastPage(res.data.customers.meta.last_page);
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        navigate('/');
      } else {
        console.error(error.message);
      }
    })
  }, [token, page, rowsPerPage]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchCustomerNames(signal);

    return () => {
      controller.abort();
    };

  }, [fetchCustomerNames]);


  const fetchTime = (signal) => {
    axiosInstance.get('support-ticket-turn-around-time', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      signal: signal
    }).then((res) => {
      setTimes(res.data.tats.data);
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        navigate('/');
      } else {
        console.error(error.message);
      }
    });
  }

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchTime(signal);

    return () => {
      controller.abort();
    };

  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addedNewCustomer = () => {
    fetchCustomerNames();
    setEditingCustomerId(null)
  }


  const handleCustomerDelete = (id, customername) => {
    if (window.confirm(`Are you sure to delete ${customername} customer?`)) {
      axiosInstance.delete(`/support-ticket-customers/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      }).then((res) => {
        if (res.data.success) {
          const message = res.data.message;
          showNotification(message, 'success');
          fetchCustomerNames();
          setPage(0);
        }
      }).catch((error) => {
        showNotification(error.message, 'error');
        console.error(error);
      });
    }
  };

  const exportData = customersNames ? customersNames.map((customer, index) => ({
    '#': index + 1,
    'Customer name': customer.name,
  })) : [];


  const doesCustomerIdExist = (dataObjects, customerIdToCheck) => {
    return dataObjects.some(data => data.customer && data.customer.id === customerIdToCheck);
  }

  return (
    <div className='container customer-Groups-list'>
      <div className="row">
        <div className="col section">
          <div className="row">
            <div className="col">
              {
                (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                  <button className='btn btn-primary btn-sm text-white' data-bs-toggle="modal" data-bs-target="#newCustomerModal">+ New Customer</button>
                )
              }
            </div>
          </div>
          <div className="row">
            <div className="col text-end">
              <CSVLink data={exportData} filename='Customers.csv' className='btn btn-light btn-sm'>Export</CSVLink>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-center">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>#</strong></TableCell>
                      <TableCell><strong>Customer Name</strong></TableCell>
                      {
                        (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                          <TableCell><strong>Action</strong></TableCell>
                        )
                      }
                    </TableRow>
                  </TableHead>
                  {loading ?
                    <TableBody>
                      {[...Array(2)].map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell><SkeletonComponent line={1} /></TableCell>
                          <TableCell><SkeletonComponent line={1} /></TableCell>
                          {
                            (permissions.includes('Delete Services') || permissions.includes('Edit Services')) && roles.includes(role) && (
                              <TableCell><SkeletonComponent line={1} /></TableCell>
                            )
                          }
                        </TableRow>
                      ))}
                    </TableBody>
                    : (
                      customersNames.length === 0 ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={3} className='text-center'>
                              No data available.
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {(rowsPerPage > 0 && customersNames || []
                          ).map((customer, index) => (
                            <TableRow key={customer.id}>
                              <TableCell>
                                {/* {page* rowsPerPage + index + 1} */}
                                {index + 1}
                              </TableCell>
                              <TableCell>
                                {
                                  permissions.includes('Edit Services') && roles.includes(role) ? (
                                    <Link
                                      onClick={() => {
                                        setEditingCustomerId(customer.id);
                                        setInitialCustomerName(customer.name);
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target="#newCustomerModal"
                                    >
                                      {customer.name}
                                    </Link>
                                  ) : (
                                    <Link > {customer.name}</Link>
                                  )
                                }
                              </TableCell>
                              {
                                (permissions.includes('Edit Services') || permissions.includes('Delete Services')) && roles.includes(role) && (
                                  <TableCell>
                                    {permissions.includes('Edit Services') && (
                                      <FontAwesomeIcon
                                        icon={faPenToSquare}
                                        className='me-2 edit-icon'
                                        onClick={() => {
                                          setEditingCustomerId(customer.id);
                                          setInitialCustomerName(customer.name);
                                        }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#newCustomerModal"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Edit"
                                      />
                                    )}

                                    {permissions.includes('Delete Services') && (
                                      doesCustomerIdExist(times, customer.id) ?
                                        <FontAwesomeIcon
                                          icon={faTrashCan}
                                          diabled
                                          className='delete-icon disabled'
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content="Cannot delete customer. Please delete related tickets & turn around time first." />
                                        :
                                        <FontAwesomeIcon
                                          onClick={() => handleCustomerDelete(customer.id, customer.name)}
                                          icon={faTrashCan}
                                          className='delete-icon'
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content="Delete" />
                                    )}
                                  </TableCell>
                                )
                              }

                            </TableRow>
                          ))}
                        </TableBody>
                      )
                    )}
                </Table>
              </TableContainer>
              {customersNames.length !== 0 && (
                <CustomTablePagination
                  page={page}
                  setPage={setPage}
                  count={totalItem}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  lastPage={lastPage}
                />
              )}
            </div>
          </div>

        </div>
      </div>
      <NewCustomerModal addedNewCustomer={addedNewCustomer} editingCustomerId={editingCustomerId} initialCustomerName={initialCustomerName} />
    </div>
  )
}

export default Customers