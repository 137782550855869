// src/store/LoginReducer.js
const initialState = {
  is_login: false,
  token: '',
  user: '',
  permissions: '',
  role: '',
  services: [],
  departments: [],
  staffs: [],
  priorities: [],
  preDefinedReplies: [],
  statuses: [],
  customers: [],
  states: [],
  complaints: [],
  chargers: []
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        is_login: action.payload,
      };
    case 'UPDATE_TOKEN':
      return {
        ...state,
        token: action.payload
      };
    case 'USER':
      return {
        ...state,
        user: action.payload
      };
    case 'PERMISSIONS':
      return {
        ...state,
        permissions: action.payload
      }
    case 'ROLE':
      return {
        ...state,
        role: action.payload
      }
    case 'UPDATE_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload
      }
    case 'UPDATE_ROLE':
      return {
        ...state,
        role: action.payload
      }
    case 'SET_INITIAL_DATA':
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default LoginReducer;
