import React, { useState, useEffect, useCallback, useRef } from 'react';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faMagnifyingGlass, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SkeletonComponent from '../../Parts/SkeletonComponent';
import { CSVLink } from 'react-csv';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axiosInstance from '../../../../Axios/Axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomTablePagination from '../../Parts/CustomTablePagination';
import { Formik, Form, Field, ErrorMessagem } from 'formik';
import CustomSelect from '../../Parts/CustomSelect';
import { useNotification } from '../../../Notification/NotificationProvider';
import useLogoutOnClose from '../../../AutoLogout/AutoLogout';


const TicketSupport = () => {
    // useLogoutOnClose();
    const navigate = useNavigate();
    const location = useLocation();
    const { showNotification } = useNotification();
    const token = useSelector((state) => state.login.token);
    const user = useSelector((state) => state.login.user);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [tickets, setTickets] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(0);
    const [statusFilter, setStatusFilter] = useState("");
    const [statuses, setStatuses] = useState([]);
    const resetValue = () => { };
    const closeRef = useRef();
    const [selectedSubjct, setSelectedSubjct] = useState("equal");
    const [selectedDepartment, setSelectedDepartment] = useState("equal");
    const [selectedStatus, setSelectedStatus] = useState("in");
    const [selectedPriority, setSelectedPriority] = useState("equal");
    const [selectedService, setSelectedService] = useState("equal");
    const [selectedAssigned, setSelectedAssigned] = useState("equal");
    const [selected, setSelected] = useState("and");
    const [services, setServices] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [prioritys, setPrioritys] = useState([]);
    const [filters, setFilters] = useState("");
    const [ticketStatusCounts, setTicketStatusCounts] = useState([]);
    const [isEvamp, setIsEvamp] = useState(false);

    const permissions = useSelector((state) => state.login.permissions);
    const role = useSelector((state) => state.login.role);
    const roles = ['admin', 'staff'];
    const logged_id = user.id;


    const handleSelect = (option) => {
        setSelected(option);
    };

    const handleOptionSubjct = (option) => {
        setSelectedSubjct(option);
    };
    const handleOptionDepartment = (option) => {
        setSelectedDepartment(option);
    };
    const handleOptionStatus = (option) => {
        setSelectedStatus(option);
    };
    const handleOptionPriority = (option) => {
        setSelectedPriority(option);
    };
    const handleOptionService = (option) => {
        setSelectedService(option);
    };
    const handleOptionAssigned = (option) => {
        setSelectedAssigned(option);
    };

    const [initialValues, setInitialValues] = useState({
        filter_Subject: "",
        filter_Department: "",
        filter_Status: "",
        filter_Priority: "",
        filter_Service: "",
        filter_Assigned: "",
    });

    const handleChangeSearch = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    };

    const handleMouseEnter = (id) => {
        setHoveredRow(id);
    };

    const handleMouseLeave = () => {
        setHoveredRow(null);
    };

    const fetchTickets = useCallback(async (signal) => {

        axiosInstance
            .get(
                `support-ticket?data=${filters}&search=${searchTerm}&ticket_status_id=${statusFilter}&page=${page + 1
                }&item=${rowsPerPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                    signal: signal,
                }
            )
            .then((res) => {
                // console.log(res.data.tickets.data);
                setLoading(false);
                setTickets(res.data.tickets.data);
                setTotalItem(res.data.tickets.meta.total);
                setLastPage(res.data.tickets.meta.last_page);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate("/");
                } else {
                    console.error(error.message);
                }
            });


    }, [page, rowsPerPage, statusFilter, filters, searchTerm]);


    const fetchCount = () => {
        axiosInstance
            .get('/support-ticket-counts', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res) {
                    //console.log(res.data.data);
                    setTicketStatusCounts(res.data.data)
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    navigate("/");
                } else {
                    showNotification(error.message, "error");
                    console.error(error.message);
                }
            });
    }


    const fetchInitialValues = async (signal) => {
        try {
            const [
                ticket_countResponse,
                ticket_statusResponse,
                servicesResponse,
                departmentsResponse,
                staffResponse,
                ticket_priorityResponse,

            ] = await Promise.all([
                axiosInstance.get("/support-ticket-counts", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/ticket-statuses", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/services", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/departments", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/staff", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
                axiosInstance.get("/ticket-priority", {
                    headers: { Authorization: `Bearer ${token}` },
                    signal: signal,
                }),
            ]);
            setLoadingInitial(false)
            setStatuses(ticket_statusResponse.data.ticketStatuses.data);
            setServices(servicesResponse.data.services.data);
            setDepartments(departmentsResponse.data.departments.data);
            setStaffs(staffResponse.data.staff.data);
            setPrioritys(ticket_priorityResponse.data.ticketPriorities.data);
            setTicketStatusCounts(ticket_countResponse.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFilter = (formData, { resetForm }) => {
        const ticket_status_id =
            formData.filter_Status &&
            formData.filter_Status.map((item) => item.value);
        const ticket_priority_id = formData.filter_Priority.value;
        const department_id = formData.filter_Department.value;
        const staff_id = formData.filter_Assigned.value;
        const ticket_service_id = formData.filter_Service.value;
        const subject = formData.filter_Subject;

        //console.log("ticket_status_id", ticket_status_id);
        // console.log("ticket_priority_id", ticket_priority_id);
        // console.log("department_id", department_id);
        // console.log("staff_id", staff_id);
        // console.log("ticket_service_id", ticket_service_id);s
        // console.log("selected", selected);

        let isStatusFilter;
        let isPriorityFilter;
        let isDepartmentFilter;
        let isStaffFilter;
        let isServiceFilter;
        let isSubjectFilter;
        let isTypeFilter;

        if (ticket_status_id) {
            isStatusFilter = {
                field: "ticket_status_id",
                type: selectedStatus === "in" ? "=" : "!=",
                value: ticket_status_id,
            };
        }
        if (ticket_priority_id) {
            isPriorityFilter = {
                field: "ticket_priority_id",
                type: selectedPriority === "equal" ? "=" : "!=",
                value: ticket_priority_id,
            };
        }
        if (department_id) {
            isDepartmentFilter = {
                field: "department_id",
                type: selectedDepartment === "equal" ? "=" : "!=",
                value: department_id,
            };
        }
        if (staff_id) {
            isStaffFilter = {
                field: "staff_id",
                type: selectedAssigned === "equal" ? "=" : "!=",
                value: staff_id,
            };
        }
        if (ticket_service_id) {
            isServiceFilter = {
                field: "ticket_service_id",
                type: selectedService === "equal" ? "=" : "!=",
                value: ticket_service_id,
            };
        }
        if (subject) {
            isSubjectFilter = {
                field: "subject",
                type: selectedSubjct === "equal" ? "=" : "!=",
                value: subject,
            };
        }
        if (selected) {
        }

        var newFilter = {
            filter_type: selected,
            filter: [
                { ...isStatusFilter },
                { ...isPriorityFilter },
                { ...isDepartmentFilter },
                { ...isStaffFilter },
                { ...isServiceFilter },
                { ...isSubjectFilter },
            ],
        };

        let filter = JSON.stringify(newFilter);

        // console.log(filter);
        setFilters(filter);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleTicketDelete = (id, ticketSubject) => {
        if (window.confirm(`Are you sure to delete ${ticketSubject} ticket?`)) {
            axiosInstance
                .delete(`/support-ticket/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        const message = res.data.message;
                        showNotification(message, "success");
                        setPage(0);
                        fetchTickets();
                        fetchCount();
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        navigate("/");
                    } else {
                        showNotification(error.message, "error");
                        console.error(error.message);
                    }
                });
        }
    };

    function capitalizeFirstChar(str) {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const handleFilterByStatus = (status_name) => {
        const findStatus = statuses.filter((status) => status.name === status_name);
        const status_Id = findStatus[0].id;
        // console.log(status_Id);
        setStatusFilter(status_Id);
    };


    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchTickets(signal);

        return () => {
            controller.abort();
        };

    }, [fetchTickets]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchInitialValues(signal);

        return () => {
            controller.abort();
        };

    }, []);


    const exportData = tickets?.map((ticket, index) => ({
        '#': index + 1,
        'Service': ticket.ticket_service_id ? ticket.ticket_service_id.name : '-',
        'Department': ticket.ticket_department_id ? ticket.ticket_department_id.name : '-',
        'Customers': ticket?.customer?.name,
        'Status': ticket.ticket_status_id ? ticket.ticket_status_id.name : '-',
        'City': ticket?.city ? ticket?.city?.city : '-',
        'State': ticket?.state ? ticket?.state?.name : '-',
        'Priority': ticket.ticket_priority_id ? ticket.ticket_priority_id.name : '-',
        'Last Reply': new Date(ticket.updated_at).toLocaleString(),
        'Created': new Date(ticket.created_at).toLocaleString()
    }));

    //change icon based on url
    useEffect(() => {
        const isEvamp = window.location.href.includes("evamp");
        setIsEvamp(isEvamp);
    }, []);

    return (
        <div className='section container customer-page'>
            <div className="row mb-3">
                <div className="col">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className='mb-0'><FontAwesomeIcon icon={faFile} /> Tickets Summary</h5>
                        <div className="buttons">
                            {permissions.includes('Add new tickets') && roles.includes(role) && (
                                <Link to='/tickets/ticket'>
                                    <button className='btn btn-primary btn-sm text-white fw-semibold me-2'>+ New Ticket</button>
                                </Link>
                            )}
                            <button className='filetrBtn ms-1' data-bs-toggle="modal" data-bs-target="#newTicketFilterModal" data-tooltip-id="my-tooltip" data-tooltip-content="Filter by" data-tooltip-place="top">
                                {isEvamp ? <FontAwesomeIcon icon={faFilter} /> : <img src={require("../../../../Assets/images/filter.png")} alt="filerData" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row summary">
                <div className="col">
                    <div className="row mt-2 mb-4 ps-2">
                        {loadingInitial ? (
                            <SkeletonComponent line={1} />
                        ) : (
                            ticketStatusCounts?.map((item) => {
                                return (
                                    <div className="col list" key={item.id}>
                                        <p
                                            style={{ cursor: "pointer", color: item.color }}
                                            onClick={() => handleFilterByStatus(item.name)}
                                        >
                                            <span>{item.ticket_count}</span> {item.name}
                                        </p>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
            <div className="row justify-content-end">
                <div className="col-3">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1" style={{ height: "34px" }}><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                        <input type="text" className="form-control" onChange={handleChangeSearch} placeholder="Search" aria-label="search" style={{ height: "34px" }} value={searchTerm} aria-describedby="basic-addon1" />
                    </div>
                </div>
                <div className="col-auto">
                    <CSVLink data={exportData} filename='Tickets.csv' className='btn btn-light btn-sm'>Export</CSVLink>
                </div>
            </div>
            <hr className='mt-2 mb-3 text-body-tertiary' />
            <div className="row mt-2 justify-content-center">
                <div className="col text-center">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'><strong>#ID</strong></TableCell>
                                    <TableCell align='left' style={{ minWidth: "200px" }}><strong>Service</strong></TableCell>
                                    <TableCell align='left'><strong>Department</strong></TableCell>
                                    <TableCell align='left'><strong>Customer</strong></TableCell>
                                    <TableCell align='left'><strong>Status</strong></TableCell>
                                    <TableCell align='left' style={{ minWidth: "100px" }}><strong>City</strong></TableCell>
                                    <TableCell style={{ minWidth: "140px" }}><strong>State</strong></TableCell>
                                    <TableCell align='left'><strong>Priority</strong></TableCell>
                                    <TableCell align='left'><strong>Last Reply</strong></TableCell>
                                    <TableCell align='left'><strong>Created</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            {loading ?
                                <TableBody>
                                    {[...Array(4)].map((_, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {/* <TableCell><SkeletonComponent line={1} /></TableCell> */}
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                            <TableCell><SkeletonComponent line={1} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody> : (
                                    tickets && tickets.length === 0 ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={10} className='text-center'>
                                                    No data available.
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {tickets && tickets.length !== 0 && tickets.map((ticket, index) => (
                                                <TableRow key={ticket.id} onMouseEnter={() => handleMouseEnter(ticket.id)} onMouseLeave={handleMouseLeave}>
                                                    <TableCell align='left'>{ticket.id} </TableCell>
                                                    <TableCell align='left'>
                                                        <Link to={`/tickets/ticket/${ticket.id}`}>
                                                            {ticket.ticket_service_id ? (ticket.ticket_service_id.name) : ("-")}
                                                        </Link>
                                                        <div style={{ visibility: `${hoveredRow === ticket.id ? 'visible' : 'hidden'}` }}>
                                                            <span className='me-1'>
                                                                {permissions.includes('List tickets') && roles.includes(role) && (
                                                                    <Link
                                                                        to={`/tickets/ticket/${ticket.id}`}
                                                                    >
                                                                        View
                                                                    </Link>
                                                                )}

                                                            </span>
                                                            {((permissions.includes('Edit tickets') && logged_id == ticket.created.id) || permissions.includes('Edit others tickets')) && roles.includes(role) && (
                                                                '|'
                                                            )}
                                                            <span className='mx-1'>
                                                                {(permissions.includes('Edit tickets') && logged_id == ticket.created.id) || permissions.includes('Edit others tickets') ? (
                                                                    <Link to={`/tickets/ticket/${ticket.id}/setting`}>
                                                                        Edit
                                                                    </Link>
                                                                ) : null}
                                                            </span>
                                                            {((permissions.includes('Delete tickets') && logged_id == ticket.created.id) || permissions.includes('Delete others tickets')) && roles.includes(role) && (
                                                                '|'
                                                            )}

                                                            {(permissions.includes('Delete tickets') && logged_id == ticket.created.id) || permissions.includes('Delete others tickets') && roles.includes(role) ? (
                                                                <span className='text-danger ms-1' style={{ cursor: 'pointer' }} onClick={() => handleTicketDelete(ticket.id, ticket.subject)}>Delete</span>
                                                            ) : null}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align='left'>{ticket.ticket_department_id ? (ticket.ticket_department_id.name) : ("-")}</TableCell>
                                                    <TableCell align='left'>{ticket.customer ? (ticket.customer.name) : ("-")}</TableCell>
                                                    <TableCell align='left'>
                                                        {ticket.ticket_status_id ? (<div
                                                            className="rounded d-inline-block"
                                                            style={{
                                                                fontSize: 12,
                                                                padding: 4,
                                                                width: 80,
                                                                textAlign: "center",
                                                                color: ticket.ticket_status_id.color,
                                                                borderColor: ticket.ticket_status_id.color,
                                                                borderWidth: 1,
                                                                borderStyle: 'solid'
                                                            }}
                                                        >
                                                            {capitalizeFirstChar(ticket.ticket_status_id.name)}
                                                        </div>) : ("-")}

                                                    </TableCell>
                                                    <TableCell align='left'>{ticket?.city?.city}</TableCell>
                                                    <TableCell align='left'>{ticket?.state?.name}</TableCell>
                                                    <TableCell align='left'>{ticket.ticket_priority_id ? (ticket.ticket_priority_id.name) : ("-")}</TableCell>
                                                    <TableCell align='left'>
                                                        <div>{new Date(ticket.created_at).toLocaleDateString('en-GB')}</div>
                                                        <div>{new Date(ticket.updated_at).toLocaleTimeString()}</div>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <div>{new Date(ticket.created_at).toLocaleDateString('en-GB')}</div>
                                                        <div>{new Date(ticket.created_at).toLocaleTimeString()}</div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )
                                )}
                        </Table>
                    </TableContainer>
                </div>
                {tickets.length !== 0 && (
                    <CustomTablePagination
                        page={page}
                        setPage={setPage}
                        count={totalItem}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        lastPage={lastPage}
                    />
                )}
            </div>

            <div className="modal fade" tabIndex="-1" id="newTicketFilterModal">
                <div className="modal-dialog" style={{ maxWidth: 800 }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Filter</h2>
                            <button
                                type="button"
                                className="btn-close"
                                ref={closeRef}
                                onClick={() => {
                                    resetValue();
                                }}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <Formik initialValues={initialValues} onSubmit={handleFilter}>
                                <Form>
                                    <div className="my-5, mx-5">
                                        <div className="row align-items-center ">
                                            <div className="col-3 alert alert-light ms-3">
                                                <label htmlFor="is_active_yes" className='ms-4'>
                                                    <Field
                                                        className="form-check-input me-1"
                                                        type="radio"
                                                        id="is_and"
                                                        value="and"
                                                        name="is_active"
                                                        checked={selected === "and"}
                                                        onChange={() => handleSelect("and")}
                                                    />
                                                    and
                                                </label>
                                                <label
                                                    htmlFor="is_active_no"
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    <Field
                                                        className="form-check-input me-1"
                                                        type="radio"
                                                        id="is_or"
                                                        value="or"
                                                        name="is_active"
                                                        checked={selected === "or"}
                                                        onChange={() => handleSelect("or")}
                                                    />
                                                    or
                                                </label>
                                            </div>
                                        </div>

                                        <div className="alert alert-light align-items-center ps-4" style={{ position: "relative" }}>
                                            {/* <div className="row my-4">
                                                <div className="col-4 d-flex align-items-center">
                                                    <span className="px-2">Subject</span>
                                                    <div className="btn-group btn-group-sm me-2" >
                                                        <button
                                                            type="button"
                                                            className="btn btn-default dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <span className="caret text-primary">{selectedSubjct}</span>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end ">
                                                            <li
                                                                className="dropdown-item "
                                                                onClick={() => handleOptionSubjct("equal")}
                                                            >
                                                                equal
                                                            </li>
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionSubjct("not equal")}
                                                            >
                                                                not equal
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <Field
                                                        name="filter_Subject"
                                                        className="form-control"
                                                        type="text"
                                                        id="Subject"
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="row align-items-center">
                                                <span className="filter-type">{selected}</span>
                                                <div className="col-4 d-flex align-items-center">
                                                    <span className="px-2">Department</span>
                                                    <div className="btn-group btn-group-sm me-2" >
                                                        <button
                                                            type="button"
                                                            className="btn btn-default dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <span className="caret text-primary">{selectedDepartment}</span>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionDepartment("equal")}
                                                            >
                                                                equal
                                                            </li>
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() =>
                                                                    handleOptionDepartment("not equal")
                                                                }
                                                            >
                                                                not equal
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <Field
                                                        component={CustomSelect}
                                                        name="filter_Department"
                                                        id="Department"
                                                        options={departments?.map((department) => ({
                                                            label: department.name,
                                                            value: department.id,
                                                        }))}
                                                    //onChange={handleStatusSelection}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-4">
                                                <span className="filter-type">{selected}</span>
                                                <div className="col-4 d-flex align-items-center">
                                                    <span className="px-2">Status</span>
                                                    <div className="btn-group btn-group-sm me-2" >
                                                        <button
                                                            type="button"
                                                            className="btn btn-default dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <span className="caret text-primary">{selectedStatus}</span>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionStatus("in")}
                                                            >
                                                                in
                                                            </li>
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionStatus("not in")}
                                                            >
                                                                not in
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <Field
                                                        component={CustomSelect}
                                                        name="filter_Status"
                                                        id="Status"
                                                        isMulti={true}
                                                        options={statuses?.map((status) => ({
                                                            label: status.name,
                                                            value: status.id,
                                                        }))}
                                                    //onChange={handleStatusSelection}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <span className="filter-type">{selected}</span>
                                                <div className="col-4 d-flex align-items-center">
                                                    <span className="px-2">Priority</span>
                                                    <div className="btn-group btn-group-sm me-2" >
                                                        <button
                                                            type="button"
                                                            className="btn btn-default dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <span className="caret text-primary">{selectedPriority}</span>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionPriority("equal")}
                                                            >
                                                                equal
                                                            </li>
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionPriority("not equal")}
                                                            >
                                                                not equal
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <Field
                                                        component={CustomSelect}
                                                        name="filter_Priority"
                                                        id="Priority"
                                                        options={prioritys?.map((priority) => ({
                                                            label: priority.name,
                                                            value: priority.id,
                                                        }))}
                                                    //onChange={handleStatusSelection}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-4">
                                                <span className="filter-type">{selected}</span>
                                                <div className="col-4 d-flex align-items-center">
                                                    <span className="px-2">Service</span>
                                                    <div className="btn-group btn-group-sm me-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <span className="caret text-primary">{selectedService}</span>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionService("equal")}
                                                            >
                                                                equal
                                                            </li>
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionService("not equal")}
                                                            >
                                                                not equal
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <Field
                                                        component={CustomSelect}
                                                        name="filter_Service"
                                                        id="Service"
                                                        options={services?.map((service) => ({
                                                            label: service.name,
                                                            value: service.id,
                                                        }))}
                                                    //onChange={handleStatusSelection}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center mb-4">
                                                <span className="filter-type">{selected}</span>
                                                <div className="col-4 d-flex align-items-center">
                                                    <span className="px-2">Assigned</span>
                                                    <div className="btn-group btn-group-sm me-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <span className="caret text-primary">{selectedAssigned}</span>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionAssigned("equal")}
                                                            >
                                                                equal
                                                            </li>
                                                            <li
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionAssigned("not equal")}
                                                            >
                                                                not equal
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <Field
                                                        component={CustomSelect}
                                                        name="filter_Assigned"
                                                        id="Assigned"
                                                        options={staffs?.map((staff) => ({
                                                            label: staff.name,
                                                            value: staff.id,
                                                        }))}
                                                    //onChange={handleStatusSelection}
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="reset"
                                                    ref={closeRef}
                                                    className="btn btn-primary text-white btn-sm"
                                                >
                                                    Reset
                                                </button>
                                                <button
                                                    type="submit"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    className="btn btn-primary text-white btn-sm"
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TicketSupport;
